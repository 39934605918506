import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { ActionIcon, Tabs } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import {
  ArrayHelpers,
  ErrorMessage,
  Field,
  FieldArray,
  FormikProvider,
} from 'formik';
import { FaList, FaLongArrowAltRight } from 'react-icons/fa';

import { useState } from 'react';
import { formatNumber } from 'src/Pages/Reports/components/MantineTable';
import { amountOnly, getDateFormat } from 'src/utils/helpers';
import { BondRateEntry, BondRateLog } from './model';

const BondDescriptionField = ({
  field,
  form,
  ...props
}: {
  field: any;
  form: any;
  props: any;
}) => {
  return (
    <Input
      {...field}
      {...props}
      w={'50%'}
      marginLeft={'4px'}
      borderRadius={'4px'}
      placeholder="Type Bond Description"
      size="sm"
      type="text"
      value={field.value || ''}
    />
  );
};

const BondRateField = ({
  field,
  form,
  ...props
}: {
  field: any;
  form: any;
  props: any;
}) => {
  return (
    <Input
      {...field}
      {...props}
      w={'30%'}
      borderRadius={'4px'}
      placeholder="Bond Rate"
      size="sm"
      type="text"
      value={field.value || ''}
      onKeyDown={amountOnly}
    />
  );
};

const BondFees = ({
  formik,
  onRemove,
  inactiveBonds,
  addMode,
  onShowDeleted,
}: {
  formik: any;
  onRemove: any;
  inactiveBonds: Array<BondRateEntry>;
  addMode: boolean;
  onShowDeleted: any;
}) => {
  const {
    isOpen: isOpenLogs,
    onOpen: openLogs,
    onClose: closeLogs,
  } = useDisclosure();
  const [bondLogData, setBondLogData] = useState<Array<BondRateLog>>([]);
  const [showDeletedBondRates, setShowDeletedBondRates] = useState(false);
  let boundArrayHelpers: ArrayHelpers;

  const bindArrayHelpers = (arrayHelpers: any) => {
    boundArrayHelpers = arrayHelpers;
  };

  const ViewLogsModal = () => {
    const findRecentlyUpdated = (logs: BondRateLog[]) => {
      return logs.reduce((latest, current) => {
        return new Date(latest.date) > new Date(current.date)
          ? latest
          : current;
      });
    };
    const recent =
      bondLogData.length > 0 ? findRecentlyUpdated(bondLogData) : null;
    return (
      <Modal isOpen={isOpenLogs} onClose={closeLogs} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent maxW={'fit-content'}>
          <ModalHeader>Bond Rates Log</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Tabs defaultValue={showDeletedBondRates ? 'all' : 'recent'}>
                <Tabs.List>
                  {!showDeletedBondRates && (
                    <Tabs.Tab value="recent">Recent Update</Tabs.Tab>
                  )}

                  <Tabs.Tab value="all">View All</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="recent">
                  {recent && (
                    <Box p={'2em 0'}>
                      <div style={{ marginBottom: '8px' }}>
                        <span style={{ fontWeight: 'bold' }}>
                          {recent.updatedBy}{' '}
                        </span>
                        <span>{recent.verb} on </span>
                        <span
                          style={{
                            color: '#b8b8b8',
                            fontSize: '13px',
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          {getDateFormat(recent.date, 'DD MMM, YYYY hh:mm A')}
                        </span>
                      </div>

                      <Flex marginBottom={1} alignItems={'center'} gap={5}>
                        <Text
                          style={{
                            backgroundColor: '#f5ce93',
                            padding: '1px 8px',
                            borderRadius: '3px',
                            color: '#815b22',
                            fontSize: '12px',
                            fontWeight: '700',
                          }}
                        >
                          Description
                        </Text>
                        <Tooltip label="Old Value">
                          <Text fontSize={'13px'}>
                            {recent.oldDescription || 'Nil'}
                          </Text>
                        </Tooltip>

                        <FaLongArrowAltRight />
                        <Tooltip label="New Value">
                          <Text fontSize={'13px'}>{recent.newDescription}</Text>
                        </Tooltip>
                      </Flex>
                      <Flex alignItems={'center'} gap={5}>
                        <Text
                          style={{
                            backgroundColor: '#f5ce93',
                            padding: '1px 8px',
                            borderRadius: '3px',
                            color: '#815b22',
                            fontSize: '12px',
                            fontWeight: '700',
                          }}
                        >
                          Rate
                        </Text>
                        <Tooltip label="Old Value">
                          <Text fontSize={'13px'}>
                            {formatNumber(Number(recent.oldRate)) || 'Nil'}
                          </Text>
                        </Tooltip>

                        <FaLongArrowAltRight />
                        <Tooltip label="New Value">
                          <Text fontSize={'13px'}>
                            {formatNumber(Number(recent.newRate))}
                          </Text>
                        </Tooltip>
                      </Flex>
                    </Box>
                  )}
                </Tabs.Panel>
                <Tabs.Panel value="all">
                  <Box p={'2em 0'}>
                    {bondLogData.map((obj, index) => (
                      <Box key={index} marginBottom={4}>
                        <div style={{ marginBottom: '8px' }}>
                          <span style={{ fontWeight: 'bold' }}>
                            {index + 1}. {obj.updatedBy}{' '}
                          </span>
                          <span>{obj.verb} on </span>
                          <span
                            style={{
                              color: '#b8b8b8',
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            {' '}
                            {getDateFormat(obj.date, 'DD MMM, YYYY hh:mm A')}
                          </span>
                        </div>
                        {obj.verb !== 'deleted' && (
                          <Flex
                            pl={'15px'}
                            marginBottom={1}
                            alignItems={'center'}
                            gap={5}
                          >
                            <Text
                              style={{
                                backgroundColor: '#f5ce93',
                                padding: '1px 8px',
                                borderRadius: '3px',
                                color: '#815b22',
                                fontSize: '12px',
                                fontWeight: '700',
                              }}
                            >
                              Description
                            </Text>
                            <Tooltip label="Old Value">
                              <Text fontSize={'13px'}>
                                {obj.oldDescription || 'Nil'}
                              </Text>
                            </Tooltip>

                            <FaLongArrowAltRight />
                            <Tooltip label="New Value">
                              <Text fontSize={'13px'}>
                                {obj.newDescription}
                              </Text>
                            </Tooltip>
                          </Flex>
                        )}

                        {obj.verb !== 'deleted' && (
                          <Flex pl={'15px'} alignItems={'center'} gap={5}>
                            <Text
                              style={{
                                backgroundColor: '#f5ce93',
                                padding: '1px 8px',
                                borderRadius: '3px',
                                color: '#815b22',
                                fontSize: '12px',
                                fontWeight: '700',
                              }}
                            >
                              Rate
                            </Text>
                            <Tooltip label="Old Value">
                              <Text fontSize={'13px'}>
                                {formatNumber(Number(obj.oldRate)) || 'Nil'}
                              </Text>
                            </Tooltip>

                            <FaLongArrowAltRight />
                            <Tooltip label="New Value">
                              <Text fontSize={'13px'}>
                                {formatNumber(Number(obj.newRate))}
                              </Text>
                            </Tooltip>
                          </Flex>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Tabs.Panel>
              </Tabs>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Box>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Text fontWeight="600" fontSize={13} pb="5px">
          Bond Rates ({formik.values.bondRates.length})
        </Text>
        <Flex gap={5} alignItems={'center'}>
          {!addMode && (
            <Flex gap={2} alignItems={'center'}>
              <Text fontSize={13}>Show Deleted</Text>
              <Switch
                id="showHistory"
                isChecked={showDeletedBondRates}
                onChange={(e) => {
                  setShowDeletedBondRates(e.target.checked);
                  onShowDeleted(e.target.checked);
                }}
              />
            </Flex>
          )}

          <Button
            bg={'#114684'}
            color={'#ffffff'}
            _hover={{ opacity: 0.8 }}
            size="sm"
            onClick={() => {
              boundArrayHelpers.push({
                description: '',
                rate: null,
                type: 'manual',
                status: 'active',
                logs: [],
              });
            }}
          >
            Add Bond Rate
          </Button>
        </Flex>
      </Flex>
      <Flex
        marginTop={'5px'}
        gap={5}
        alignItems={'center'}
        border={'1px solid rgb(221 228 237)'}
        borderRadius={'6px 6px 0 0'}
      >
        <Text
          textAlign={'center'}
          fontWeight="600"
          fontSize={13}
          pb="5px"
          width={'50%'}
        >
          Description
        </Text>
        <Text
          width={'30%'}
          textAlign={'center'}
          fontWeight="600"
          fontSize={13}
          pb="5px"
        >
          Rate
        </Text>
        <Text
          width={'20%'}
          textAlign={'center'}
          fontWeight="600"
          fontSize={13}
          pb="5px"
        >
          Action
        </Text>
      </Flex>
      {showDeletedBondRates ? (
        <Box
          border={'1px solid rgb(221 228 237)'}
          borderRadius={'0 0 6px 6px'}
          maxH={'300px'}
          overflowY={'auto'}
          borderTop={'none'}
        >
          {inactiveBonds.length > 0 ? (
            inactiveBonds.map((obj, index) => {
              return (
                <Box key={index}>
                  <Flex
                    gap={5}
                    alignItems={'center'}
                    borderTop={
                      index !== 0 ? '1px solid rgb(221 228 237)' : 'none'
                    }
                  >
                    <Box width={'50%'}>
                      <Text fontWeight="600" fontSize={13} padding={'5px'}>
                        {obj.description}
                      </Text>
                    </Box>
                    <Box
                      width={'30%'}
                      borderLeft={'1px solid rgb(221 228 237)'}
                    >
                      <Text
                        fontWeight="600"
                        fontSize={13}
                        padding={'5px'}
                        textAlign={'right'}
                      >
                        {formatNumber(Number(obj.rate))}
                      </Text>
                    </Box>
                    <Box
                      width={'20%'}
                      display={'flex'}
                      justifyContent={'center'}
                      borderLeft={'1px solid rgb(221 228 237)'}
                      padding={'5px'}
                    >
                      <Tooltip label="View Logs">
                        <ActionIcon
                          onClick={() => {
                            setBondLogData(obj?.logs || []);
                            openLogs();
                          }}
                          color="teal"
                          size={'sm'}
                        >
                          <FaList />
                        </ActionIcon>
                      </Tooltip>
                    </Box>
                  </Flex>
                </Box>
              );
            })
          ) : (
            <Text
              color={'gray.300'}
              fontSize={13}
              fontWeight={'600'}
              padding={'10px'}
              textAlign={'center'}
            >
              No deleted Bond Rates
            </Text>
          )}
        </Box>
      ) : (
        <Box
          border={'1px solid rgb(221 228 237)'}
          borderRadius={'0 0 6px 6px'}
          maxH={'300px'}
          overflowY={'auto'}
          borderTop={'none'}
          height={'88%'}
        >
          <FormikProvider value={formik}>
            <FieldArray
              name="bondRates"
              render={(bondArray) => {
                bindArrayHelpers(bondArray);
                return (
                  <div>
                    {formik.values.bondRates.length > 0 ? (
                      formik.values.bondRates.map(
                        (bond: BondRateEntry, index: number) => {
                          return (
                            <Box key={index}>
                              <Flex
                                gap={5}
                                alignItems={'center'}
                                borderTop={'1px solid rgb(221 228 237)'}
                                padding={'4px 0'}
                              >
                                <Field
                                  name={`bondRates.${index}.description`}
                                  component={BondDescriptionField}
                                />
                                <Field
                                  name={`bondRates.${index}.rate`}
                                  component={BondRateField}
                                />

                                <Flex
                                  gap={3}
                                  w={'20%'}
                                  justifyContent={'center'}
                                >
                                  <Tooltip label="Remove">
                                    <ActionIcon
                                      color="red"
                                      size={'sm'}
                                      onClick={() => {
                                        if (
                                          bond.hasOwnProperty('dateAdded') ||
                                          bond.hasOwnProperty('dateUpdated')
                                        ) {
                                          bond.status = 'inactive';
                                          onRemove(bond);
                                        }
                                        bondArray.remove(index);
                                      }}
                                    >
                                      <IconTrash />
                                    </ActionIcon>
                                  </Tooltip>
                                  {bond?.logs && bond?.logs.length > 0 ? (
                                    <Tooltip label="View Logs">
                                      <ActionIcon
                                        onClick={() => {
                                          setBondLogData(bond?.logs || []);
                                          openLogs();
                                        }}
                                        color="teal"
                                        size={'sm'}
                                      >
                                        <FaList />
                                      </ActionIcon>
                                    </Tooltip>
                                  ) : (
                                    <div style={{ width: '19.25px' }}></div>
                                  )}
                                </Flex>
                              </Flex>

                              <Flex>
                                <Box
                                  flexGrow={0}
                                  flexShrink={0}
                                  width={'50%'}
                                  marginLeft={'4px'}
                                >
                                  <ErrorMessage
                                    className="bond-field-error"
                                    component="div"
                                    name={`bondRates.${index}.description`}
                                  />
                                </Box>
                                <Box flexGrow={0} flexShrink={0} width={'30%'}>
                                  <ErrorMessage
                                    className="bond-field-error"
                                    component="div"
                                    name={`bondRates.${index}.rate`}
                                  />
                                </Box>
                              </Flex>
                            </Box>
                          );
                        }
                      )
                    ) : (
                      <Text
                        color={'gray.300'}
                        fontSize={13}
                        fontWeight={'600'}
                        padding={'10px'}
                        textAlign={'center'}
                      >
                        Click on 'Add Bond Rate' button to make an entry
                      </Text>
                    )}
                  </div>
                );
              }}
            />
          </FormikProvider>
        </Box>
      )}

      <ViewLogsModal></ViewLogsModal>
    </Box>
  );
};

export default BondFees;
