import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { AuthenticationSchema } from 'src/ValidationSchema/Auth/Index';
import { PasswordPrompt } from './PasswordPrompt';

interface PasswordProtectionProps {
  password: string;
  children: React.ReactNode;
}

interface AuthenticationSchema {
  password: string;
}

const PasswordProtection: React.FC<PasswordProtectionProps> = ({
  password,
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);
  const storedAuth = localStorage.getItem('isAuthenticated');

  useEffect(() => {
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    } else {
      setShowPrompt(true);
    }
  }, []);

  const initialValues: AuthenticationSchema = {
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AuthenticationSchema,
    onSubmit: async (values: AuthenticationSchema) => {
      try {
        handleSubmit(values.password);
      } catch (error: any) {
        console.log('error', error);
      }
    },
  });

  const handleSubmit = (inputPassword: string) => {
    if (inputPassword === password) {
      setIsAuthenticated(true);
      setShowPrompt(false);
      localStorage.setItem('isAuthenticated', 'true');
    } else {
      formik.setErrors({ password: 'Incorrect password. Please try again.' });
    }
  };

  if (!isAuthenticated && !storedAuth) {
    return (
      <>
        <PasswordPrompt
          isOpen={showPrompt}
          formik={formik}
          onClose={() => {}} // Empty function as we don't want to allow closing
        />
      </>
    );
  }

  return <>{children}</>;
};

export default PasswordProtection;
