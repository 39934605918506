import { Box, Button, Flex, Select, Text, VStack } from '@chakra-ui/react';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CiCircleInfo } from 'react-icons/ci';
import { useLocation } from 'react-router-dom';
import { financialYearOption } from 'src/Pages/Upload/data';
import { fetchApplicantUserData } from 'src/Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { fetchBrokerUserData } from 'src/Redux/Broker/BrokerProfile/BrokerProfile';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import {
  getAccountPayableData,
  getAccountReceivableData,
  getBalanceSheetData,
  getProfitLossData,
  resetAccountPayableSliceData,
} from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import {
  TFilterAccountPayable,
  TReportTimeFrame,
  TReportType,
} from 'src/Redux/Broker/Reports/APAgingSummary/state';
import {
  getOcrScanReports,
  ocrReportValidate,
} from 'src/Redux/OcrReports/slice';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useAppToast } from 'src/hooks';
import { getRandomUUID } from 'src/utils/helpers';
import ReportTabOCRListTable from '../components/OCRReportType/ReportTabOCRListTable';
import AccountPayableTable from '../components/summaryTables/AccountPayableTable';
import AccountReceivableTable from '../components/summaryTables/AccountReceivableTable';
import BalanceSheetTable from '../components/summaryTables/BalanceSheetTable';
import ProfitLossTable from '../components/summaryTables/ProfitLossTable';
import OcrReportHistoryModal from '../components/summaryTables/components/OcrReportHistoryModal';
import ValidateOCRReportModel from '../components/summaryTables/components/ValidateOCRReportModel';
import {
  QBOTimeFrameOptions,
  getHistoricalOptions,
  getHistoricalOptionsQbo,
  reportTypeOptions,
} from '../utils';

const APAgingSummary = () => {
  const dispatch = useAppDispatch();
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const applicantProfile = useAppSelector((state) => state.applicantProfile);
  const ocrReportSlice = useAppSelector((state) => state.ocrReportSlice);

  const [applicantOptions, setApplicantOptions] = useState<
    Record<string, any>[]
  >([]);
  const [selectedBrokerageId, setSelectedBrokerageId] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<TFilterAccountPayable>(
    'This Fiscal Year-to-date'
  );
  const [reportTimeFrame, setReportTimeFrame] = useState<TReportTimeFrame>(
    'Most Recent Interim Financial Statement'
  );
  const [historicalYear, setHistoricalYear] = useState<string>('');
  const [reportType, setReportType] = useState<TReportType>('OCR');
  const [APReportData, setAPReportData] = useState<Record<string, any>[]>();
  const [ocrReportData, setOcrReportData] = useState<Record<
    string,
    any
  > | null>(null);
  const [reportTabOCRReports, setReportTabOCRReports] = useState<
    Record<string, any>[]
  >([]);
  const [QBOReportData, setQBOReportData] = useState<Record<
    string,
    any
  > | null>(null);
  const [isValidateOCR, setIsValidateOCR] = useState(false);
  const [selectApplicantData, setSelectApplicantData] = useState<
    Record<string, any>
  >({});
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState<{
    contentLoader: boolean;
    btnLoader: boolean;
    validateBtn: boolean;
  }>({
    contentLoader: false,
    btnLoader: false,
    validateBtn: false,
  });
  const [reportName, setReportName] = useState({
    APReport: false,
    ARReport: false,
    balanceSheetReport: false,
    profitLossReport: false,
  });
  const [ADAData, setADAData] = useState<Record<string, any>>({
    Total: {
      ada: null,
      amountOrPerc: null,
    },
    '1 - 30': {
      ada: null,
      amountOrPerc: null,
    },
    '31 - 60': {
      ada: null,
      amountOrPerc: null,
    },
    '61 - 90': {
      ada: null,
      amountOrPerc: null,
    },
    Current: {
      ada: null,
      amountOrPerc: null,
    },
    adaType: null,
    valueType: null,
    '91 and over': {
      ada: null,
      amountOrPerc: null,
    },
  });
  const [isReportDelete, setIsReportDelete] = useState<boolean>(false);
  const [isReportAdded, setIsReportAdded] = useState<boolean>(false);
  const [
    validateReportModel,
    { open: openValidateReportModel, close: closeValidateReportModel },
  ] = useDisclosure();
  const [OcrHistory, { open: openOcrHistory, close: closeOcrHistory }] =
    useDisclosure(false);
  const brokerSlice = useAppSelector((state) => state.broker.brokerProfileData);
  const applicantSlice = useAppSelector((state) => state.applicantProfile);

  const userType = localStorage.getItem('userType');
  const toast = useAppToast();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const ocrReportId = urlParams?.get('ocrReportId');
  const applicantId = urlParams?.get('applicantId');
  // const applicationId = urlParams?.get('applicationId');
  const typeOfReport = urlParams?.get('reportType');
  const ocrReportName = urlParams?.get('reportName');
  const timeFrame = urlParams?.get('reportTimeFrame');
  const reportTabOcrId = urlParams?.get('reportTabOcrId');
  const userId = localStorage.getItem('userID');

  useEffect(() => {
    return () => {
      dispatch(resetAccountPayableSliceData({}));
    };
  }, []);

  useEffect(() => {
    if (userType === 'applicant') {
      if (!applicantSlice || !Object.keys(applicantSlice?.user).length) {
        dispatch(
          fetchApplicantUserData({ applicantId: Number(userId)! }) as any
        );
      }
    } else if (userType === 'broker') {
      if (!brokerSlice || !Object.keys(brokerSlice?.data).length) {
        dispatch(fetchBrokerUserData() as any);
      }
    }
  }, [applicantSlice, brokerSlice]);

  useEffect(() => {
    if (
      !!reportTabOcrId &&
      !!applicantId &&
      !!typeOfReport &&
      !!ocrReportName &&
      !!timeFrame
    ) {
      dispatch(
        getOcrScanReports({
          applicantId: Number(applicantId),
          type: typeOfReport,
          reportName: ocrReportName,
          timeFrame,
        })
      );
    }
  }, [reportTabOcrId]);

  useEffect(() => {
    // console.log('ocrReportId', ocrReportId);
    console.log('test');

    if (!!applicantId && !!timeFrame) {
      setSelectedBrokerageId(applicantId);
      setReportTimeFrame(timeFrame as any);
    }
    if (
      !!ocrReportId &&
      !!applicantId &&
      !!typeOfReport &&
      !!ocrReportName &&
      !!timeFrame
    ) {
      // dispatch(getOCRReportById({ id: Number(ocrReportId) }));
      dispatch(
        getOcrScanReports({
          applicantId: Number(applicantId),
          type: typeOfReport,
          reportName: ocrReportName,
          timeFrame,
        })
      );
    }
  }, [ocrReportId]);

  useEffect(() => {
    const { status, type, ocrReportList, error, msg } = ocrReportSlice;
    switch (status) {
      case 'loading':
        {
          if (type === 'GET_OCR_REPORT_LIST') {
            setIsLoading({
              ...isLoading,
              contentLoader: true,
              btnLoader: false,
            });
          }
          if (
            type === 'UPDATE_OCR_REPORT' ||
            type === 'ADD_OCR_REPORT' ||
            type === 'DELETE_OCR_REPORT'
          ) {
            setIsLoading({ ...isLoading, btnLoader: true });
          }
          if (type === 'VALIDATE_OCR_REPORT') {
            setIsLoading({ ...isLoading, validateBtn: true });
          }
        }
        break;
      case 'succeed':
        {
          if (type === 'GET_OCR_REPORT_LIST' && !!ocrReportList?.length) {
            const data = !!ocrReportId
              ? ocrReportList.find((el) => el.id == ocrReportId)
              : ocrReportList.find((el) => el.id == reportTabOcrId);
            setAPReportData(data?.ocrReportData);
            setOcrReportData(data ?? []);
            setIsValidateOCR(data?.isOcrReportValid);
            setIsLoading({
              ...isLoading,
              contentLoader: false,
              btnLoader: false,
            });
          }
          if (
            type === 'UPDATE_OCR_REPORT' ||
            type === 'DELETE_OCR_REPORT' ||
            type === 'ADD_OCR_REPORT'
          ) {
            setIsLoading({ ...isLoading, btnLoader: false });
            toast({
              title: isReportDelete
                ? 'Report deleted successfully.'
                : isReportAdded
                  ? 'Data entry added successfully.'
                  : msg,
              status: 'success',
            });
            setIsReportDelete(false);
            setIsReportAdded(false);
            if (
              (!!ocrReportId || !!reportTabOcrId) &&
              !!applicantId &&
              !!typeOfReport &&
              !!ocrReportName &&
              !!timeFrame
            ) {
              dispatch(
                getOcrScanReports({
                  applicantId: Number(applicantId),
                  type: typeOfReport,
                  reportName: ocrReportName,
                  timeFrame,
                })
              );
            }
          }
          if (type === 'VALIDATE_OCR_REPORT') {
            toast({
              title: msg,
              status: 'success',
            });
            setIsLoading({ ...isLoading, validateBtn: false });
            closeValidateReportModel();
            setIsValidateOCR(true);
            if (
              (!!ocrReportId || !!reportTabOcrId) &&
              !!applicantId &&
              !!typeOfReport &&
              !!ocrReportName &&
              !!timeFrame
            ) {
              dispatch(
                getOcrScanReports({
                  applicantId: Number(applicantId),
                  type: typeOfReport,
                  reportName: ocrReportName,
                  timeFrame,
                })
              );
            }
          }
        }
        break;
      case 'failed':
        {
          if (type === 'GET_OCR_REPORT_LIST') {
            setIsLoading({
              ...isLoading,
              contentLoader: false,
              btnLoader: false,
            });
            setAPReportData([]);
            setOcrReportData(null);
            setIsValidateOCR(false);
          }
          if (
            type === 'DELETE_OCR_REPORT' ||
            type === 'UPDATE_OCR_REPORT' ||
            type === 'ADD_OCR_REPORT'
          ) {
            setIsLoading({ ...isLoading, btnLoader: false });
            toast({
              title: error,
              status: 'error',
            });
            setIsReportDelete(false);
            setIsReportAdded(false);
          }
          if (type === 'VALIDATE_OCR_REPORT') {
            toast({
              title: error,
              status: 'error',
            });
            setIsLoading({ ...isLoading, validateBtn: false });
            closeValidateReportModel();
            setIsValidateOCR(false);
          }
        }
        break;
      default:
        break;
    }
  }, [ocrReportSlice.status]);

  useEffect(() => {
    const pathName = location.pathname.split('/').pop();
    switch (pathName) {
      case 'account-payable':
        setReportName({ ...reportName, APReport: true });
        break;
      case 'account-receivable':
        setReportName({ ...reportName, ARReport: true });
        break;
      case 'balance-sheet':
        setReportName({ ...reportName, balanceSheetReport: true });
        break;
      case 'profit-loss':
        setReportName({ ...reportName, profitLossReport: true });
        break;
      default:
        break;
    }
  }, [location]);

  useEffect(() => {
    if (userType === 'broker' && brokerageDashboard.applicantsByBrokrageData) {
      const selectedApplicantData =
        brokerageDashboard.applicantsByBrokrageData.find(
          (elm: any) => elm.applicantId == selectedBrokerageId
        );
      if (selectedApplicantData) {
        setSelectApplicantData(selectedApplicantData);
      }
    } else if (userType === 'applicant' && applicantProfile?.user) {
      setSelectApplicantData(applicantProfile.user.data);
    }
  }, [selectedBrokerageId]);

  useEffect(() => {
    if (selectedBrokerageId && reportTimeFrame && reportType && !ocrReportId) {
      setIsLoading({ ...isLoading, contentLoader: true });
      if (reportName?.APReport) {
        dispatch(
          getAccountPayableData({
            applicantId: Number(selectedBrokerageId),
            filter: reportTimeFrame,
            reportType,
            historicalYear: historicalYear,
          })
        );
      } else if (reportName?.ARReport) {
        dispatch(
          getAccountReceivableData({
            applicantId: Number(selectedBrokerageId),
            filter: reportTimeFrame,
            reportType,
            historicalYear: historicalYear,
          })
        );
      } else if (reportName?.profitLossReport) {
        dispatch(
          getProfitLossData({
            applicantId: Number(selectedBrokerageId),
            filter: reportTimeFrame,
            reportType,
            historicalYear: historicalYear,
          })
        );
      } else if (reportName?.balanceSheetReport) {
        dispatch(
          getBalanceSheetData({
            applicantId: Number(selectedBrokerageId),
            filter: reportTimeFrame,
            reportType,
            historicalYear: historicalYear,
          })
        );
      }
    }
  }, [selectedBrokerageId, reportTimeFrame, reportType, historicalYear]);

  useEffect(() => {
    if (!brokerageDashboard.applicantsByBrokrageData && userType === 'broker') {
      setLoader(true);
      dispatch(ApplicantBrokerage());
    } else if (
      brokerageDashboard.applicantsByBrokrageData &&
      userType === 'broker'
    ) {
      setApplicantOptions(brokerageDashboard.applicantsByBrokrageData);
    }
  }, []);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    if (status === 'succeed') {
      if (
        type === 'GET_APPLICANT_BY_BROKERAGE' &&
        !!applicantsByBrokrageData?.length
      ) {
        setApplicantOptions(applicantsByBrokrageData);
        setLoader(false);
      }
    }
  }, [brokerageDashboard.status, selectedBrokerageId]);

  useEffect(() => {
    if (userType && userType === 'applicant') {
      const userID = localStorage.getItem('userID') || '';
      handleBrokerageSelect(userID);
    }
  }, []);

  const getAdjustTotal = (key: string) => {
    return (
      Number(
        accountPayableReport.data?.reports[0]?.reportData?.[
          accountPayableReport.data?.reports[0]?.reportData?.length - 1
        ]?.[key]
      ) - (accountPayableReport.data?.reports[0]?.adaData?.[key]?.ada ?? 0)
    );
  };

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'GET_ACCOUNT_PAYABLE_DATA') {
          if (accountPayableReport.data?.reportType === 'QBO') {
            setAPReportData(accountPayableReport.data?.reports[0]?.reportData);
          } else {
            setReportTabOCRReports(accountPayableReport.data?.reports!);
          }
          setQBOReportData(accountPayableReport.data?.reports[0]!);
          setIsLoading({ ...isLoading, contentLoader: false });
        } else if (
          accountPayableReport.type === 'GET_ACCOUNT_RECEIVABLE_DATA'
        ) {
          // setAPReportData(accountPayableReport.data?.reportData);
          if (
            accountPayableReport.data?.reports[0]?.reportData &&
            accountPayableReport?.data?.reportType === 'QBO'
          ) {
            let tableAdjustedTotalRow = {
              Customer: 'Adjusted Total',
              Current:
                accountPayableReport.data?.reports[0]?.adjustedTotal?.Current ??
                getAdjustTotal('Current'),
              '1 - 30':
                accountPayableReport.data?.reports[0]?.adjustedTotal?.[
                  '1 - 30'
                ] ?? getAdjustTotal('1 - 30'),
              '31 - 60':
                accountPayableReport.data?.reports[0]?.adjustedTotal?.[
                  '31 - 60'
                ] ?? getAdjustTotal('31 - 60'),
              '61 - 90':
                accountPayableReport.data?.reports[0]?.adjustedTotal?.[
                  '61 - 90'
                ] ?? getAdjustTotal('61 - 90'),
              '91 and over':
                accountPayableReport.data?.reports[0]?.adjustedTotal?.[
                  '91 and over'
                ] ?? getAdjustTotal('91 and over'),
              Total:
                accountPayableReport.data?.reports[0]?.adjustedTotal?.Total ??
                getAdjustTotal('Total'),
              question: null,
              holdbackRetention: null,
              retentionValue: null,
              id: getRandomUUID(),
            };
            const adaData = {
              Customer: 'Allowance Doubtful Account (ADA)',
              Current:
                accountPayableReport.data?.reports[0]?.adaData?.Current?.ada,
              '1 - 30':
                accountPayableReport.data?.reports[0]?.adaData?.['1 - 30']?.ada,
              '31 - 60':
                accountPayableReport.data?.reports[0]?.adaData?.['31 - 60']
                  ?.ada,
              '61 - 90':
                accountPayableReport.data?.reports[0]?.adaData?.['61 - 90']
                  ?.ada,
              '91 and over':
                accountPayableReport.data?.reports[0]?.adaData?.['91 and over']
                  ?.ada,
              Total: accountPayableReport.data?.reports[0]?.adaData?.Total?.ada,
              question:
                accountPayableReport.data?.reports[0]?.adaData?.valueType,
              applyADA: accountPayableReport.data?.reports[0]?.adaData?.adaType,
              holdbackRetention: null,
              retentionValue: null,
              id: getRandomUUID(),
            };
            let updatedTable = [
              ...accountPayableReport.data?.reports[0]?.reportData,
              adaData,
              tableAdjustedTotalRow,
            ];
            setAPReportData(updatedTable);
            setADAData(accountPayableReport.data?.reports[0]?.adaData);
          } else {
            setReportTabOCRReports(accountPayableReport.data?.reports!);
          }
          setQBOReportData(accountPayableReport.data?.reports[0]!);
          setIsLoading({ ...isLoading, contentLoader: false });
        } else if (accountPayableReport.type === 'GET_PROFIT_LOSS_DATA') {
          if (accountPayableReport.data?.reportType === 'QBO') {
            setAPReportData(accountPayableReport.data?.reports[0]?.reportData);
          } else {
            setReportTabOCRReports(accountPayableReport.data?.reports!);
          }
          setQBOReportData(accountPayableReport.data?.reports[0]!);
          setIsLoading({ ...isLoading, contentLoader: false });
        } else if (accountPayableReport.type === 'GET_BALANCE_SHEET_DATA') {
          if (accountPayableReport.data?.reportType === 'QBO') {
            setAPReportData(accountPayableReport.data?.reports[0]?.reportData);
          } else {
            setReportTabOCRReports(accountPayableReport.data?.reports!);
          }
          setQBOReportData(accountPayableReport.data?.reports[0]!);
          setIsLoading({ ...isLoading, contentLoader: false });
        } else if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD'
        ) {
          dispatch(
            getAccountReceivableData({
              applicantId: Number(selectedBrokerageId),
              filter: reportTimeFrame,
              reportType,
              historicalYear: historicalYear,
            })
          );
        }
        return;
      }
      case 'loading': {
        if (
          accountPayableReport.type === 'GET_ACCOUNT_PAYABLE_DATA' ||
          accountPayableReport.type === 'GET_ACCOUNT_RECEIVABLE_DATA' ||
          accountPayableReport.type === 'GET_PROFIT_LOSS_DATA' ||
          accountPayableReport.type === 'GET_BALANCE_SHEET_DATA'
        ) {
          setIsLoading({ ...isLoading, contentLoader: true });
        }
        return;
      }
      case 'failed': {
        selectedBrokerageId !== '' &&
          accountPayableReport.error &&
          toast({
            title: accountPayableReport.error,
            status: 'error',
          });
        setIsLoading({ ...isLoading, contentLoader: false });
        setAPReportData([]);
        setQBOReportData(null);
        return;
      }
    }
  }, [accountPayableReport.status]);

  const handleBrokerageSelect = async (
    event: React.ChangeEvent<HTMLSelectElement> | string
  ) => {
    const selectedId = typeof event === 'string' ? event : event.target.value;
    setSelectedBrokerageId(selectedId);
  };

  const ValidateReportHandler = () => {
    modals.openConfirmModal({
      // title: 'Do you want to validate this report?',
      modalId: 'validateReportInfo',
      withCloseButton: false,
      closeOnConfirm: false,
      labels: { confirm: 'Yes', cancel: 'No' },
      groupProps: {
        position: 'center',
      },
      children: (
        <>
          <VStack textAlign={'center'}>
            <CiCircleInfo color="#3182ce" fontSize={64} />
            <Text fontSize={20} my={3}>
              Do you want to validate this report?
            </Text>
          </VStack>
        </>
      ),
      onCancel: () => modals.close('validateReportInfo'),
      onConfirm: () => {
        modals.close('validateReportInfo');
        openValidateReportModel();
        // modals.openConfirmModal({
        //   // title: 'This is modal at second layer',
        //   modalId: 'validateReportConfirm',
        //   withCloseButton: false,
        //   labels: { confirm: 'Submit', cancel: 'Cancel' },
        //   groupProps: {
        //     position: 'center',
        //   },
        //   children: (
        //     <VStack textAlign={'center'}>
        //       <CiCircleInfo color="#3182ce" fontSize={64} />
        //       <Text fontSize={16} my={3}>
        //         Once it is validated no more editing possible, if changes are
        //         required, need to upload new document.
        //       </Text>
        //     </VStack>
        //   ),
        //   onConfirm: async () => {
        //     try {
        //       await dispatch(
        //         ocrReportValidate({ ocrReportId: Number(ocrReportId) })
        //       );
        //       modals.closeAll();
        //     } catch (error) {
        //       console.log('validate error', error);
        //     }
        //   },
        //   onCancel: () => modals.closeAll(),
        // });
      },
    });
  };

  const submitValidateReport = () => {
    dispatch(
      ocrReportValidate({
        ocrReportId: !!ocrReportId
          ? Number(ocrReportId)
          : Number(reportTabOcrId),
        applicantId: ocrReportData?.applicantId,
      })
    );
  };

  const showTable =
    (selectedBrokerageId !== '' || userType === 'applicant') && !loader;

  return (
    <>
      <Box py={5}>
        {!loader && selectedBrokerageId === '' && userType === 'broker' && (
          <Text fontSize={'medium'}>Please Select Applicant</Text>
        )}
        <Flex alignItems={'left'} flexDirection={'column'} gap={15} mb={5}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            // mb={5}
            flexWrap={'wrap'}
          >
            <Flex alignItems={'center'} gap={1} flexWrap={'wrap'} mb={1}>
              {userType === 'broker' && (
                <Box maxW="max-content">
                  <Select
                    w="100%"
                    onChange={handleBrokerageSelect}
                    value={selectedBrokerageId}
                    name="userData.brokerageId"
                    borderRadius="5px"
                    bg={'#114684'}
                    color={'white'}
                    textAlign={'center'}
                    disabled={!!ocrReportId || !!reportTabOcrId}
                  >
                    <option
                      value={''}
                      style={{ color: 'black' }}
                      label="Select Applicant"
                    />
                    {applicantOptions?.map((user?: any, index?: number) => (
                      <option
                        style={{ color: 'black' }}
                        key={index}
                        value={user?.applicantId ?? ''}
                        label={user?.companyName ?? ''}
                      />
                    ))}
                  </Select>
                </Box>
              )}
              {selectedBrokerageId && (
                <>
                  {/* <Box maxW="max-content">
                    {!!ocrReportId ? (
                      <Select
                        w="100%"
                        onChange={(e) =>
                          setOcrReportType(e.target.value as any)
                        }
                        value={ocrReportType ?? ''}
                        name="userData.brokerageId"
                        borderRadius="5px"
                        bg={'#114684'}
                        color={'white'}
                        textAlign={'center'}
                        disabled={!!ocrReportId}
                      >
                        {Array.isArray(financialYearOption) &&
                          financialYearOption.map(
                            (item: any, index: number) => (
                              <option
                                style={{ color: 'black' }}
                                key={index}
                                value={item.value}
                                label={item.label}
                              />
                            )
                          )}
                      </Select>
                    ) : (
                      <Select
                        w="100%"
                        onChange={(e) =>
                          setSelectedOption(e.target.value as any)
                        }
                        value={selectedOption ?? ''}
                        name="userData.brokerageId"
                        borderRadius="5px"
                        bg={'#114684'}
                        color={'white'}
                        textAlign={'center'}
                      >
                        <option
                          value="This Fiscal Year-to-date"
                          style={{ color: 'black' }}
                          label="This Fiscal Year-to-date"
                        />
                        <option
                          value="Last Fiscal Year"
                          style={{ color: 'black' }}
                          label="Last Fiscal Year"
                        />
                      </Select>
                    )}
                  </Box> */}
                  {!ocrReportId && (
                    <Box minW={'150px'}>
                      <Select
                        w="100%"
                        onChange={(e) => {
                          const defaultReportType =
                            e.target.value === 'QBO'
                              ? 'This Fiscal Year-to-date'
                              : 'Most Recent Interim Financial Statement';
                          setReportType(e.target.value as TReportType);
                          setReportTimeFrame(defaultReportType);
                          setHistoricalYear('');
                          setOcrReportData(null);
                          setIsValidateOCR(false);
                        }}
                        value={reportType}
                        name="reportType"
                        borderRadius="5px"
                        bg={'#114684'}
                        color={'white'}
                        textAlign={'center'}
                        disabled={!!reportTabOcrId}
                      >
                        {reportTypeOptions?.map((item, index) => (
                          <option
                            style={{ color: 'black' }}
                            key={index}
                            value={item.value}
                            label={item.label}
                          />
                        ))}
                      </Select>
                    </Box>
                  )}
                  <Box maxW="max-content">
                    <Select
                      w="100%"
                      onChange={(e) => {
                        setReportTimeFrame(e.target.value as TReportTimeFrame);
                        if (e.target.value === 'Other') {
                          setHistoricalYear(
                            getHistoricalOptions(moment(new Date()).year())[0]
                              ?.value
                          );
                        }
                      }}
                      value={reportTimeFrame}
                      name="reportTimeFrame"
                      borderRadius="5px"
                      bg={'#114684'}
                      color={'white'}
                      textAlign={'center'}
                      disabled={!!ocrReportId || !!reportTabOcrId}
                    >
                      {reportType === 'OCR'
                        ? financialYearOption?.map((item, index) => (
                            <option
                              style={{ color: 'black' }}
                              key={index}
                              value={item.value}
                              label={item.label}
                            />
                          ))
                        : reportType === 'QBO' &&
                          QBOTimeFrameOptions?.map((item, index) => (
                            <option
                              style={{ color: 'black' }}
                              key={index}
                              value={item.value}
                              label={item.label}
                            />
                          ))}
                    </Select>
                  </Box>

                  {reportTimeFrame === 'Other' &&
                    !ocrReportId &&
                    reportType === 'QBO' && (
                      <Box minW={'150px'}>
                        <Select
                          w="100%"
                          onChange={(e) => {
                            setHistoricalYear(e.target.value as string);
                          }}
                          value={historicalYear}
                          name="historicalYear"
                          borderRadius="5px"
                          bg={'#114684'}
                          color={'white'}
                          textAlign={'center'}
                          disabled={!!reportTabOcrId}
                        >
                          {getHistoricalOptionsQbo(
                            moment(new Date()).year()
                          )?.map((item, index) => (
                            <option
                              style={{ color: 'black' }}
                              key={index}
                              value={item.value}
                              label={item.label}
                            />
                          ))}
                        </Select>
                      </Box>
                    )}

                  {reportTimeFrame === 'Other' &&
                    !ocrReportId &&
                    reportType === 'OCR' && (
                      <Box minW={'150px'}>
                        <Select
                          w="100%"
                          onChange={(e) => {
                            setHistoricalYear(e.target.value as string);
                          }}
                          value={historicalYear}
                          name="historicalYear"
                          borderRadius="5px"
                          bg={'#114684'}
                          color={'white'}
                          textAlign={'center'}
                          disabled={!!reportTabOcrId}
                        >
                          {getHistoricalOptions(moment(new Date()).year())?.map(
                            (item, index) => (
                              <option
                                style={{ color: 'black' }}
                                key={index}
                                value={item.value}
                                label={item.label}
                              />
                            )
                          )}
                        </Select>
                      </Box>
                    )}
                </>
              )}
            </Flex>
            <Flex alignItems={'center'} gap={2} flexWrap={'wrap'} mb={1}>
              {!!ocrReportId && !isValidateOCR && (
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  onClick={ValidateReportHandler}
                  isDisabled={isLoading.contentLoader || isValidateOCR}
                >
                  Upload Report
                </Button>
              )}
              {!!ocrReportId && (
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  onClick={() => {
                    openOcrHistory();
                  }}
                  isDisabled={isLoading.contentLoader}
                >
                  Report Log
                </Button>
              )}
            </Flex>
          </Flex>
          <Flex direction={'column'} gap={1}>
            {!!QBOReportData && !!Object.keys(QBOReportData).length && (
              <Text fontSize={'12px'}>
                {QBOReportData?.reportType === 'QBO'
                  ? `Note - This report is not editable as it is directly pulled from QBO`
                  : QBOReportData?.reportType === 'OCR' && !!reportTabOcrId
                    ? `Report is generated from OCR functionality`
                    : ''}
              </Text>
            )}
            {!!ocrReportId && (
              <Text fontSize={'md'} fontWeight={'600'}>
                {ocrReportData?.isAssigned && !ocrReportData?.isOcrReportValid
                  ? `Report Status: Report has been assigned to applicant on ${moment(ocrReportData?.assignedAt).format('MMM DD, YYYY hh:mm A')}`
                  : ocrReportData?.isOcrReportValid
                    ? `Report Status: Report has been validated by ${ocrReportData?.validateBy} on ${moment(ocrReportData?.validatedAt).format('MMM DD, YYYY hh:mm A')}`
                    : ''}
              </Text>
            )}
          </Flex>
          {reportType === 'QBO' || !!ocrReportId || !!reportTabOcrId ? (
            <Flex direction={'column'} alignItems={'center'}>
              {showTable && reportName?.APReport && (
                <Box maxW={'1550px'} w={'100%'}>
                  <AccountPayableTable
                    tableData={APReportData}
                    filterType={reportTimeFrame}
                    isInitialLoading={isLoading.contentLoader}
                    selectApplicantData={selectApplicantData}
                    applicantId={selectedBrokerageId}
                    isEditable={ocrReportId ? !!ocrReportId : !!reportTabOcrId}
                    ocrReportId={
                      !!ocrReportId
                        ? Number(ocrReportId)
                        : Number(reportTabOcrId)
                    }
                    ocrUpdateLoader={isLoading?.btnLoader}
                    setIsReportDelete={setIsReportDelete}
                    isValidReport={isValidateOCR}
                    ocrReportData={ocrReportData!}
                    setIsReportAdded={setIsReportAdded}
                    reportType={reportType}
                    historicalYear={historicalYear}
                    reportTabOcrId={reportTabOcrId}
                  />
                </Box>
              )}
              {showTable && reportName?.ARReport && (
                <Box maxW={'1550px'} w={'100%'}>
                  <AccountReceivableTable
                    tableData={APReportData}
                    filterType={reportTimeFrame}
                    isInitialLoading={isLoading.contentLoader}
                    selectApplicantData={selectApplicantData}
                    applicantId={selectedBrokerageId}
                    ADAData={ADAData}
                    isEditable={ocrReportId ? !!ocrReportId : !!reportTabOcrId}
                    ocrUpdateLoader={isLoading?.btnLoader}
                    isValidReport={isValidateOCR}
                    ocrReportId={
                      !!ocrReportId
                        ? Number(ocrReportId)
                        : Number(reportTabOcrId)
                    }
                    setIsReportDelete={setIsReportDelete}
                    ocrReportData={ocrReportData!}
                    setIsReportAdded={setIsReportAdded}
                    reportType={reportType}
                    historicalYear={historicalYear}
                    reportTabOcrId={reportTabOcrId}
                  />
                </Box>
              )}
              {showTable && reportName?.profitLossReport && (
                <Box maxW={'1550px'} w={'100%'}>
                  <ProfitLossTable
                    tableData={APReportData}
                    filterType={reportTimeFrame}
                    isInitialLoading={isLoading.contentLoader}
                    selectApplicantData={selectApplicantData}
                    applicantId={selectedBrokerageId}
                    isEditable={ocrReportId ? !!ocrReportId : !!reportTabOcrId}
                    ocrReportId={
                      !!ocrReportId
                        ? Number(ocrReportId)
                        : Number(reportTabOcrId)
                    }
                    ocrUpdateLoader={isLoading?.btnLoader}
                    setIsReportDelete={setIsReportDelete}
                    isValidReport={isValidateOCR}
                    ocrReportData={ocrReportData!}
                    setIsReportAdded={setIsReportAdded}
                    reportType={reportType}
                    historicalYear={historicalYear}
                    reportTabOcrId={reportTabOcrId}
                  />
                </Box>
              )}
              {showTable && reportName?.balanceSheetReport && (
                <Box maxW={'1550px'} w={'100%'}>
                  <BalanceSheetTable
                    tableData={APReportData}
                    filterType={reportTimeFrame}
                    isInitialLoading={isLoading.contentLoader}
                    selectApplicantData={selectApplicantData}
                    applicantId={selectedBrokerageId}
                    isEditable={ocrReportId ? !!ocrReportId : !!reportTabOcrId}
                    ocrReportId={
                      !!ocrReportId
                        ? Number(ocrReportId)
                        : Number(reportTabOcrId)
                    }
                    ocrUpdateLoader={isLoading?.btnLoader}
                    setIsReportDelete={setIsReportDelete}
                    isValidReport={isValidateOCR}
                    ocrReportData={ocrReportData!}
                    setIsReportAdded={setIsReportAdded}
                    reportType={reportType}
                    historicalYear={historicalYear}
                    reportTabOcrId={reportTabOcrId}
                  />
                </Box>
              )}
            </Flex>
          ) : (
            showTable && (
              <ReportTabOCRListTable
                tableList={reportTabOCRReports}
                tableContentLoader={isLoading.contentLoader}
              />
            )
          )}
        </Flex>
      </Box>
      <ValidateOCRReportModel
        isOpen={validateReportModel}
        onClose={() => {
          setIsLoading({ ...isLoading, validateBtn: false });
          closeValidateReportModel();
        }}
        onConfirm={submitValidateReport}
        submitLoader={isLoading.validateBtn}
      />
      <OcrReportHistoryModal
        isOpen={OcrHistory}
        onClose={() => {
          closeOcrHistory();
        }}
        ocrReportId={Number(ocrReportId)}
        ocrReportData={ocrReportData!}
      />
    </>
  );
};

export default APAgingSummary;
