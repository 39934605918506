import React from 'react';
import {
  AnalyticalReportDetails,
  IEfficiencyTableData,
  ILeverageTableData,
  ILiquidityTableData,
  ITableData,
} from 'src/Redux/Broker/AnalyticalReports/state';
import { useAnalyticalReportContext } from '../..';
import { optionsFields } from '../../data';
import EfficiencyRatiosTable from '../EfficiencyRatio/EfficiencyRatiosTable';
import LeverageRatiosTable from '../LeverageRatio/LeverageRatiosTable';
import LiquidityRatiosTable from '../LiquidityRatio/LiquidityRatiosTable';
import ProfitabilityRatiosTable from '../ProfitabilityRatio/ProfitabilityRatiosTable';
import YearOverYearGrowthTable from '../YearOverYearGrowth/YearOverYearGrowthTable';

const tableInfoData: {
  tableData:
    | ITableData[]
    | ILiquidityTableData[]
    | IEfficiencyTableData[]
    | ILeverageTableData[];
  editable: Record<string, any>;
  profitAndLossData: { [key: string]: optionsFields[] };
  balanceSheetData: { [key: string]: optionsFields[] };
} = {
  tableData: [
    {
      label: 'y1',
      debtToEquity: null,
      debtToAssets: null,
      equityRatio: null,
      ebitda: null,
      timesInterestEarned: null,
      tangibleNetWorth: null,
      longTermAssetsToTangibleNetWorth: null,
      totalLiabilitiesToTangibleNetWorth: null,
    },
    {
      label: 'y2',
      debtToEquity: null,
      debtToAssets: null,
      equityRatio: null,
      ebitda: null,
      timesInterestEarned: null,
      tangibleNetWorth: null,
      longTermAssetsToTangibleNetWorth: null,
      totalLiabilitiesToTangibleNetWorth: null,
    },
    {
      label: 'y3',
      debtToEquity: null,
      debtToAssets: null,
      equityRatio: null,
      ebitda: null,
      timesInterestEarned: null,
      tangibleNetWorth: null,
      longTermAssetsToTangibleNetWorth: null,
      totalLiabilitiesToTangibleNetWorth: null,
    },
    {
      label: 'y4',
      debtToEquity: null,
      debtToAssets: null,
      equityRatio: null,
      ebitda: null,
      timesInterestEarned: null,
      tangibleNetWorth: null,
      longTermAssetsToTangibleNetWorth: null,
      totalLiabilitiesToTangibleNetWorth: null,
    },
    {
      label: 'y5',
      debtToEquity: null,
      debtToAssets: null,
      equityRatio: null,
      ebitda: null,
      timesInterestEarned: null,
      tangibleNetWorth: null,
      longTermAssetsToTangibleNetWorth: null,
      totalLiabilitiesToTangibleNetWorth: null,
    },
  ],
  editable: {
    totalLiabilities: {
      value: 100,
      fields: [
        { label: 'Total Liabilities 1', value: 100, id: 'tl1' },
        { label: 'Total Liabilities 2', value: 100, id: 'tl2' },
      ],
    },
    totalEquity: {
      value: 100,
      fields: [
        { label: 'Total Equity 1', value: 100, id: 'te1' },
        { label: 'Total Equity 2', value: 100, id: 'te2' },
      ],
    },
    totalAssets: {
      value: 100,
      fields: [],
    },
    netIncome: {
      value: null,
      fields: [],
    },
    interest: {
      value: null,
      fields: [],
    },
    taxes: {
      value: null,
      fields: [],
    },
    depreciation: {
      value: null,
      fields: [],
    },
    amortization: {
      value: null,
      fields: [],
    },
    interestExpense: {
      value: 100,
      fields: [],
    },
    intangibleAssets: {
      value: 100,
      fields: [
        { label: 'Intangible Assets 1', value: 100, id: 'ia1' },
        { label: 'Intangible Assets 2', value: 100, id: 'ia2' },
      ],
    },
    totalLongTermAssets: {
      value: 100,
      fields: [
        { label: 'Long Term Assets 1', value: 100, id: 'lta1' },
        { label: 'Long Term Assets 2', value: 100, id: 'lta2' },
      ],
    },
  },
  profitAndLossData: {
    expenses: [
      {
        id: 'c5ce3970-7538-463a-aa69-fa1fbf199322',
        Label: '60100 - Consulting Fee',
        Total: 153784.03,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '9db95433-faf7-4444-a86b-a6bd047a06e9',
        Label: '60200 - Management Fee',
        Total: 120000,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '30ff45ad-4689-40b9-9814-9f460525e33d',
        Label: '60300 - Professional Fees',
        Total: 70935.66,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'a0cdd05f-6aeb-4af7-a2c2-cd6288112510',
        Label: '60400 - Estimates',
        Total: 36250.49,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '9cc495ae-7f9f-461d-9bb5-5095902724d7',
        Label: '60500 - General/Admin Expenses - Interior Design',
        Total: 8689.59,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '69e1a1f8-1d16-49ba-81db-d3f99196d30d',
        Label: '61000 - Advertising and Promotional',
        Total: 234804.26,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '8f46fdd1-7100-4900-a339-ef2c5175ea86',
        Label: '61500 - Sales Commission',
        Total: 358486.65,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '02eab7ec-9d0e-402e-9109-547feaa41e4c',
        Label: '62000 - General Administrative Expenses',
        Total: 108595.16,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'be9045a0-52ae-4bd4-9b4d-547f5386facc',
        Label: '62100 - Office Rent',
        Total: 90000,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '75035538-89fa-4aa3-89e7-a9d9369a8bfd',
        Label: '62200 - Meals and entertainment',
        Total: 25651.81,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'bd8d6dbd-2fc3-449e-8267-ccf2bbe33b7f',
        Label: '62300 - Office Utilities',
        Total: 10120.25,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '343f5edf-e0c5-4f98-8633-d4d0567a0b09',
        Label: '62400 - Telecom Expenses',
        Total: 18891.16,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'd139129b-30b7-497d-a2dc-7de665b2bb57',
        Label: '62450-Depreciation Expense',
        Total: 8276.53,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'd0a627c5-73e6-442c-8642-e7291ce72c3c',
        Label: '62500 - SaaS/Subscription',
        Total: 46987.6,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '6502cea1-4a52-495d-9f3c-2f6e58750309',
        Label: '62600 - General Insurance',
        Total: 22292.35,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '8387fc6a-d1ef-480b-8142-a5769eea0a93',
        Label: '62800 - Travel Expense',
        Total: 7907.66,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'e46cbb49-d012-42a6-bfdf-9041253aed30',
        Label: '63000 - Employee Payroll',
        Total: -1683.58,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '2d485d17-c9da-4d7a-809f-926b51c26aa5',
        Label: '63010 - GMA Salaries',
        Total: 202730.88,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '9b658252-99fc-4430-80c8-a533fc5c7748',
        Label: '63020 - PM Salaries',
        Total: 366788.48,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '10b6f8de-f5ed-43c0-9482-af6d7222b16b',
        Label: '63030 - PM Bonus',
        Total: 7000,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'a7f01a0d-72e1-40da-96af-51da9322ba12',
        Label: '63090 - Salaries expenses-accrual',
        Total: -15087.57,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '3ab714cb-249a-4ebf-af50-568dea6ce969',
        Label: '64010 - CPP Premium',
        Total: 39043.85,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'a267a3c1-e303-43b5-9f4b-5cacefc0fc35',
        Label: '64020 - EI Premium',
        Total: 15313.69,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'cd0a2ec4-8e58-4761-a0c6-0ffc6db064c5',
        Label: '64030 - WSIB Premium',
        Total: 13130.21,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'd20596f1-e8c0-430e-abd6-e7ebd51c8394',
        Label: '64040 - Employee Vacation',
        Total: 32969.58,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '9af09a7e-3848-4371-8ff1-fa610a4ed9c1',
        Label: '65010 - Auto and Truck Expenses - Lease',
        Total: 107179.18,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '302cdd9c-c22b-4da2-8ca6-a166f846b3f6',
        Label: '65020 - Auto and Truck Expenses - Gas',
        Total: 27368.2,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'c8a5a06b-28bd-41ab-873f-4e1c8cc46b95',
        Label: '65030 - Auto and Truck Expenses - Vehicle Insurance',
        Total: 17561.62,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '56fb2ca2-8af3-44d6-a86c-72952c875f8a',
        Label: '65040 - Auto and Truck Expenses - Parking incl. Violation',
        Total: 2977.17,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '73f53b64-dd52-404d-8a86-9cac68bba853',
        Label: '65050 - Auto and Truck Expenses - Vehicle Maintenance',
        Total: 11145.78,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '18c81735-7033-4045-adca-5877a05b5ca5',
        Label: '67000 - Interest expense',
        Total: 27521.14,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '187bb969-4b3e-492f-aaa0-75cc68dca71f',
        Label: '67100 - Bank & CC Service Charges',
        Total: 8280.16,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'e4dc7306-49b3-4779-87c6-58a8f6e025f1',
        Label: '67300 - Plooto Processing Fee',
        Total: 1524.34,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '21670ee8-34f4-4d4c-9103-7901da4f0a55',
        Label: '69000 - Bad debts',
        Total: 28498.26,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'b7f99bb0-af0c-4b64-8023-96d6bb39fdc4',
        Label: 'Donations',
        Total: 12550,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '3d5e0033-2d97-4c80-8946-1bfe0cd4ff3c',
        Label: '42000 - Interest Income CAD',
        Total: 221.88,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '7e4cbe14-3a65-4684-ad3f-5850b27e44e9',
        Label: '43000 - Other Income',
        Total: 8523.02,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '67dc8622-8926-46f0-b9dc-812724cf46bd',
        Label: '43030 - Unrealized Gain/Loss on Investments',
        Total: 178343.64,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '3c30c3f4-2ee1-4282-8873-1080389f48af',
        Label: '43040 - Dividend income CAD',
        Total: 1773.45,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '05e7c957-8186-4bc4-ab94-d449d69d4d05',
        Label: '43041 - Dividend income USD',
        Total: 7367.12,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '841ebe85-0a3f-4c52-9a22-1594d8077e69',
        Label: '61100 - RBC Investment Management Fees',
        Total: 13576.11,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '4cc4f052-f0b1-4d73-b1ac-4c35d8cd7751',
        Label: '70000 - Penalties and Interest',
        Total: 546.66,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
    ],
    otherData: [
      {
        id: '5072b658-c3c9-495f-b765-ff92bbfb742e',
        Label: '40000 - Reno Contract Income',
        Total: 8296426.34,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '57620a90-d1a8-476c-ac0a-b00a7d4dd788',
        Label: '41000 - Design Contract Income',
        Total: 297151.23,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '8f8899cf-a43e-4936-98fc-cd06df946d27',
        Label: '41005 - Int Design Contract Income',
        Total: 109665,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '1516d41b-73f8-42e7-8692-f9bd49d96d51',
        Label: '50000 - COG Construction Contract',
        Total: 5262821.99,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '854d834a-4ac5-4fc6-9d23-da885fcd28f3',
        Label: '50500 - COG - Design Contracts',
        Total: 215232.9,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '2c66f0f6-6293-4dd4-9801-44490b5c8895',
        Label: '50505 - COG-Int Design',
        Total: 87777.62,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '4fe48db0-cc49-4a7e-94cf-12e682e4cdd4',
        Label: '51000 - Job Site Field Labour',
        Total: 77769.62,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '65f295b1-58e3-4b2c-839c-d09b7789d1c4',
        Label: '52000 - Equipment Rental',
        Total: 18805.6,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '211b7d05-51b8-4aba-b842-9f2daca2b0b5',
        Label: '53000 - Temp Help',
        Total: 72231.87,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '127c5b8a-3c46-4446-a9f6-8a0c92b395d1',
        Label: 'Cost of Goods Sold',
        Total: 2153.99,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'c5ce3970-7538-463a-aa69-fa1fbf199322',
        Label: '60100 - Consulting Fee',
        Total: 153784.03,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '9db95433-faf7-4444-a86b-a6bd047a06e9',
        Label: '60200 - Management Fee',
        Total: 120000,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '30ff45ad-4689-40b9-9814-9f460525e33d',
        Label: '60300 - Professional Fees',
        Total: 70935.66,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'a0cdd05f-6aeb-4af7-a2c2-cd6288112510',
        Label: '60400 - Estimates',
        Total: 36250.49,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '9cc495ae-7f9f-461d-9bb5-5095902724d7',
        Label: '60500 - General/Admin Expenses - Interior Design',
        Total: 8689.59,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '69e1a1f8-1d16-49ba-81db-d3f99196d30d',
        Label: '61000 - Advertising and Promotional',
        Total: 234804.26,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '8f46fdd1-7100-4900-a339-ef2c5175ea86',
        Label: '61500 - Sales Commission',
        Total: 358486.65,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '02eab7ec-9d0e-402e-9109-547feaa41e4c',
        Label: '62000 - General Administrative Expenses',
        Total: 108595.16,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'be9045a0-52ae-4bd4-9b4d-547f5386facc',
        Label: '62100 - Office Rent',
        Total: 90000,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '75035538-89fa-4aa3-89e7-a9d9369a8bfd',
        Label: '62200 - Meals and entertainment',
        Total: 25651.81,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'bd8d6dbd-2fc3-449e-8267-ccf2bbe33b7f',
        Label: '62300 - Office Utilities',
        Total: 10120.25,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '343f5edf-e0c5-4f98-8633-d4d0567a0b09',
        Label: '62400 - Telecom Expenses',
        Total: 18891.16,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'd139129b-30b7-497d-a2dc-7de665b2bb57',
        Label: '62450-Depreciation Expense',
        Total: 8276.53,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'd0a627c5-73e6-442c-8642-e7291ce72c3c',
        Label: '62500 - SaaS/Subscription',
        Total: 46987.6,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '6502cea1-4a52-495d-9f3c-2f6e58750309',
        Label: '62600 - General Insurance',
        Total: 22292.35,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '8387fc6a-d1ef-480b-8142-a5769eea0a93',
        Label: '62800 - Travel Expense',
        Total: 7907.66,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'e46cbb49-d012-42a6-bfdf-9041253aed30',
        Label: '63000 - Employee Payroll',
        Total: -1683.58,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '2d485d17-c9da-4d7a-809f-926b51c26aa5',
        Label: '63010 - GMA Salaries',
        Total: 202730.88,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '9b658252-99fc-4430-80c8-a533fc5c7748',
        Label: '63020 - PM Salaries',
        Total: 366788.48,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '10b6f8de-f5ed-43c0-9482-af6d7222b16b',
        Label: '63030 - PM Bonus',
        Total: 7000,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'a7f01a0d-72e1-40da-96af-51da9322ba12',
        Label: '63090 - Salaries expenses-accrual',
        Total: -15087.57,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '3ab714cb-249a-4ebf-af50-568dea6ce969',
        Label: '64010 - CPP Premium',
        Total: 39043.85,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'a267a3c1-e303-43b5-9f4b-5cacefc0fc35',
        Label: '64020 - EI Premium',
        Total: 15313.69,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'cd0a2ec4-8e58-4761-a0c6-0ffc6db064c5',
        Label: '64030 - WSIB Premium',
        Total: 13130.21,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'd20596f1-e8c0-430e-abd6-e7ebd51c8394',
        Label: '64040 - Employee Vacation',
        Total: 32969.58,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '9af09a7e-3848-4371-8ff1-fa610a4ed9c1',
        Label: '65010 - Auto and Truck Expenses - Lease',
        Total: 107179.18,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '302cdd9c-c22b-4da2-8ca6-a166f846b3f6',
        Label: '65020 - Auto and Truck Expenses - Gas',
        Total: 27368.2,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'c8a5a06b-28bd-41ab-873f-4e1c8cc46b95',
        Label: '65030 - Auto and Truck Expenses - Vehicle Insurance',
        Total: 17561.62,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '56fb2ca2-8af3-44d6-a86c-72952c875f8a',
        Label: '65040 - Auto and Truck Expenses - Parking incl. Violation',
        Total: 2977.17,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '73f53b64-dd52-404d-8a86-9cac68bba853',
        Label: '65050 - Auto and Truck Expenses - Vehicle Maintenance',
        Total: 11145.78,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '18c81735-7033-4045-adca-5877a05b5ca5',
        Label: '67000 - Interest expense',
        Total: 27521.14,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '187bb969-4b3e-492f-aaa0-75cc68dca71f',
        Label: '67100 - Bank & CC Service Charges',
        Total: 8280.16,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'e4dc7306-49b3-4779-87c6-58a8f6e025f1',
        Label: '67300 - Plooto Processing Fee',
        Total: 1524.34,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '21670ee8-34f4-4d4c-9103-7901da4f0a55',
        Label: '69000 - Bad debts',
        Total: 28498.26,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: 'b7f99bb0-af0c-4b64-8023-96d6bb39fdc4',
        Label: 'Donations',
        Total: 12550,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '3d5e0033-2d97-4c80-8946-1bfe0cd4ff3c',
        Label: '42000 - Interest Income CAD',
        Total: 221.88,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '7e4cbe14-3a65-4684-ad3f-5850b27e44e9',
        Label: '43000 - Other Income',
        Total: 8523.02,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '67dc8622-8926-46f0-b9dc-812724cf46bd',
        Label: '43030 - Unrealized Gain/Loss on Investments',
        Total: 178343.64,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '3c30c3f4-2ee1-4282-8873-1080389f48af',
        Label: '43040 - Dividend income CAD',
        Total: 1773.45,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '05e7c957-8186-4bc4-ab94-d449d69d4d05',
        Label: '43041 - Dividend income USD',
        Total: 7367.12,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '841ebe85-0a3f-4c52-9a22-1594d8077e69',
        Label: '61100 - RBC Investment Management Fees',
        Total: 13576.11,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
      {
        id: '4cc4f052-f0b1-4d73-b1ac-4c35d8cd7751',
        Label: '70000 - Penalties and Interest',
        Total: 546.66,
        isSelected: false,
        groupBy: 'Profit and Loss',
      },
    ],
  },
  balanceSheetData: {
    assets: [
      {
        id: '40dc50c8-3dd3-440b-b51a-b7a6f4e7669e',
        Label: '10200 - RBC - CADxx486',
        Total: 11224.82,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '8aefa0d0-ee06-405b-ae99-2a81042c2751',
        Label: 'Plooto Clearing',
        Total: -144736.88,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '226ff0d6-c3b7-4456-994e-bb03ccff91e0',
        Label: 'Plooto Instant (Canada - CAD)',
        Total: 33.39,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'e0578d40-ac7f-4a5c-8a16-241a38fd4e6e',
        Label: '11000 - Accounts Receivable (A/R)',
        Total: 278190.71,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'afded7bc-52f0-4521-8cec-e87acb7c76ea',
        Label: '10310 - RBC Dominion Securities xx361',
        Total: 339660.9,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '1d75921a-e439-451f-ad0a-ea18050bc789',
        Label: '10330 - RBC Dominion Securities-USD',
        Total: 719885.86,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '59510594-1113-4899-b9dc-0059448e6950',
        Label: '12000 - Due from Related Parties',
        Total: 1261715.96,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c589b305-8753-44d8-9f25-6c9ee0447fa8',
        Label: '12100 - Due from 2127099 Ontario Inc.',
        Total: -14517.26,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c37430d9-f759-459b-8fe6-2feb5c1feb04',
        Label: '12200 - Due from 2604880 Ontario Inc.',
        Total: -408.15,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '400949c6-907f-48f3-ae5a-7b671b9c1a12',
        Label: '13000 - Security Deposit',
        Total: 3159.27,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '6b059af0-ac6d-42ce-8fa5-b81297e2a115',
        Label: '14000 - Clearing Account',
        Total: 3170.08,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '25f7676b-324c-46ea-93ae-0c0e4b4beb5b',
        Label: 'Advanced Payroll clearing',
        Total: 6860.53,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '8b818cc6-ebc5-457b-b316-5a4c97d62b7e',
        Label: 'Prepaid Expenses',
        Total: 3762.58,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '15fa0983-7dce-419a-a364-843f763ec19b',
        Label: 'Property, plant and equipment 15000 - Furniture and Equipment',
        Total: 63877.74,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c929d1dc-3a96-4435-b81a-6be7b0cd1a9f',
        Label: '15050 - Furniture and Equipment Accumulated Amortization',
        Total: -37487.42,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'aed07975-1a72-46fd-b12c-a09799b44727',
        Label: '15200 - Machinery and equipment',
        Total: 20304.82,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c41a5a6d-cafa-41f7-8cdf-4aedcdf63ee3',
        Label: '15250 - Machinery & Equipment Accumulated Amortization',
        Total: -17737.76,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'e8972ecc-e6d1-4527-8a5e-59fdd9ecc20d',
        Label: '15600 - Computers',
        Total: 33006.35,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '146cb7e6-1264-4a1b-99f2-1cf33dc53c44',
        Label: '15601-Computer Accumulated Depreciation',
        Total: -29124.97,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '635e50d9-db5d-4644-955b-039fd81ef8df',
        Label: '16000 - Trademark',
        Total: 10524.14,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
    ],
    liabilities: [
      {
        id: 'd0a579b1-be5c-42c5-bf0d-46e1a6d1229b',
        Label: 'Current Liabilities Accounts Payable (A/P)',
        Total: 535372.69,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '415071a6-0b40-4568-9133-40bc41233b31',
        Label: '20000 - Accounts Payable (A/P)',
        Total: 535372.69,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '35212ddf-a314-4846-a923-6d5090e40bd3',
        Label: '21000 - Company Credit Cards',
        Total: 156.47,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c5c5e307-ac48-40ef-a9fb-9e8681009239',
        Label: '21001 - xx4349',
        Total: 11000,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'f05a01f0-7098-4078-bf8f-d378cb0c3c8c',
        Label: '21010 -  xx1305 - Kevin',
        Total: 5877.51,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'e162e58e-8481-480e-91f2-9d2dd44630c6',
        Label: '21020 - xx1556 - David',
        Total: 181.03,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '0fc4e202-08fc-4322-be4b-f64f45edb735',
        Label: '21030 - xx3473 - Robert',
        Total: -2646.61,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '504bc583-79ea-467c-ba74-d70c1c0f0885',
        Label: '21050 - xx8264 - Kristofer',
        Total: 350.12,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'fe64394c-c1ae-479f-9985-967176a0c1a2',
        Label: '21060 - xx3817 - Becky',
        Total: 2242.5,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'ff6f9522-d011-498f-80c8-6d2e824e0696',
        Label: '21070 - xx1282 - Mohammad',
        Total: -2967.66,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'b27e8be7-251b-4161-bc6b-848a2875df09',
        Label: '21120 - xx0855 - Alison',
        Total: 304.61,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '73e728ac-716b-4bcb-a0b4-a2b35f012f46',
        Label: '21130 - xx2201 - Ezad',
        Total: -2881.8,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '6dcadfac-dd41-4d05-86da-61bb2487050b',
        Label: '21170 - xx3948 - Ghalib',
        Total: 91.96,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '1e779337-5931-49ef-8d8c-bc342bebaaf8',
        Label: '22000 - Accrued Liabilities',
        Total: 4950,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c7893cbc-a19d-4ee2-a624-0ec1818becfd',
        Label: '22100 - Vacation Pay Liabilities',
        Total: 3276.96,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'afed7522-9b79-46a7-bde9-a1cd0033f4ee',
        Label: '22200 - WSIB Payable',
        Total: 1354.11,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '3ce08d82-87a4-4e1a-85c7-05ed87a3d280',
        Label: '23000 - Deferred Income',
        Total: 151909.28,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '39b61ac4-10c8-4885-8a12-33d32594bcf8',
        Label: '24000 - Income Tax Payable',
        Total: -940.85,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '5b7c37e0-70fc-4a04-8b7c-80557ebfdeda',
        Label: '25000 - GST/HST Payable',
        Total: 45585.79,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '6e57f791-9bbd-46d9-99ff-a38a055ee76f',
        Label: '25100 - GST/HST Suspense',
        Total: -1432.96,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '8b36faf9-ac41-419a-ae4c-b9899ad3e632',
        Label: '26000 - Due to Related Parties',
        Total: 157500,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c687cf3a-eb13-48ca-90a2-0f00b994de17',
        Label: '26500 - Current portion of LT Loan',
        Total: 75320,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '0e4f5c3c-bd9b-4211-b78d-d2e7446eabae',
        Label: "27000 - Director's Advances",
        Total: -7268.19,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '55f85b06-a952-4758-a085-7aca7da79533',
        Label: '34000 - Unrealized Gain/Loss on Investments',
        Total: -949.46,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '1d6a7487-d795-498c-a9d8-74fdd21a15ad',
        Label: '69110 - Withholding tax on investments- USD',
        Total: 949.46,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '7f0fdbe1-1384-45c3-8646-ca31cc196d54',
        Label: '29000 - Bank Loans 29100 - BDC Loan',
        Total: 173330,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '452b1251-2386-4874-bcfd-54cbad15dd55',
        Label: '31000 - Capital Stock',
        Total: 100,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '048e536e-66b0-4635-8253-14c62cb442d1',
        Label: 'Retained Earnings',
        Total: 2470850.21,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '8e7ed248-4329-43b1-bfa8-a992c79954c3',
        Label: 'Profit for the year',
        Total: 10473.25,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
    ],
    otherData: [
      {
        id: '40dc50c8-3dd3-440b-b51a-b7a6f4e7669e',
        Label: '10200 - RBC - CADxx486',
        Total: 11224.82,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '8aefa0d0-ee06-405b-ae99-2a81042c2751',
        Label: 'Plooto Clearing',
        Total: -144736.88,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '226ff0d6-c3b7-4456-994e-bb03ccff91e0',
        Label: 'Plooto Instant (Canada - CAD)',
        Total: 33.39,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'e0578d40-ac7f-4a5c-8a16-241a38fd4e6e',
        Label: '11000 - Accounts Receivable (A/R)',
        Total: 278190.71,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'afded7bc-52f0-4521-8cec-e87acb7c76ea',
        Label: '10310 - RBC Dominion Securities xx361',
        Total: 339660.9,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '1d75921a-e439-451f-ad0a-ea18050bc789',
        Label: '10330 - RBC Dominion Securities-USD',
        Total: 719885.86,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '59510594-1113-4899-b9dc-0059448e6950',
        Label: '12000 - Due from Related Parties',
        Total: 1261715.96,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c589b305-8753-44d8-9f25-6c9ee0447fa8',
        Label: '12100 - Due from 2127099 Ontario Inc.',
        Total: -14517.26,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c37430d9-f759-459b-8fe6-2feb5c1feb04',
        Label: '12200 - Due from 2604880 Ontario Inc.',
        Total: -408.15,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '400949c6-907f-48f3-ae5a-7b671b9c1a12',
        Label: '13000 - Security Deposit',
        Total: 3159.27,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '6b059af0-ac6d-42ce-8fa5-b81297e2a115',
        Label: '14000 - Clearing Account',
        Total: 3170.08,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '25f7676b-324c-46ea-93ae-0c0e4b4beb5b',
        Label: 'Advanced Payroll clearing',
        Total: 6860.53,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '8b818cc6-ebc5-457b-b316-5a4c97d62b7e',
        Label: 'Prepaid Expenses',
        Total: 3762.58,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '15fa0983-7dce-419a-a364-843f763ec19b',
        Label: 'Property, plant and equipment 15000 - Furniture and Equipment',
        Total: 63877.74,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c929d1dc-3a96-4435-b81a-6be7b0cd1a9f',
        Label: '15050 - Furniture and Equipment Accumulated Amortization',
        Total: -37487.42,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'aed07975-1a72-46fd-b12c-a09799b44727',
        Label: '15200 - Machinery and equipment',
        Total: 20304.82,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c41a5a6d-cafa-41f7-8cdf-4aedcdf63ee3',
        Label: '15250 - Machinery & Equipment Accumulated Amortization',
        Total: -17737.76,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'e8972ecc-e6d1-4527-8a5e-59fdd9ecc20d',
        Label: '15600 - Computers',
        Total: 33006.35,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '146cb7e6-1264-4a1b-99f2-1cf33dc53c44',
        Label: '15601-Computer Accumulated Depreciation',
        Total: -29124.97,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '635e50d9-db5d-4644-955b-039fd81ef8df',
        Label: '16000 - Trademark',
        Total: 10524.14,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'd0a579b1-be5c-42c5-bf0d-46e1a6d1229b',
        Label: 'Current Liabilities Accounts Payable (A/P)',
        Total: 535372.69,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '415071a6-0b40-4568-9133-40bc41233b31',
        Label: '20000 - Accounts Payable (A/P)',
        Total: 535372.69,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '35212ddf-a314-4846-a923-6d5090e40bd3',
        Label: '21000 - Company Credit Cards',
        Total: 156.47,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c5c5e307-ac48-40ef-a9fb-9e8681009239',
        Label: '21001 - xx4349',
        Total: 11000,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'f05a01f0-7098-4078-bf8f-d378cb0c3c8c',
        Label: '21010 -  xx1305 - Kevin',
        Total: 5877.51,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'e162e58e-8481-480e-91f2-9d2dd44630c6',
        Label: '21020 - xx1556 - David',
        Total: 181.03,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '0fc4e202-08fc-4322-be4b-f64f45edb735',
        Label: '21030 - xx3473 - Robert',
        Total: -2646.61,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '504bc583-79ea-467c-ba74-d70c1c0f0885',
        Label: '21050 - xx8264 - Kristofer',
        Total: 350.12,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'fe64394c-c1ae-479f-9985-967176a0c1a2',
        Label: '21060 - xx3817 - Becky',
        Total: 2242.5,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'ff6f9522-d011-498f-80c8-6d2e824e0696',
        Label: '21070 - xx1282 - Mohammad',
        Total: -2967.66,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'b27e8be7-251b-4161-bc6b-848a2875df09',
        Label: '21120 - xx0855 - Alison',
        Total: 304.61,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '73e728ac-716b-4bcb-a0b4-a2b35f012f46',
        Label: '21130 - xx2201 - Ezad',
        Total: -2881.8,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '6dcadfac-dd41-4d05-86da-61bb2487050b',
        Label: '21170 - xx3948 - Ghalib',
        Total: 91.96,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '1e779337-5931-49ef-8d8c-bc342bebaaf8',
        Label: '22000 - Accrued Liabilities',
        Total: 4950,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c7893cbc-a19d-4ee2-a624-0ec1818becfd',
        Label: '22100 - Vacation Pay Liabilities',
        Total: 3276.96,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'afed7522-9b79-46a7-bde9-a1cd0033f4ee',
        Label: '22200 - WSIB Payable',
        Total: 1354.11,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '3ce08d82-87a4-4e1a-85c7-05ed87a3d280',
        Label: '23000 - Deferred Income',
        Total: 151909.28,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '39b61ac4-10c8-4885-8a12-33d32594bcf8',
        Label: '24000 - Income Tax Payable',
        Total: -940.85,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '5b7c37e0-70fc-4a04-8b7c-80557ebfdeda',
        Label: '25000 - GST/HST Payable',
        Total: 45585.79,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '6e57f791-9bbd-46d9-99ff-a38a055ee76f',
        Label: '25100 - GST/HST Suspense',
        Total: -1432.96,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '8b36faf9-ac41-419a-ae4c-b9899ad3e632',
        Label: '26000 - Due to Related Parties',
        Total: 157500,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: 'c687cf3a-eb13-48ca-90a2-0f00b994de17',
        Label: '26500 - Current portion of LT Loan',
        Total: 75320,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '0e4f5c3c-bd9b-4211-b78d-d2e7446eabae',
        Label: "27000 - Director's Advances",
        Total: -7268.19,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '55f85b06-a952-4758-a085-7aca7da79533',
        Label: '34000 - Unrealized Gain/Loss on Investments',
        Total: -949.46,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '1d6a7487-d795-498c-a9d8-74fdd21a15ad',
        Label: '69110 - Withholding tax on investments- USD',
        Total: 949.46,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '7f0fdbe1-1384-45c3-8646-ca31cc196d54',
        Label: '29000 - Bank Loans 29100 - BDC Loan',
        Total: 173330,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '452b1251-2386-4874-bcfd-54cbad15dd55',
        Label: '31000 - Capital Stock',
        Total: 100,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '048e536e-66b0-4635-8253-14c62cb442d1',
        Label: 'Retained Earnings',
        Total: 2470850.21,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
      {
        id: '8e7ed248-4329-43b1-bfa8-a992c79954c3',
        Label: 'Profit for the year',
        Total: 10473.25,
        isSelected: false,
        groupBy: 'Balance Sheet',
      },
    ],
  },
};

const AnalyticalTable: React.FC<{
  tableInfo: AnalyticalReportDetails;
}> = ({ tableInfo }) => {
  const { analyticalReportRatio } = useAnalyticalReportContext();
  if (!!tableInfo) {
    switch (analyticalReportRatio) {
      case 'Profitability':
        return <ProfitabilityRatiosTable TData={tableInfo!} />;
      case 'Liquidity':
        return <LiquidityRatiosTable TData={tableInfo!} />;
      case 'Efficiency':
        return <EfficiencyRatiosTable TData={tableInfo!} />;
      case 'Leverage':
        return <LeverageRatiosTable TData={tableInfo!} />;
      default:
        return <YearOverYearGrowthTable TData={tableInfo!} />;
    }
  }
};

export default AnalyticalTable;
