import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApplicationById } from 'src/Redux/Applications/slice';
import { useAppToast } from 'src/hooks';
import '../../../App.css';
import {
  AccountingSystem,
  AccountingSystemUpdateOwner,
  RescindFromApplicant,
} from '../../../Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { getApplicantHistory } from '../../../Redux/Broker/Dashboard/History';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../Redux/Store';
import { AccoutingSystemSchema } from '../../../ValidationSchema/Pages/Index';
import {
  addFocusEventListenerOnInput,
  deepObjectComparison,
  formatDate,
  getInputBg,
  getValue,
  preventAlphabetsTyping,
  removeFocusEventListenerOnInput,
  trimStringValues,
} from '../../../utils/helpers';
import { IsSubmited } from './Stepper';
import { creditSecuredOptions, interimStatementIntervalOptions } from './data';

const AccoutingSystem = forwardRef<
  any,
  {
    formData?: TAccountingSystemAPIData;
    isSubmited: IsSubmited;
    setIsSubmited: (pros: IsSubmited) => void;
  }
>(({ isSubmited, setIsSubmited, formData }, ref) => {
  console.log('AccoutingSystem-formData', formData);

  const [isAssignToApplicant, setIsAssignToApplicant] = useState(false);
  const [loader, setLoader] = useState<{ btnLoader: boolean }>({
    btnLoader: false,
  });
  const [rescindFromApplicant, setRescindFromApplicant] =
    useState<boolean>(false);
  // const [applicantObject, setApplicantObject] = useState<any>(null);
  // const [applicationData, setApplicationData] = useState<TAccountingSystem>();
  const [historyIndex, setHistoryIndex] = useState(0);
  const Toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const paramsData = useParams<{ id: string }>();
  const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
  const { showBox, showHistory, applicantId } = location.state || {};
  const businessEstablishedDatePickerRef = useRef<DatePickerInput>(null);
  const dateCompletedDatePickerRef = useRef<DatePickerInput>(null);
  const userType = localStorage.getItem('userType');
  const isApplicantUser = userType === 'applicant';
  const applicationId = formData?.applicationId;
  const brokerId = formData?.brokerId;
  // const AccoutingdFormData = formData;

  // STORE DATA
  // const userData: any = useAppSelector(
  //   (state: RootState) => state.createApplicationFormSlice.user
  // );
  // const data: any = useAppSelector(
  //   (state: RootState) => state.brokerageDashboard.user
  // );
  const HistoryData: any = useAppSelector(
    (state: RootState) => state.history.user
  );
  const createApplicationForm = useAppSelector(
    (state: RootState) => state.createApplicationFormSlice
  );
  const toast = useAppToast();
  // const applicantFetch = useAppSelector(
  //   (state: RootState) => state.notification.user
  // );
  // const accountantState = useAppSelector(
  //   (state: RootState) => state.accountant.user
  // );
  // const appliactionsSlice = useAppSelector(
  //   (state) => state.appliactionsSlice
  // );
  // console.log('appliactionsSlice', appliactionsSlice);

  // let userGetidData = userData && userData.data;
  // let brokerid = userGetidData && userGetidData.brokerId;
  // let applicationId = userGetidData && userGetidData.applicationId;
  const currentHistoryData = HistoryData?.data[historyIndex]?.details;

  // const applicantFetchData =
  //   applicantFetch && applicantFetch?.data?.applications;
  // let AccoutingdFormData = data && data?.data?.accountingSystem;
  // let ongoingapplicationId =
  //   data && data?.data?.accountingSystem?.applicationId;
  // let ongoingbrokerId = data && data?.data?.accountingSystem?.brokerId;

  // let accountantData =
  //   accountantState &&
  //   accountantState.data &&
  //   accountantState.data[0] &&
  //   accountantState?.data[0];
  // let nameOfAccountingFirm = accountantData?.nameOfAccountingFirm;
  // let phoneNumber = accountantData?.phoneNumber;

  // useEffect(() => {
  //   if (ongoingID) {
  //     fetchAccountantDataByApplicant();
  //   }
  // }, [dispatch, ongoingID]);

  // useEffect(() => {
  // if (ongoingID) {
  //   fetchOngoingApplicant();
  // }
  // if (!showHistory) {
  //   fetchDashboardData();
  // }
  /*!showHistory &&
        dispatch(fetchDashboardDetails({ applicationFilter: "" }) as any);*/
  // }, [dispatch, ongoingID]);

  // const fetchAccountantDataByApplicant = async () => {
  //   if (!!localStorage.getItem('Token')) {
  //     let actionResult = await dispatch(
  //       AccountantDataByApplicant({ id: ongoingID }) as any
  //     );
  //     if (actionResult?.payload?.status === 401) {
  //       navigate('/login');
  //     }
  //   } else {
  //     navigate('/login');
  //   }
  // };

  // Check QBO Status
  // useEffect(() => {
  //   if (applicationId) {
  //     fetchCheckQboStatus(applicationId);
  //   }
  // }, [applicationId]);

  useEffect(() => {
    const { status, type, error, user } = createApplicationForm;

    switch (status) {
      case 'loading': {
        if (
          type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
          rescindFromApplicant
        ) {
          setLoader({ ...loader, btnLoader: true });
        }
        break;
      }
      case 'succeed': {
        if (
          type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
          rescindFromApplicant
        ) {
          setLoader({ ...loader, btnLoader: false });
          setRescindFromApplicant(false);
          toast({
            status: 'success',
            title: user?.data,
          });
          fetchOngoingApplication();
        }
        break;
      }
      case 'failed': {
        if (
          type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
          rescindFromApplicant
        ) {
          setLoader({ ...loader, btnLoader: false });
          setRescindFromApplicant(false);
          toast({
            status: 'error',
            title: error,
          });
        }
        break;
      }

      default:
        break;
    }
  }, [createApplicationForm.status]);

  const fetchOngoingApplication = async () => {
    dispatch(
      getApplicationById({
        applicationId: applicationId!,
      })
    );
  };

  // const fetchDashboardData = async () => {
  //   if (!!localStorage.getItem('Token')) {
  //     let actionResult = await dispatch(
  //       fetchDashboardDetails({ applicationFilter: '' }) as any
  //     );
  //     if (actionResult?.payload?.status === 401) {
  //       navigate('/login');
  //     }
  //   } else {
  //     navigate('/login');
  //   }
  // };

  // useEffect(() => {
  //   const applicantObject = applicantFetchData?.find(
  //     (applicant: any) => applicant.applicationId === ongoingID
  //   );
  //   if (ongoingID) {
  //     setApplicantObject(applicantObject);
  //   }
  // }, [ongoingID]);

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        if (ongoingID && showHistory) {
          let actionResult = await dispatch(
            getApplicantHistory({
              applicationId: ongoingID,
              modelName: 'Accounting System',
            }) as any
          );
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
        }
      } else {
        navigate('/login');
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   console.log('update-formData', formData);

  //   setApplicationData(formData);
  // }, [formData]);

  useEffect(() => {
    const getBankDetail = (bankInfo: any) => {
      if (bankInfo) {
        return (
          bankInfo &&
          bankInfo.length > 0 &&
          bankInfo.map((item: any) => ({
            address: item?.address ?? null,
            bankName: item?.bankName ?? null,
            authorizedLineOfCredit: item?.authorizedLineOfCredit ?? null,
            bankPhoneNumber: item?.bankPhoneNumber ?? null,
            withBankSinceYear: item?.withBankSinceYear ?? null,
            accountManagerName: item?.accountManagerName ?? null,
            usedLineOfCredit: item?.usedLineOfCredit ?? null,
            creditSecured: item?.creditSecured ?? null,
            securedVal: item?.creditSecured
              ? (item?.securedVal ?? 'realEstate')
              : null,
            otherSecuredVal: item?.otherSecuredVal ?? null,
          }))
        );
      } else {
        return initialValues?.bankInfo;
      }
    };

    if (formData) {
      const apiValues = {
        applicationId: getValue(
          showHistory,
          currentHistoryData?.applicationId,
          formData?.applicationId
        ),
        brokerId: getValue(
          showHistory,
          currentHistoryData?.brokerId,
          formData?.brokerId
        ),
        applicantId: getValue(
          showHistory,
          currentHistoryData?.applicantId,
          formData.applicantId
        ),
        legalName: getValue(
          showHistory,
          currentHistoryData?.legalName,
          formData?.legalName
        ),
        businessEstablishedDate: getValue(
          showHistory,
          currentHistoryData?.businessEstablishedDate,
          formData?.businessEstablishedDate
        ),
        email: getValue(
          showHistory,
          currentHistoryData?.email,
          formData?.email
        ),
        website: getValue(
          showHistory,
          currentHistoryData?.website,
          formData?.website
        ),
        street: getValue(
          showHistory,
          currentHistoryData?.street,
          formData?.street
        ),
        city: getValue(showHistory, currentHistoryData?.city, formData?.city),
        province: getValue(
          showHistory,
          currentHistoryData?.province,
          formData?.province
        ),
        country: getValue(
          showHistory,
          currentHistoryData?.country,
          formData?.country
        ),
        postalCode: getValue(
          showHistory,
          currentHistoryData?.postalCode,
          formData?.postalCode
        ),
        telephone: getValue(
          showHistory,
          currentHistoryData?.telephone,
          formData?.telephone
        ),
        accountingFirmName: getValue(
          showHistory,
          currentHistoryData?.accountingFirmName,
          formData?.accountingFirmName
        ),
        contactName: getValue(
          showHistory,
          currentHistoryData?.contactName,
          formData?.contactName
        ),
        phoneNumber: getValue(
          showHistory,
          currentHistoryData?.phoneNumber,
          formData?.phoneNumber
        ),
        interimStatementInterval: getValue(
          showHistory,
          currentHistoryData?.interimStatementInterval,
          formData?.interimStatementInterval || 'annually'
        ),
        grossProfitAtRecentFiscalYearEnd: getValue(
          showHistory,
          currentHistoryData?.grossProfitAtRecentFiscalYearEnd,
          formData?.grossProfitAtRecentFiscalYearEnd
        ),
        dateCompleted: getValue(
          showHistory,
          currentHistoryData?.dateCompleted,
          formData?.dateCompleted
        ),
        bankName: getValue(
          showHistory,
          currentHistoryData?.bankName,
          formData?.bankName
        ),
        address: getValue(
          showHistory,
          currentHistoryData?.address,
          formData?.address
        ),
        accountManagerName: getValue(
          showHistory,
          currentHistoryData?.accountManagerName,
          formData?.accountManagerName
        ),
        bankPhoneNumber: getValue(
          showHistory,
          currentHistoryData?.bankPhoneNumber,
          formData?.bankPhoneNumber
        ),
        withBankSinceYear: getValue(
          showHistory,
          currentHistoryData?.withBankSinceYear,
          formData?.withBankSinceYear
        ),
        authorizedLineOfCredit: getValue(
          showHistory,
          currentHistoryData?.authorizedLineOfCredit,
          formData?.authorizedLineOfCredit
        ),
        bankInfo: getBankDetail(
          showHistory
            ? currentHistoryData?.bankInfo
              ? currentHistoryData?.bankInfo
              : formData?.bankInfo
            : formData?.bankInfo
        ),
        usedLineOfCredit: getValue(
          showHistory,
          currentHistoryData?.usedLineOfCredit,
          formData?.usedLineOfCredit
        ),
        returnToSender: userType === 'broker' ? false : null,
        applicationName: getValue(
          showHistory,
          currentHistoryData?.applicationName,
          formData?.applicationName
        ),
        mailingAddressSameAsOffice: getValue(
          showHistory,
          currentHistoryData?.mailingAddressSameAsOffice,
          formData?.mailingAddressSameAsOffice
        ),
        mailingStreet: getValue(
          showHistory,
          currentHistoryData?.mailingStreet,
          formData?.mailingStreet
        ),
        mailingCity: getValue(
          showHistory,
          currentHistoryData?.mailingCity,
          formData?.mailingCity
        ),
        mailingPostalCode: getValue(
          showHistory,
          currentHistoryData?.mailingPostalCode,
          formData?.mailingPostalCode
        ),
        mailingProvince: getValue(
          showHistory,
          currentHistoryData?.mailingProvince,
          formData?.mailingProvince
        ),
        mailingCountry: getValue(
          showHistory,
          currentHistoryData?.mailingCountry,
          formData?.mailingCountry
        ),
      };
      setInitialValues(apiValues);
    }
  }, [currentHistoryData, formData]);

  const [initialValues, setInitialValues] = useState<TAccountingSystem>({
    applicationId: applicationId!,
    brokerId: brokerId!,
    applicantId: formData?.applicantId!,
    legalName: null,
    businessEstablishedDate: null,
    email: null,
    website: null,
    street: null,
    city: null,
    province: null,
    postalCode: null,
    country: null,
    telephone: null,
    accountingFirmName: null,
    contactName: null,
    phoneNumber: null,
    interimStatementInterval: 'annually',
    grossProfitAtRecentFiscalYearEnd: null,
    dateCompleted: null,
    bankName: null,
    address: null,
    accountManagerName: null,
    bankPhoneNumber: null,
    withBankSinceYear: null,
    authorizedLineOfCredit: null,
    bankInfo: [
      {
        address: null,
        bankName: null,
        authorizedLineOfCredit: null,
        bankPhoneNumber: null,
        withBankSinceYear: null,
        accountManagerName: null,
        usedLineOfCredit: null,
        creditSecured: null,
        securedVal: null,
        otherSecuredVal: null,
      },
    ],
    usedLineOfCredit: null,
    returnToSender: userType === 'broker' ? false : null,
    applicationName: '',
    mailingAddressSameAsOffice: null,
    mailingStreet: null,
    mailingCity: null,
    mailingPostalCode: null,
    mailingProvince: null,
    mailingCountry: null,
  });

  const OnHandelReturnToApplicant = () => {
    setIsAssignToApplicant(true);
  };
  const OnHandleReturnToBroker = () => {
    setRescindFromApplicant(true);
    dispatch(
      RescindFromApplicant({
        applicationId: formData?.applicationId!,
        formName: 'accountingSystem',
      })
    );
  };

  // const fetchId = applicationId ? applicationId : ongoingapplicationId;
  // const fetchId = true;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: AccoutingSystemSchema,
    onSubmit: async (values: TAccountingSystem) => {
      setIsSubmited({ ...isSubmited, 'Accounting System': true });
      let res = '';
      try {
        //values = Object.assign(values, { mailingCountry: values?.country });
        if (!isAssignToApplicant) {
          const updatedData: Partial<TAccountingSystem> = deepObjectComparison(
            values,
            formData
          );
          // const keys: (keyof TAccountingSystem)[] = Object.keys(
          //   values
          // ) as (keyof TAccountingSystem)[];

          // for (const key of keys) {
          //   if (!deepObjectComparison(values[key], AccoutingdFormData[key])) {
          //     editedValues[key] = (values as any)[key];
          //   }
          // }
          const editedValues = {
            ...values,
            updatedData: updatedData,
            // returnToSender: false,
            applicationId: formData?.applicationId,
            applicantId: formData?.applicantId,
            brokerId: formData?.brokerId,
            applicationName: formData?.applicationName,
          };
          const trimedValues = trimStringValues(editedValues);
          const actionResult = await dispatch(
            AccountingSystemUpdateOwner({
              updatedData: trimedValues,
              applicationId: applicationId!,
            }) as any
          );
          const response = actionResult.payload;
          const resStatus = response.status;

          res = response.msg;
          if (resStatus === 200) {
            Toast({
              title: response.msg,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            fetchOngoingApplication();
          } else {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        } else if (isAssignToApplicant) {
          let editedValues: any = {};
          const updatedData: Partial<TAccountingSystem> = deepObjectComparison(
            values,
            formData
          );
          // const keys: (keyof TAccountingSystem)[] = Object.keys(
          //   values
          // ) as (keyof TAccountingSystem)[];

          // for (const key of keys) {
          //   // if ((values as any)[key] !== (AccoutingdFormData as any)[key]) {
          //   if (!deepObjectComparison(values[key], AccoutingdFormData[key])) {
          //     editedValues[key] = (values as any)[key];
          //   }
          // }
          editedValues = {
            ...values,
            updatedData: updatedData,
            returnToSender: userType === 'applicant' ? false : true,
            applicationId: formData?.applicationId!,
            applicantId: formData?.applicantId!,
            brokerId: formData?.brokerId!,
            applicationName: formData?.applicationName!,
          };
          const trimedValues = trimStringValues(editedValues);
          setLoader({ ...loader, btnLoader: true });
          const actionResult = await dispatch(
            AccountingSystemUpdateOwner({
              updatedData: trimedValues,
              applicationId: applicationId!,
            }) as any
          );
          if (userType === 'broker') {
            const response = actionResult?.payload;
            const resStatus = response.status;

            res = response.msg;
            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          } else {
            navigate('/');
          }
          setIsAssignToApplicant(false);
          setLoader({ ...loader, btnLoader: false });
        } else {
          const data = {
            ...values,
            applicantId: formData?.applicantId!,
            brokerId: brokerId!,
          };
          const actionResult = await dispatch(AccountingSystem(data) as any);
          const response = actionResult?.payload;
          res = response?.msg;
          const resStatus = response?.status;

          if (resStatus === 200) {
            Toast({
              title: response?.msg,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            fetchOngoingApplication();
          } else {
            if (resStatus !== 401) {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            } else {
              navigate('/login');
            }
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
    },
  });

  useImperativeHandle(ref, () => ({
    formik: formik,
  }));

  const errorStyle = {
    color: 'red',
    fontSize: '13px',
    textAlign: 'start',
    fontWeight: '500',
    position: 'absolute',
    bottom: '-20px',
  };

  // const fetchCheckQboStatus = async (appId: number) => {
  //   if (!!localStorage.getItem('Token')) {
  //     let actionResult = await dispatch(CheckQboStatus(appId) as any);
  //     if (actionResult?.payload?.status === 401) {
  //       navigate('/login');
  //     }
  //   } else {
  //     navigate('/login');
  //   }
  // };

  const handleAddBankInfo = (index: number) => {
    formik.setValues((prevValues: any) => {
      const newValues = { ...prevValues };
      const bankInfo = newValues.bankInfo || [];

      bankInfo?.push({
        address: null,
        bankName: null,
        authorizedLineOfCredit: null,
        bankPhoneNumber: null,
        withBankSinceYear: null,
        accountManagerName: null,
        usedLineOfCredit: null,
        creditSecured: null,
        securedVal: null,
        otherSecuredVal: null,
      });

      return {
        ...newValues,
        bankInfo,
      };
    });
  };

  const handleRemoveBankInfo = (index: number) => {
    formik.setValues((prevValues) => {
      const newValues = { ...prevValues };
      const bankInfo = newValues.bankInfo || [];

      if (bankInfo?.length > 1) {
        const removeIndex = index;
        bankInfo?.splice(removeIndex, 1);
        return {
          ...newValues,
          bankInfo,
        };
      }

      return newValues;
    });
  };

  return (
    <Box
      display="flex"
      gap={4}
      w="min(88rem,95%)"
      // margin={'-236px auto 0'}
      flexDirection={{ base: 'column', lg: 'row' }}
      h={'100%'}
    >
      <Container
        maxW={'container.lg'}
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        h="auto"
        p="20px"
        borderRadius="20px"
        gap={5}
        bg={'white'}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box display={'flex'} flexDirection="column" gap={5}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box width={'100%'}>
                <Text
                  textAlign="start"
                  fontSize={20}
                  color="black"
                  fontWeight="1000"
                  pr={5}
                >
                  {/* Applicant Name: {applicantHeaderName} */}
                </Text>
              </Box>
              <Box width={'100%'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="center"
                  fontSize={20}
                >
                  General & Banking{' '}
                  {/* {applicantHeaderName && applicantHeaderName.length > 0
                        ? `: ${applicantHeaderName} `
                        : null} */}
                </Text>
              </Box>

              <Box width={'100%'}></Box>
            </Box>
            {/* <Box>
                {!qboState.loading && !qboState?.qboAccess && (
                  <AlertBox
                    variant={qboState?.qboAccess ? 'success' : 'error'}
                    message={qboState?.qboReason}
                  />
                )}
              </Box> */}
            <Box
              display={'grid'}
              // gridTemplateColumns={'repeat(auto-fit,minmax(235px,1fr))'}
              gridTemplateColumns={'repeat(auto-fill,minmax(270px,1fr))'}
              gap={5}
            >
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Legal name of company
                    <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  name="legalName"
                  placeholder="legal name of company"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.legalName ?? ''}
                  focusBorderColor="#7f7f7f"
                  bgColor={getInputBg({
                    isDisable: true,
                    isHistory:
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'legalName' in currentHistoryData?.updatedData,
                  })}
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'legalName' in currentHistoryData?.updatedData &&
                    'white'
                  }
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={true}
                />
                {formik.touched.legalName && formik.errors.legalName && (
                  <Box sx={errorStyle}>{formik.errors.legalName}</Box>
                )}
              </Box>
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>Date business established</li>
                </Text>
                <DatePickerInput
                  ref={businessEstablishedDatePickerRef}
                  value={
                    formik.values.businessEstablishedDate !== null
                      ? formik.values.businessEstablishedDate
                      : undefined
                  }
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('businessEstablishedDate', date);
                  }}
                  locale="en"
                  placeholder="Date business established"
                  className={`react-datepicker-component react-datepicker-input input gray-border ${
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'businessEstablishedDate' in currentHistoryData?.updatedData
                      ? 'bg-blue'
                      : ''
                  }`}
                  disabled={showBox}
                  showOnInputClick={true}
                  onShow={() =>
                    addFocusEventListenerOnInput(
                      businessEstablishedDatePickerRef
                    )
                  }
                  onHide={() =>
                    removeFocusEventListenerOnInput(
                      businessEstablishedDatePickerRef
                    )
                  }
                />
              </Box>
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mt={1}
                >
                  <li>
                    Email address{' '}
                    <span style={{ color: 'red' }}>
                      {' '}
                      <span style={{ color: 'red' }}>*</span>
                    </span>
                  </li>
                </Text>
                <Input
                  readOnly={true}
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Email address"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.email ?? ''}
                  name="email"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  bgColor={getInputBg({
                    isDisable: true,
                    isHistory:
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'email' in currentHistoryData?.updatedData,
                  })}
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'email' in currentHistoryData?.updatedData &&
                    'white'
                  }
                  _hover={{ borderColor: '#7f7f7f' }}
                />
                {formik.touched.email && formik.errors.email && (
                  <Box sx={errorStyle}>{formik.errors.email}</Box>
                )}
              </Box>

              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>Website</li>
                </Text>
                <Input
                  readOnly={true}
                  fontSize={'13px'}
                  w="100%"
                  type="url"
                  placeholder="Website"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.website ?? ''}
                  name="website"
                  bgColor={getInputBg({
                    isDisable: true,
                    isHistory:
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'website' in currentHistoryData?.updatedData,
                  })}
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'website' in currentHistoryData?.updatedData &&
                    'white'
                  }
                />
              </Box>
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    {' '}
                    Address <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>
                <Input
                  readOnly={true}
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Address"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.street ?? ''}
                  name="street"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  bgColor={getInputBg({
                    isDisable: true,
                    isHistory:
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'street' in currentHistoryData?.updatedData,
                  })}
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'street' in currentHistoryData?.updatedData &&
                    'white'
                  }
                />
                {formik.touched.street && formik.errors.street && (
                  <Box sx={errorStyle}>{formik.errors.street}</Box>
                )}
              </Box>
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    {' '}
                    City <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>

                <Box display="flex" position={'relative'}>
                  {/* <AutoComplete
                        apiKey={GOOGLE_MAPS_API_KEY}
                        defaultValue={formik.values.city ?? ''}
                        id="city"
                        onPlaceSelected={(place) => {
                          if (place && place.formatted_address) {
                            formik.setFieldValue(
                              'city',
                              place.formatted_address
                            );
                            setShowClear(true);
                          } else {
                            formik.setFieldValue('city', '');
                            setShowClear(false);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        className={`googlelocationcity ${
                          showBox ? 'readonly' : ''
                        }`}
                        ref={autoCompleteRef}
                        style={{
                          padding: '7px 16px',
                          outline: '#7f7f7f',
                          backgroundColor:
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'city' in currentHistoryData?.updatedData
                              ? '#114684'
                              : '',
                          color:
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'city' in currentHistoryData?.updatedData
                              ? 'white'
                              : '',
                        }}
                      /> */}
                  <Input
                    readOnly={true}
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="City"
                    borderRadius="5px"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city ?? ''}
                    name="city"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    bgColor={getInputBg({
                      isDisable: true,
                      isHistory:
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'city' in currentHistoryData?.updatedData,
                    })}
                    color={
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'city' in currentHistoryData?.updatedData &&
                      'white'
                    }
                  />
                  {/* <Box
                        display={'flex'}
                        alignItems={'center'}
                        bg={'#f0f5f9'}
                        mr={2}
                        cursor={'pointer'}
                        _hover={{ color: '#114684' }}
                        position={'absolute'}
                        top={'50%'}
                        transform={'translateY(-50%)'}
                        right={0}
                      >
                        {showClear && <FaTimes onClick={handleClear} />}
                      </Box> */}
                  {formik.touched.city && formik.errors.city && (
                    <Box sx={errorStyle}>{formik.errors.city}</Box>
                  )}
                </Box>
              </Box>

              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    {' '}
                    {`${formik?.values?.country === 'United States' ? 'State' : 'Province'}`}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>
                <Input
                  readOnly={true}
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder={`${formik?.values?.country === 'United States' ? 'State' : 'Province'}`}
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.province ?? ''}
                  name="province"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  bgColor={getInputBg({
                    isDisable: true,
                    isHistory:
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'province' in currentHistoryData?.updatedData,
                  })}
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'province' in currentHistoryData?.updatedData &&
                    'white'
                  }
                />
                {formik.touched.province && formik.errors.province && (
                  <Box sx={errorStyle}>{formik.errors.province}</Box>
                )}
              </Box>
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    {' '}
                    {`${formik?.values?.country === 'United States' ? 'Zip code' : 'Postal code'}`}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder={`${formik?.values?.country === 'United States' ? 'Zip code' : 'Postal code'}`}
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  readOnly={true}
                  value={formik.values.postalCode ?? ''}
                  name="postalCode"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  bgColor={getInputBg({
                    isDisable: true,
                    isHistory:
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'postalCode' in currentHistoryData?.updatedData,
                  })}
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'postalCode' in currentHistoryData?.updatedData &&
                    'white'
                  }
                  _hover={{ borderColor: '#7f7f7f' }}
                />
                {formik.touched.postalCode && formik.errors.postalCode && (
                  <Box sx={errorStyle}>{formik.errors.postalCode}</Box>
                )}
              </Box>
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    {' '}
                    Country <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>

                {/* <Box display="flex" position={'relative'}>
                      <AutoComplete
                        apiKey={GOOGLE_MAPS_API_KEY}
                        defaultValue={formik.values.country ?? ''}
                        id="country"
                        options={{
                          types: ['country'],
                        }}
                        onPlaceSelected={(place) => {
                          if (place && place.formatted_address) {
                            formik.setFieldValue(
                              'country',
                              place.formatted_address
                            );
                          } else {
                            formik.setFieldValue('country', '');
                          }
                        }}
                        onBlur={formik.handleBlur}
                        className={`googlelocationcity ${
                          showBox ? 'readonly' : ''
                        }`}
                        ref={autoCompleteRefCountry}
                        style={{
                          padding: '7px 16px',
                          outline: '#7f7f7f',
                          backgroundColor:
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'country' in currentHistoryData?.updatedData
                              ? '#114684'
                              : '',
                          color:
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'country' in currentHistoryData?.updatedData
                              ? 'white'
                              : '',
                        }}
                      />
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        bg={'#f0f5f9'}
                        mr={2}
                        cursor={'pointer'}
                        _hover={{ color: '#114684' }}
                        position={'absolute'}
                        top={'50%'}
                        transform={'translateY(-50%)'}
                        right={0}
                      >
                        {formik?.values?.country && (
                          <FaTimes onClick={handleClearCountry} />
                        )}
                      </Box>
                      {formik.touched.country && formik.errors.country && (
                        <Box sx={errorStyle}>{formik.errors.country}</Box>
                      )}
                    </Box> */}
                {/* <Select
                    onChange={formik.handleChange}
                    value={formik.values.country ?? ''}
                    name="country"
                    fontSize={'13px'}
                    disabled={true}
                    _disabled={{ opacity: 1 }}
                    w="100%"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    bgColor={getInputBg({
                      isDisable: true,
                      isHistory:
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'country' in currentHistoryData?.updatedData,
                    })}
                    color={
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'country' in currentHistoryData?.updatedData &&
                      'white'
                    }
                  >
                    {Array.isArray(countryOptions) &&
                      countryOptions.map((item: any, index: number) => (
                        <option
                          style={{ color: 'black' }}
                          key={index}
                          value={item.value}
                          label={item.label}
                        />
                      ))}
                  </Select> */}
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Country"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  readOnly={true}
                  value={formik.values.country ?? ''}
                  name="country"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  bgColor={getInputBg({
                    isDisable: true,
                    isHistory:
                      showHistory &&
                      currentHistoryData?.updatedData &&
                      'country' in currentHistoryData?.updatedData,
                  })}
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'country' in currentHistoryData?.updatedData &&
                    'white'
                  }
                  _hover={{ borderColor: '#7f7f7f' }}
                />
              </Box>
              <Box
                gap={'8px'}
                gridColumnStart={1}
                gridColumnEnd={{ sm: 1, md: 2, lg: 3, xl: 4 }}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Use the Office address as the mailing address?
                    <span style={{ color: 'red' }}>
                      {' '}
                      <span style={{ color: 'red' }}>*</span>
                    </span>
                  </li>
                </Text>
                <Stack spacing={5} direction="row" flex={1}>
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.mailingAddressSameAsOffice === true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'mailingAddressSameAsOffice',
                        e.target.checked
                      );
                    }}
                    readOnly={true}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.mailingAddressSameAsOffice === false
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'mailingAddressSameAsOffice',
                        !e.target.checked
                      );
                    }}
                    readOnly={true}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>
              {formik.values.mailingAddressSameAsOffice === false && (
                <>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    gap={'8px'}
                    position={'relative'}
                    gridColumnStart={1}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        Address <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>
                    <Input
                      readOnly={true}
                      fontSize={'13px'}
                      w="100%"
                      type="text"
                      placeholder="Address"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={formik.values.mailingStreet ?? ''}
                      name="mailingStreet"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      bgColor={getInputBg({
                        isDisable: true,
                        isHistory:
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'mailingStreet' in currentHistoryData?.updatedData,
                      })}
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'mailingStreet' in currentHistoryData?.updatedData &&
                        'white'
                      }
                    />
                    {formik.touched.mailingStreet &&
                      formik.errors.mailingStreet && (
                        <Box sx={errorStyle}>{formik.errors.mailingStreet}</Box>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    gap={'8px'}
                    position={'relative'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        City <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>

                    <Box display="flex" position={'relative'}>
                      {/* <AutoComplete
                            apiKey={GOOGLE_MAPS_API_KEY}
                            defaultValue={formik.values.mailingCity ?? ''}
                            onPlaceSelected={(place) => {
                              if (place && place.formatted_address) {
                                formik.setFieldValue(
                                  'mailingCity',
                                  place.formatted_address
                                );
                                setShowClear(true);
                              } else {
                                formik.setFieldValue('mailingCity', '');
                                setShowClear(false);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            className={`googlelocationcity ${
                              showBox ? 'readonly' : ''
                            }`}
                            ref={autoCompleteRef}
                            style={{
                              padding: '7px 16px',
                              outline: '#7f7f7f',
                              backgroundColor:
                                showHistory &&
                                currentHistoryData?.updatedData &&
                                'mailingCity' in currentHistoryData?.updatedData
                                  ? '#114684'
                                  : '',
                              color:
                                showHistory &&
                                currentHistoryData?.updatedData &&
                                'mailingCity' in currentHistoryData?.updatedData
                                  ? 'white'
                                  : '',
                            }}
                          /> */}
                      <Input
                        readOnly={true}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="City"
                        borderRadius="5px"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mailingCity ?? ''}
                        name="mailingCity"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        bgColor={getInputBg({
                          isDisable: true,
                          isHistory:
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'mailingCity' in currentHistoryData?.updatedData,
                        })}
                        color={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'mailingCity' in currentHistoryData?.updatedData &&
                          'white'
                        }
                      />
                      {/* <Box
                            display={'flex'}
                            alignItems={'center'}
                            bg={'#f0f5f9'}
                            mr={2}
                            cursor={'pointer'}
                            _hover={{ color: '#114684' }}
                            position={'absolute'}
                            top={'50%'}
                            transform={'translateY(-50%)'}
                            right={0}
                          >
                            {showClear && <FaTimes onClick={handleClear} />}
                          </Box> */}
                      {formik.touched.mailingCity &&
                        formik.errors.mailingCity && (
                          <Box sx={errorStyle}>{formik.errors.mailingCity}</Box>
                        )}
                    </Box>
                  </Box>

                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    gap={'8px'}
                    position={'relative'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        {`${formik?.values?.mailingCountry === 'United States' ? 'State' : 'Province'}`}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>
                    <Input
                      readOnly={true}
                      fontSize={'13px'}
                      w="100%"
                      type="text"
                      placeholder={`${formik?.values?.mailingCountry === 'United States' ? 'State' : 'Province'}`}
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={formik.values.mailingProvince ?? ''}
                      name="mailingProvince"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      bgColor={getInputBg({
                        isDisable: true,
                        isHistory:
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'mailingProvince' in currentHistoryData?.updatedData,
                      })}
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'mailingProvince' in currentHistoryData?.updatedData &&
                        'white'
                      }
                    />
                    {formik.touched.mailingProvince &&
                      formik.errors.mailingProvince && (
                        <Box sx={errorStyle}>
                          {formik.errors.mailingProvince}
                        </Box>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    gap={'8px'}
                    position={'relative'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        {`${formik?.values?.mailingCountry === 'United States' ? 'Zip code' : 'Postal code'}`}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="text"
                      placeholder={`${formik?.values?.mailingCountry === 'United States' ? 'Zip code' : 'Postal code'}`}
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      readOnly={true}
                      value={formik.values.mailingPostalCode ?? ''}
                      name="mailingPostalCode"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      bgColor={getInputBg({
                        isDisable: true,
                        isHistory:
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'mailingPostalCode' in
                            currentHistoryData?.updatedData,
                      })}
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'mailingPostalCode' in
                          currentHistoryData?.updatedData &&
                        'white'
                      }
                      _hover={{ borderColor: '#7f7f7f' }}
                    />
                    {formik.touched.mailingPostalCode &&
                      formik.errors.mailingPostalCode && (
                        <Box sx={errorStyle}>
                          {formik.errors.mailingPostalCode}
                        </Box>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    gap={'8px'}
                    position={'relative'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        Country <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>

                    {/* <Box display="flex" position={'relative'}>
                          <AutoComplete
                            apiKey={GOOGLE_MAPS_API_KEY}
                            defaultValue={formik.values.mailingCountry ?? ''}
                            id="mailingCountry"
                            options={{
                              types: ['country'],
                            }}
                            onPlaceSelected={(place) => {
                              if (place && place.formatted_address) {
                                formik.setFieldValue(
                                  'mailingCountry',
                                  place.formatted_address
                                );
                              } else {
                                formik.setFieldValue('mailingCountry', '');
                              }
                            }}
                            onBlur={formik.handleBlur}
                            className={`googlelocationcity ${
                              showBox ? 'readonly' : ''
                            }`}
                            ref={refmailingCountry}
                            style={{
                              padding: '7px 16px',
                              outline: '#7f7f7f',
                              backgroundColor:
                                showHistory &&
                                currentHistoryData?.updatedData &&
                                'mailingCountry' in
                                  currentHistoryData?.updatedData
                                  ? '#114684'
                                  : '',
                              color:
                                showHistory &&
                                currentHistoryData?.updatedData &&
                                'mailingCountry' in
                                  currentHistoryData?.updatedData
                                  ? 'white'
                                  : '',
                            }}
                          />
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            bg={'#f0f5f9'}
                            mr={2}
                            cursor={'pointer'}
                            _hover={{ color: '#114684' }}
                            position={'absolute'}
                            top={'50%'}
                            transform={'translateY(-50%)'}
                            right={0}
                          >
                            {formik?.values?.mailingCountry && (
                              <FaTimes onClick={handleClearmailCountry} />
                            )}
                          </Box>
                          {formik.touched.mailingCountry &&
                            formik.errors.mailingCountry && (
                              <Box sx={errorStyle}>
                                {formik.errors.mailingCountry}
                              </Box>
                            )}
                        </Box> */}
                    {/* <Select
                        onChange={formik.handleChange}
                        value={formik.values.mailingCountry ?? ''}
                        name="mailingCountry"
                        fontSize={'13px'}
                        w="100%"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        disabled={true}
                        _disabled={{ opacity: 1 }}
                        bgColor={getInputBg({
                          isDisable: true,
                          isHistory:
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'mailingCountry' in currentHistoryData?.updatedData,
                        })}
                        color={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'mailingCountry' in currentHistoryData?.updatedData &&
                          'white'
                        }
                      >
                        {Array.isArray(countryOptions) &&
                          countryOptions.map((item: any, index: number) => (
                            <option
                              style={{ color: 'black' }}
                              key={index}
                              value={item.value}
                              label={item.label}
                            />
                          ))}
                      </Select> */}
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="text"
                      placeholder="Country"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      readOnly={true}
                      value={formik.values.mailingCountry ?? ''}
                      name="mailingCountry"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      bgColor={getInputBg({
                        isDisable: true,
                        isHistory:
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'mailingCountry' in currentHistoryData?.updatedData,
                      })}
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'mailingCountry' in currentHistoryData?.updatedData &&
                        'white'
                      }
                      _hover={{ borderColor: '#7f7f7f' }}
                    />
                  </Box>
                  {/* <Box flex={{ base: '1', md: '1' }}></Box> */}
                </>
              )}
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
                gridColumnStart={1}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>Telephone</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Telephone"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  readOnly={showBox}
                  value={formik.values.telephone ?? ''}
                  name="telephone"
                  bgColor={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'telephone' in currentHistoryData?.updatedData &&
                    '#114684'
                  }
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'telephone' in currentHistoryData?.updatedData &&
                    'white'
                  }
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                />
              </Box>

              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    {' '}
                    Name of Accounting Firm{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Name of Accounting Firm"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  readOnly={showBox}
                  value={formik.values.accountingFirmName ?? ''}
                  name="accountingFirmName"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  bgColor={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'accountingFirmName' in currentHistoryData?.updatedData &&
                    '#114684'
                  }
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'accountingFirmName' in currentHistoryData?.updatedData &&
                    'white'
                  }
                />
                {formik.touched.accountingFirmName &&
                  formik.errors.accountingFirmName && (
                    <Box sx={errorStyle}>
                      {formik.errors.accountingFirmName}
                    </Box>
                  )}
              </Box>
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>Contact Name at Accounting Firm</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Contact Name at Accounting Firm"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.contactName ?? ''}
                  name="contactName"
                  readOnly={showBox}
                  bgColor={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'contactName' in currentHistoryData?.updatedData &&
                    '#114684'
                  }
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'contactName' in currentHistoryData?.updatedData &&
                    'white'
                  }
                />
              </Box>
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>Phone number</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="tel"
                  placeholder="Phone number"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber ?? ''}
                  name="phoneNumber"
                  readOnly={showBox}
                  bgColor={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'phoneNumber' in currentHistoryData?.updatedData &&
                    '#114684'
                  }
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'phoneNumber' in currentHistoryData?.updatedData &&
                    'white'
                  }
                  onKeyDown={preventAlphabetsTyping}
                />
              </Box>

              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    What intervals are interim statements prepared?{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>
                {/* <Input
                    fontSize={"13px"}
                    w="100%"
                    type="text"
                    placeholder=" What intervals are interim statements prepared: Monthly, quarterly, semi-annually, annually"
                    borderRadius="5px"
                    onChange={formik.handleChange}
                    readOnly={showBox}
                    value={formik.values.interimStatementInterval ?? ""}
                    name="interimStatementInterval"
                    focusBorderColor="#FF0000"
                    borderColor={"#FF0000"}
                    _hover={{ borderColor: "#FF0000" }}
                    bgColor={
                      showHistory &&
                      formik.values.interimStatementInterval &&
                      "#114684"
                    }
                    color={
                      showHistory &&
                      formik.values.interimStatementInterval &&
                      "white"
                    }
                  /> */}
                <Select
                  onChange={formik.handleChange}
                  value={formik.values.interimStatementInterval ?? ''}
                  name="interimStatementInterval"
                  fontSize={'13px'}
                  disabled={showBox}
                  _disabled={{ opacity: 1 }}
                  w="100%"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  bgColor={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'interimStatementInterval' in
                      currentHistoryData?.updatedData &&
                    '#114684'
                  }
                  color={
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'interimStatementInterval' in
                      currentHistoryData?.updatedData &&
                    'white'
                  }
                >
                  {Array.isArray(interimStatementIntervalOptions) &&
                    interimStatementIntervalOptions.map(
                      (item: any, index: number) => (
                        <option
                          style={{ color: 'black' }}
                          key={index}
                          value={item.value}
                          label={item.label}
                        />
                      )
                    )}
                </Select>
                {/* {formik.touched.interimStatementInterval &&
                    formik.errors.interimStatementInterval && (
                      <Box sx={errorStyle}>
                        {formik.errors.interimStatementInterval}
                      </Box>
                    )} */}
              </Box>
              {/* <Box
                    flex={{ base: "1", md: "1" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        Gross profit at most recent Fiscal Year End{" "}
                        <span style={{ color: "red" }}>*</span>
                      </li>
                    </Text>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="tel"
                      // onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      placeholder="Gross profit at most recent Fiscal Year End"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={"#7f7f7f"}
                      _hover={{ borderColor: "#7f7f7f" }}
                      onChange={formik.handleChange}
                      readOnly={showBox}
                      value={
                        formik.values.grossProfitAtRecentFiscalYearEnd ?? ""
                      }
                      name="grossProfitAtRecentFiscalYearEnd"
                      bgColor={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        "grossProfitAtRecentFiscalYearEnd" in
                          currentHistoryData?.updatedData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        "grossProfitAtRecentFiscalYearEnd" in
                          currentHistoryData?.updatedData &&
                        "white"
                      }
                      onKeyDown={preventAlphabetsTyping}
                    />
                    {formik.touched.grossProfitAtRecentFiscalYearEnd &&
                      formik.errors.grossProfitAtRecentFiscalYearEnd && (
                        <Box sx={errorStyle}>
                          {formik.errors.grossProfitAtRecentFiscalYearEnd}
                        </Box>
                      )}
                  </Box> */}
              <Box
                flex={{ base: '1', md: '1' }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                gap={'8px'}
                position={'relative'}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Date of Fiscal Year End{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </li>
                </Text>

                <DatePickerInput
                  ref={dateCompletedDatePickerRef}
                  value={
                    formik.values.dateCompleted !== null
                      ? formik.values.dateCompleted
                      : undefined
                  }
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('dateCompleted', date);
                  }}
                  locale="en"
                  placeholder="Date of Fiscal Year End"
                  className={`react-datepicker-component react-datepicker-input input ${
                    showHistory &&
                    currentHistoryData?.updatedData &&
                    'dateCompleted' in currentHistoryData?.updatedData
                      ? 'white-border bg-blue'
                      : 'gray-border accounting-fiscal-year-end-date'
                  }`}
                  disabled={true}
                  showOnInputClick={true}
                  onShow={() =>
                    addFocusEventListenerOnInput(dateCompletedDatePickerRef)
                  }
                  onHide={() =>
                    removeFocusEventListenerOnInput(dateCompletedDatePickerRef)
                  }
                />
                {formik.touched.dateCompleted &&
                  formik.errors.dateCompleted && (
                    <Box sx={errorStyle}>{formik.errors.dateCompleted}</Box>
                  )}
              </Box>

              {/* <Box
                    flex={{ base: "1", md: "1" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {" "}
                        Name of bank <span style={{ color: "red" }}>*</span>
                      </li>
                    </Text>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="text"
                      placeholder="Name of bank"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      readOnly={showBox}
                      value={formik.values.bankName ?? ""}
                      name="bankName"
                      focusBorderColor="#5BBE3F"
                      borderColor={"#5BBE3F"}
                      _hover={{ borderColor: "#5BBE3F" }}
                      bgColor={
                        showHistory &&
                        currentHistoryData &&
                        "bankName" in currentHistoryData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData &&
                        "bankName" in currentHistoryData &&
                        "white"
                      }
                    />
                    {formik.touched.bankName && formik.errors.bankName && (
                      <Box sx={errorStyle}>{formik.errors.bankName}</Box>
                    )}
                  </Box>
                  <Box
                    flex={{ base: "1", md: "1" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Address</li>
                    </Text>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="text"
                      placeholder="Address"
                      borderRadius="5px"
                      focusBorderColor="#5BBE3F"
                      borderColor={"#5BBE3F"}
                      _hover={{ borderColor: "#5BBE3F" }}
                      onChange={formik.handleChange}
                      value={formik.values.address ?? ""}
                      name="address"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData &&
                        "address" in currentHistoryData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData &&
                        "address" in currentHistoryData &&
                        "white"
                      }
                    />
                  </Box>

                  <Box
                    flex={{ base: "1", md: "1" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {" "}
                        Authorized line of credit{" "}
                        <span style={{ color: "red" }}>*</span>
                      </li>
                    </Text>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="number"
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      placeholder="Authorized line of credit "
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      readOnly={showBox}
                      value={formik.values.authorizedLineOfCredit ?? ""}
                      name="authorizedLineOfCredit"
                      focusBorderColor="#008000"
                      borderColor={"#008000"}
                      _hover={{ borderColor: "#008000" }}
                      bgColor={
                        showHistory &&
                        currentHistoryData &&
                        "authorizedLineOfCredit" in currentHistoryData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData &&
                        "authorizedLineOfCredit" in currentHistoryData &&
                        "white"
                      }
                    />
                    {formik.touched.authorizedLineOfCredit &&
                      formik.errors.authorizedLineOfCredit && (
                        <Box sx={errorStyle}>
                          {formik.errors.authorizedLineOfCredit}
                        </Box>
                      )}
                  </Box> */}
              {/* {
                    formik.values.bankInfo && formik.values.bankInfo.length > 0 && formik.values.bankInfo.map((data:any,index:any) => {
                      return (
                        <>
                          <Box
                            flex={{ base: "1", md: "1" }}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            gap={"8px"}
                            position={"relative"}
                          >
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <li>
                                {" "}
                                Name of bank{" "}
                                <span style={{ color: "red" }}>*</span>
                              </li>
                            </Text>
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="text"
                              placeholder="Name of bank"
                              borderRadius="5px"
                              onChange={formik.handleChange}
                              readOnly={showBox}
                              value={
                                formik.values?.bankInfo[index].bankName ?? ""
                              }
                              name={`bankInfo[${index}].bankName`}
                              focusBorderColor="#5BBE3F"
                              borderColor={"#5BBE3F"}
                              _hover={{ borderColor: "#5BBE3F" }}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "bankName" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "bankName" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "white"
                              }
                            />
                            {formik.touched.bankInfo &&
                              formik.errors.bankInfo &&
                              typeof formik.touched.bankInfo[index] !==
                                "string" &&
                              formik.errors.bankInfo[index] && // Ensure that the value is not null or undefined
                              (formik.errors.bankInfo[index] as any)
                                ?.bankName && (
                                <Box sx={errorStyle}>
                                  {
                                    (formik.errors.bankInfo[index] as any)
                                      .bankName
                                  }
                                </Box>
                              )}
                          </Box>
                          <Box
                            flex={{ base: "1", md: "1" }}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            gap={"8px"}
                            position={"relative"}
                          >
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <li>
                                Address <span style={{ color: "red" }}>*</span>
                              </li>
                            </Text>
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="text"
                              placeholder="Address"
                              borderRadius="5px"
                              focusBorderColor="#5BBE3F"
                              borderColor={"#5BBE3F"}
                              _hover={{ borderColor: "#5BBE3F" }}
                              onChange={formik.handleChange}
                              value={
                                formik.values?.bankInfo[index].address ?? ""
                              }
                              name={`bankInfo[${index}].address`}
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "address" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "address" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "white"
                              }
                            />
                            {formik.touched.bankInfo &&
                              formik.errors.bankInfo &&
                              typeof formik.touched.bankInfo[index] !==
                                "string" &&
                              formik.errors.bankInfo[index] && // Ensure that the value is not null or undefined
                              (formik.errors.bankInfo[index] as any)
                                ?.address && (
                                <Box sx={errorStyle}>
                                  {
                                    (formik.errors.bankInfo[index] as any)
                                      .address
                                  }
                                </Box>
                              )}
                          </Box>

                          <Box
                            flex={{ base: "1", md: "1" }}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            gap={"8px"}
                            position={"relative"}
                          >
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <li>
                                {" "}
                                Authorized line of credit{" "}
                                <span style={{ color: "red" }}>*</span>
                              </li>
                            </Text>
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="number"
                              onWheel={(e) =>
                                (e.target as HTMLInputElement).blur()
                              }
                              placeholder="Authorized line of credit "
                              borderRadius="5px"
                              onChange={formik.handleChange}
                              readOnly={showBox}
                              value={
                                formik.values?.bankInfo[index]
                                  .authorizedLineOfCredit ?? ""
                              }
                              name={`bankInfo[${index}].authorizedLineOfCredit`}
                              focusBorderColor="#008000"
                              borderColor={"#008000"}
                              _hover={{ borderColor: "#008000" }}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "authorizedLineOfCredit" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "authorizedLineOfCredit" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "white"
                              }
                            />
                            {formik.touched.bankInfo &&
                              formik.errors.bankInfo &&
                              typeof formik.touched.bankInfo[index] !==
                                "string" &&
                              formik.errors.bankInfo[index] && // Ensure that the value is not null or undefined
                              (formik.errors.bankInfo[index] as any)
                                ?.authorizedLineOfCredit && (
                                <Box sx={errorStyle}>
                                  {
                                    (formik.errors.bankInfo[index] as any)
                                      .authorizedLineOfCredit
                                  }
                                </Box>
                              )}
                          </Box>
                          <Box
                            flex={{ base: "1", md: "1" }}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            gap={"8px"}
                            position={"relative"}
                          >
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <li> Phone number</li>
                            </Text>
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="tel"
                              placeholder="Phone number"
                              borderRadius="5px"
                              focusBorderColor="#5BBE3F"
                              borderColor={"#5BBE3F"}
                              _hover={{ borderColor: "#5BBE3F" }}
                              onChange={formik.handleChange}
                              value={formik.values?.bankInfo[index]
                                .bankPhoneNumber ?? ""}
                              name={`bankInfo[${index}].bankPhoneNumber`}
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "bankPhoneNumber" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "bankPhoneNumber" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "white"
                              }
                              onKeyDown={preventAlphabetsTyping}
                            />
                          </Box>

                          <Box
                            flex={{ base: "1", md: "1" }}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            gap={"8px"}
                            position={"relative"}
                          >
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <li> With bank since what year</li>
                            </Text>
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="number"
                              onWheel={(e) =>
                                (e.target as HTMLInputElement).blur()
                              }
                              placeholder="With bank since what year"
                              borderRadius="5px"
                              focusBorderColor="#008000"
                              borderColor={"#008000"}
                              _hover={{ borderColor: "#008000" }}
                              onChange={formik.handleChange}
                              value={formik.values?.bankInfo[index]
                                .withBankSinceYear ?? ""}
                              name={`bankInfo[${index}].withBankSinceYear`}
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "withBankSinceYear" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "withBankSinceYear" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "white"
                              }
                              onKeyDown={preventAlphabetsTyping}
                            />
                          </Box>
                          <Box
                            flex={{ base: "1", md: "1" }}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            gap={"8px"}
                            position={"relative"}
                          >
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <li> Account manager name</li>
                            </Text>
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="text"
                              placeholder="Account manager name"
                              borderRadius="5px"
                              focusBorderColor="#5BBE3F"
                              borderColor={"#5BBE3F"}
                              _hover={{ borderColor: "#5BBE3F" }}
                              onChange={formik.handleChange}
                              value={formik.values?.bankInfo[index]
                                .accountManagerName ?? ""}
                              name={`bankInfo[${index}].accountManagerName`}
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "accountManagerName" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "accountManagerName" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "white"
                              }
                            />
                          </Box>
                          <Box
                            flex={{ base: "1", md: "1" }}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            gap={"8px"}
                            position={"relative"}
                          >
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <li>
                                How much of authorized line of credit is
                                currently used?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </li>
                            </Text>
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="number"
                              onWheel={(e) =>
                                (e.target as HTMLInputElement).blur()
                              }
                              placeholder="How much of authorized line of credit is currently used?"
                              borderRadius="5px"
                              onChange={formik.handleChange}
                              readOnly={showBox}
                              value={formik.values?.bankInfo[index]
                                .usedLineOfCredit ?? ""}
                              name={`bankInfo[${index}].usedLineOfCredit`}
                              focusBorderColor="#008000"
                              borderColor={"#008000"}
                              _hover={{ borderColor: "#008000" }}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "usedLineOfCredit" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                currentHistoryData?.bankInfo?.[index] &&
                                "usedLineOfCredit" in
                                  currentHistoryData?.bankInfo?.[index] &&
                                "white"
                              }
                            />
                            {formik.touched.bankInfo &&
                              formik.errors.bankInfo &&
                              typeof formik.touched.bankInfo[index] !==
                                "string" &&
                              formik.errors.bankInfo[index] && // Ensure that the value is not null or undefined
                              (formik.errors.bankInfo[index] as any)
                                ?.usedLineOfCredit && (
                                <Box sx={errorStyle}>
                                  {
                                    (formik.errors.bankInfo[index] as any)
                                      .usedLineOfCredit
                                  }
                                </Box>
                              )}
                          </Box>
                        </>
                      );
                    })
                  } */}
              {/* <Box
                    flex={{ base: "1", md: "1" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li> Phone number</li>
                    </Text>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="tel"
                      placeholder="Phone number"
                      borderRadius="5px"
                      focusBorderColor="#5BBE3F"
                      borderColor={"#5BBE3F"}
                      _hover={{ borderColor: "#5BBE3F" }}
                      onChange={formik.handleChange}
                      value={formik.values.bankPhoneNumber ?? ""}
                      name="bankPhoneNumber"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData &&
                        "bankPhoneNumber" in currentHistoryData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData &&
                        "bankPhoneNumber" in currentHistoryData &&
                        "white"
                      }
                      onKeyDown={preventAlphabetsTyping}
                    />
                  </Box>

                  <Box
                    flex={{ base: "1", md: "1" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li> With bank since what year</li>
                    </Text>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="number"
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      placeholder="With bank since what year"
                      borderRadius="5px"
                      focusBorderColor="#008000"
                      borderColor={"#008000"}
                      _hover={{ borderColor: "#008000" }}
                      onChange={formik.handleChange}
                      value={formik.values.withBankSinceYear ?? ""}
                      name="withBankSinceYear"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData &&
                        "withBankSinceYear" in currentHistoryData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData &&
                        "withBankSinceYear" in currentHistoryData &&
                        "white"
                      }
                      onKeyDown={preventAlphabetsTyping}
                    />
                  </Box>
                  <Box
                    flex={{ base: "1", md: "1" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li> Account manager name</li>
                    </Text>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="text"
                      placeholder="Account manager name"
                      borderRadius="5px"
                      focusBorderColor="#5BBE3F"
                      borderColor={"#5BBE3F"}
                      _hover={{ borderColor: "#5BBE3F" }}
                      onChange={formik.handleChange}
                      value={formik.values.accountManagerName ?? ""}
                      name="accountManagerName"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData &&
                        "accountManagerName" in currentHistoryData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData &&
                        "accountManagerName" in currentHistoryData &&
                        "white"
                      }
                    />
                  </Box>
                  <Box
                    flex={{ base: "1", md: "1" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        How much of authorized line of credit is currently used?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </li>
                    </Text>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="number"
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      placeholder="How much of authorized line of credit is currently used?"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      readOnly={showBox}
                      value={formik.values.usedLineOfCredit ?? ""}
                      name="usedLineOfCredit"
                      focusBorderColor="#008000"
                      borderColor={"#008000"}
                      _hover={{ borderColor: "#008000" }}
                      bgColor={
                        showHistory &&
                        currentHistoryData &&
                        "usedLineOfCredit" in currentHistoryData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData &&
                        "usedLineOfCredit" in currentHistoryData &&
                        "white"
                      }
                    />
                    {formik.touched.usedLineOfCredit &&
                      formik.errors.usedLineOfCredit && (
                        <Box sx={errorStyle}>
                          {formik.errors.usedLineOfCredit}
                        </Box>
                      )}
                  </Box> */}
              {formik.values.bankInfo &&
                formik.values.bankInfo.length > 0 &&
                formik.values.bankInfo.map((data: any, index: any) => {
                  return (
                    <>
                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        gap={'8px'}
                        gridColumnStart={1}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Name of bank{' '}
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder="Name of bank"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          readOnly={showBox}
                          value={formik.values?.bankInfo[index].bankName ?? ''}
                          name={`bankInfo[${index}].bankName`}
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'bankName' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.bankName !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'bankName' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.bankName !== null &&
                            'white'
                          }
                        />
                        {formik.touched.bankInfo &&
                          formik.errors.bankInfo &&
                          typeof formik.touched.bankInfo[index] !== 'string' &&
                          formik.errors.bankInfo[index] && // Ensure that the value is not null or undefined
                          (formik.errors.bankInfo[index] as any)?.bankName && (
                            <Box sx={errorStyle}>
                              {(formik.errors.bankInfo[index] as any).bankName}
                            </Box>
                          )}
                      </Box>
                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        gap={'8px'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            Address
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder="Address"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={formik.values?.bankInfo[index].address ?? ''}
                          name={`bankInfo[${index}].address`}
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'address' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.address !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'address' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.address !== null &&
                            'white'
                          }
                        />
                        {formik.touched.bankInfo &&
                          formik.errors.bankInfo &&
                          typeof formik.touched.bankInfo[index] !== 'string' &&
                          formik.errors.bankInfo[index] && // Ensure that the value is not null or undefined
                          (formik.errors.bankInfo[index] as any)?.address && (
                            <Box sx={errorStyle}>
                              {(formik.errors.bankInfo[index] as any).address}
                            </Box>
                          )}
                      </Box>

                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        gap={'8px'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Authorized line of credit{' '}
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="number"
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          placeholder="$ Authorized line of credit "
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          readOnly={showBox}
                          value={
                            formik.values?.bankInfo[index]
                              .authorizedLineOfCredit ?? ''
                          }
                          name={`bankInfo[${index}].authorizedLineOfCredit`}
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'authorizedLineOfCredit' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.authorizedLineOfCredit !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'authorizedLineOfCredit' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.authorizedLineOfCredit !== null &&
                            'white'
                          }
                        />
                        {formik.touched.bankInfo &&
                          formik.errors.bankInfo &&
                          typeof formik.touched.bankInfo[index] !== 'string' &&
                          formik.errors.bankInfo[index] && // Ensure that the value is not null or undefined
                          (formik.errors.bankInfo[index] as any)
                            ?.authorizedLineOfCredit && (
                            <Box sx={errorStyle}>
                              {
                                (formik.errors.bankInfo[index] as any)
                                  .authorizedLineOfCredit
                              }
                            </Box>
                          )}
                      </Box>

                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        gap={'8px'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li> Phone number</li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="tel"
                          placeholder="Phone number"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={
                            formik.values?.bankInfo[index].bankPhoneNumber ?? ''
                          }
                          name={`bankInfo[${index}].bankPhoneNumber`}
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'bankPhoneNumber' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.bankPhoneNumber !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'bankPhoneNumber' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.bankPhoneNumber !== null &&
                            'white'
                          }
                          onKeyDown={preventAlphabetsTyping}
                        />
                      </Box>

                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        gap={'8px'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li> With bank since what year</li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="number"
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          placeholder="With bank since what year"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={
                            formik.values?.bankInfo[index].withBankSinceYear ??
                            ''
                          }
                          name={`bankInfo[${index}].withBankSinceYear`}
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'withBankSinceYear' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.withBankSinceYear !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'withBankSinceYear' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.withBankSinceYear !== null &&
                            'white'
                          }
                          onKeyDown={preventAlphabetsTyping}
                        />
                      </Box>
                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        gap={'8px'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li> Account manager name</li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder="Account manager name"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={
                            formik.values?.bankInfo[index].accountManagerName ??
                            ''
                          }
                          name={`bankInfo[${index}].accountManagerName`}
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'accountManagerName' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.accountManagerName !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'accountManagerName' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.accountManagerName !== null &&
                            'white'
                          }
                        />
                      </Box>
                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'8px'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            How much of authorized line of credit is currently
                            used?{' '}
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="number"
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          placeholder="$ How much of authorized line of credit is currently used?"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          readOnly={showBox}
                          value={
                            formik.values?.bankInfo[index].usedLineOfCredit ??
                            ''
                          }
                          name={`bankInfo[${index}].usedLineOfCredit`}
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'usedLineOfCredit' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.usedLineOfCredit !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData?.bankInfo?.[
                              index
                            ] &&
                            'usedLineOfCredit' in
                              currentHistoryData?.updatedData?.bankInfo?.[
                                index
                              ] &&
                            currentHistoryData?.updatedData?.bankInfo?.[index]
                              ?.usedLineOfCredit !== null &&
                            'white'
                          }
                        />
                        {formik.errors.bankInfo &&
                          formik.errors.bankInfo[index] && // Ensure that the value is not null or undefined
                          (formik.errors.bankInfo[index] as any)
                            ?.usedLineOfCredit && (
                            <Box
                              sx={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {
                                (formik.errors.bankInfo[index] as any)
                                  .usedLineOfCredit
                              }
                            </Box>
                          )}
                      </Box>

                      <Box
                        gap={'8px'}
                        display={'flex'}
                        // justifyContent={'space-between'}
                        flexDirection={'column'}
                        flex={{ base: '1', md: '1' }}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            How is the line of credit secured?
                            {/* <span style={{ color: "red" }}>
                                  {" "}
                                  <span style={{ color: "red" }}>*</span>
                                </span> */}
                          </li>
                        </Text>
                        <Stack
                          spacing={5}
                          direction="row"
                          flex={1}
                          alignItems={'flex-start'}
                          marginTop={{ base: 0, sm: 0, md: '19px' }}
                        >
                          <Checkbox
                            colorScheme="green"
                            isChecked={
                              formik.values?.bankInfo[index]?.creditSecured ===
                              true
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                `bankInfo[${index}].creditSecured`,
                                e.target.checked
                              );
                              formik.setFieldValue(
                                `bankInfo[${index}].securedVal`,
                                formik?.values?.bankInfo[index]?.securedVal ||
                                  'realEstate'
                              );
                            }}
                            readOnly={showBox}
                          >
                            Secured
                          </Checkbox>
                          <Checkbox
                            colorScheme="red"
                            isChecked={
                              formik.values?.bankInfo[index]?.creditSecured ===
                              false
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                `bankInfo[${index}].creditSecured`,
                                !e.target.checked
                              );
                              formik.setFieldValue(
                                `bankInfo[${index}].securedVal`,
                                null
                              );
                              formik.setFieldValue(
                                `bankInfo[${index}].otherSecuredVal`,
                                null
                              );
                            }}
                            readOnly={showBox}
                          >
                            Unsecured
                          </Checkbox>
                        </Stack>
                      </Box>
                      <Box
                        display={'flex'}
                        gap={'8px'}
                        flexWrap={'wrap'}
                        flexDir={{
                          base: 'column',
                          sm: 'column',
                          md: 'row',
                          lg: 'row',
                        }}
                        justifyContent={'space-between'}
                        alignItems={'flex-start'}
                      >
                        {formik.values?.bankInfo[index]?.creditSecured && (
                          <Box
                            flex={{ base: '1', md: '1' }}
                            display={'flex'}
                            flexDirection={'column'}
                            // justifyContent={'space-between'}
                            gap={'8px'}
                          >
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <li>
                                Select Dropdown
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </li>
                            </Text>
                            <Box
                              flex={{ base: '1', md: '1' }}
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignItems={'flex-start'}
                              gap={'8px'}
                            >
                              <Select
                                onChange={formik.handleChange}
                                value={
                                  formik.values?.bankInfo[index]?.securedVal ??
                                  ''
                                }
                                name={`bankInfo[${index}].securedVal`}
                                disabled={showBox}
                                _disabled={{ opacity: 1 }}
                                fontSize={'13px'}
                                w="100%"
                                borderRadius="5px"
                                focusBorderColor="#7f7f7f"
                                borderColor={'#7f7f7f'}
                                _hover={{ borderColor: '#7f7f7f' }}
                                bgColor={
                                  showHistory &&
                                  currentHistoryData?.updatedData &&
                                  currentHistoryData?.updatedData?.bankInfo?.[
                                    index
                                  ] &&
                                  'securedVal' in
                                    currentHistoryData?.updatedData?.bankInfo?.[
                                      index
                                    ] &&
                                  currentHistoryData?.updatedData?.bankInfo?.[
                                    index
                                  ]?.securedVal !== null &&
                                  '#114684'
                                }
                                color={
                                  showHistory &&
                                  currentHistoryData?.updatedData &&
                                  currentHistoryData?.updatedData?.bankInfo?.[
                                    index
                                  ] &&
                                  'securedVal' in
                                    currentHistoryData?.updatedData?.bankInfo?.[
                                      index
                                    ] &&
                                  currentHistoryData?.updatedData?.bankInfo?.[
                                    index
                                  ]?.securedVal !== null &&
                                  'white'
                                }
                              >
                                {Array.isArray(creditSecuredOptions) &&
                                  creditSecuredOptions.map(
                                    (item: any, index: number) => (
                                      <option
                                        style={{ color: 'black' }}
                                        key={index}
                                        value={item.value}
                                        label={item.label}
                                      />
                                    )
                                  )}
                              </Select>
                              {formik.values?.bankInfo[index]?.securedVal ===
                                'Other' && (
                                <Input
                                  fontSize={'13px'}
                                  w="100%"
                                  type="text"
                                  placeholder="Other(Please describe)"
                                  borderRadius="5px"
                                  focusBorderColor="#7f7f7f"
                                  borderColor={'#7f7f7f'}
                                  _hover={{ borderColor: '#7f7f7f' }}
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values?.bankInfo[index]
                                      .otherSecuredVal ?? ''
                                  }
                                  name={`bankInfo[${index}].otherSecuredVal`}
                                  readOnly={showBox}
                                  bgColor={
                                    showHistory &&
                                    currentHistoryData?.updatedData &&
                                    currentHistoryData?.updatedData?.bankInfo?.[
                                      index
                                    ] &&
                                    'otherSecuredVal' in
                                      currentHistoryData?.updatedData
                                        ?.bankInfo?.[index] &&
                                    currentHistoryData?.updatedData?.bankInfo?.[
                                      index
                                    ]?.otherSecuredVal !== null &&
                                    '#114684'
                                  }
                                  color={
                                    showHistory &&
                                    currentHistoryData?.updatedData &&
                                    currentHistoryData?.updatedData?.bankInfo?.[
                                      index
                                    ] &&
                                    'otherSecuredVal' in
                                      currentHistoryData?.updatedData
                                        ?.bankInfo?.[index] &&
                                    currentHistoryData?.updatedData?.bankInfo?.[
                                      index
                                    ]?.otherSecuredVal !== null &&
                                    'white'
                                  }
                                />
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                      {index === 0 ? (
                        <Box
                          fontSize="xl"
                          gridColumnStart={1}
                          display={showBox ? 'none' : 'block'}
                        >
                          <Button
                            type="button"
                            bg={'#114684'}
                            color={'white'}
                            _hover={{ bg: '#114684', color: 'white' }}
                            cursor={showBox ? 'not-allowed' : 'pointer'}
                            pointerEvents={showBox ? 'none' : 'auto'}
                            onClick={() => handleAddBankInfo(index)}
                          >
                            Add Bank Details
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          fontSize="xl"
                          gridColumnStart={1}
                          display={showBox ? 'none' : 'block'}
                        >
                          <Button
                            type="button"
                            bg={'#114684'}
                            color={'white'}
                            _hover={{ bg: '#114684', color: 'white' }}
                            cursor={showBox ? 'not-allowed' : 'pointer'}
                            pointerEvents={showBox ? 'none' : 'auto'}
                            onClick={() => handleRemoveBankInfo(index)}
                          >
                            Remove Bank Details
                          </Button>
                        </Box>
                      )}
                    </>
                  );
                })}
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'end'}
            marginTop={'25px'}
            gap={2}
          >
            {!showBox && (
              <Button
                bg={'#114684'}
                color={'white'}
                _hover={{ bg: '#114684', color: 'white' }}
                onClick={
                  userType === 'broker' && formData?.assignToApplicant
                    ? () => OnHandleReturnToBroker()
                    : () => OnHandelReturnToApplicant()
                }
                type={
                  userType === 'broker' && formData?.assignToApplicant
                    ? 'button'
                    : 'submit'
                }
                isLoading={loader?.btnLoader}
                // isDisabled={
                //   userType === 'broker' && formData?.assignToApplicant
                //     ? true
                //     : false
                // }
              >
                {isApplicantUser
                  ? 'Return to Broker'
                  : userType === 'broker' && formData?.assignToApplicant
                    ? 'Edit - Rescind Assignment from Applicant'
                    : 'Assign to applicant'}
              </Button>
            )}
            {!showBox && (
              <Box>
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  type="submit"
                >
                  Update
                </Button>
              </Box>
            )}
          </Box>
        </form>
      </Container>
      {showHistory && (
        <>
          <Box
            height="calc(100vh - 150px)"
            w={{
              base: '100%',
              lg: '360px',
            }}
            sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
            bg={'white'}
            display={'flex'}
            flexDir={'column'}
            gap={'10px'}
            borderRadius={'12px'}
            boxShadow="0 0 14px -6px rgba(0,0,0,0.3)"
            p={4}
          >
            <Box style={{ fontSize: '25px', fontWeight: 'bold' }}>History</Box>
            <Box
              display={'flex'}
              flexDir={'column'}
              gap={'10px'}
              overflow={'auto'}
            >
              {HistoryData?.data?.map((item: any, index: number) => {
                const formattedDate = formatDate(item?.createdAt);
                return (
                  <Box
                    key={item?.historyId}
                    bgColor={historyIndex == index ? '#1146844a' : '#f5f5f5'}
                    p={3}
                    borderRadius={'12px'}
                    cursor={'pointer'}
                    mr={2}
                    border={
                      historyIndex == index
                        ? '1px solid #114684'
                        : '1px solid gray'
                    }
                    onClick={() => setHistoryIndex(index)}
                  >
                    <Box fontWeight={500} mt={2} display={'flex'}>
                      <Box marginRight={1}>Date :</Box>
                      <Text> {formattedDate.date}</Text>
                      <Text
                        mx={2}
                        borderLeft="1px solid #114684"
                        height="auto"
                        boxShadow="none"
                      ></Text>
                      <Text>{formattedDate.time}</Text>
                    </Box>
                    <Box fontSize="15px" fontWeight={500}>
                      Status :{' '}
                      {item?.applicationStatus === 'Awaiting Consent'
                        ? 'Drafts'
                        : item?.applicationStatus}
                    </Box>
                    <Box fontSize="15px" fontWeight={500}>
                      Initiated By : {item?.initiatedBy}
                    </Box>
                    {item.broker === null ? (
                      <Box fontSize="15px" fontWeight={500}>
                        Updated By: {item?.applicant?.companyName}{' '}
                        {item?.applicant?.refNumber} (Applicant)
                      </Box>
                    ) : (
                      <Box fontSize="15px" fontWeight={500}>
                        Updated By: {item?.broker?.firstName}{' '}
                        {item?.broker?.lastName} (Broker)
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
});

export default AccoutingSystem;
