import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { FileInput } from '@mantine/core';
import { IconFileCv } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isFileTypeAllowed } from 'src/utils/helpers';
import { fetchApplicantUserData } from '../../../Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { ApplicantBrokerage } from '../../../Redux/Broker/Dashboard/BrokerageDashboard';
import {
  getWIPDataByApplicantId,
  getWIPTableList,
  updateRevenue,
  uploadWIPTemplate,
} from '../../../Redux/Broker/Reports/WorkInProgress/slice';
import { IApplicantData } from '../../../Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';

import { useDisclosure } from '@mantine/hooks';
import useAppToast from '../../../hooks/useAppToast';
import MantineTable from '../components/MantineTable';
import WIPBondListModal from '../components/WIPReportList/WIPBondListModal';
import WIPProjectListModal from '../components/WIPReportList/WIPProjectListModal';
import WIPReportListTable from '../components/WIPReportList/WIPReportListTable';
import { WIP_TEMPLATE_DOC_TYPES } from '../utils';

const ReportWorkingProgress = () => {
  const [selectedBrokerageId, setSelectedBrokerageId] = useState<string>('');
  const [selectedApplicantData, setSelectedApplicantData] =
    useState<IApplicantData>();
  const [progressInfo, setProgressInfo] = useState<any>(null);
  const [progressInfoLoading, setProgressInfoLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [reportBasisBy, setReportBasisBy] = useState<string>('');
  const [otherValue, setOtherValue] = useState<string>('');
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [showAllProject, setShowAllProject] = useState<boolean>(false);
  const [templateFile, setTemplateFile] = useState<File | null>(null);
  const [loader, setLoader] = useState<{
    wipTemplateUpload: boolean;
    tableContent: boolean;
    isUpdateProjectStatus: boolean;
    isBondNumberUpdating: boolean;
  }>({
    wipTemplateUpload: false,
    tableContent: false,
    isUpdateProjectStatus: false,
    isBondNumberUpdating: false,
  });
  const [WIPTableList, setWIPTableList] = useState<Record<string, any>[]>([]);

  const dispatch = useAppDispatch();
  const toast = useAppToast();
  // store state
  const progressReport = useAppSelector((state) => state.brokerReportProgress);
  const applicantProfile = useAppSelector((state) => state.applicantProfile);
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const reportId = urlParams?.get('reportId');
  const applicantId = urlParams?.get('applicantId');
  const userId = localStorage.getItem('userID');

  const [
    showProjectListModal,
    { open: openProjectListModal, close: closeProjectListModal },
  ] = useDisclosure(false);

  const [
    showBondListModal,
    { open: openBondListModal, close: closeBondListModal },
  ] = useDisclosure(false);

  useEffect(() => {
    if (!!applicantId && !!reportId) {
      setSelectedBrokerageId(applicantId);
      if (selectedApplicantData) {
        dispatch(
          getWIPDataByApplicantId({
            reportId: Number(reportId),
            showAll: showAllProject,
          })
        );
      }
    }
    if (!!selectedBrokerageId && !reportId && !applicantId) {
      handleBrokerageSelect(selectedBrokerageId);
    }
  }, [applicantId, reportId, selectedApplicantData]);

  useEffect(() => {
    if (brokerageDashboard.applicantsByBrokrageData) {
      const selectedApplicantData =
        brokerageDashboard.applicantsByBrokrageData.find(
          (elm: any) => elm.applicantId == selectedBrokerageId
        );
      setSelectedApplicantData(selectedApplicantData);
    } else if (!!applicantOptions?.length && userType === 'broker') {
      const selectedApplicantData = applicantOptions.find(
        (elm: any) => elm.applicantId == selectedBrokerageId
      );
      setSelectedApplicantData(selectedApplicantData);
    }
  }, [selectedBrokerageId, applicantOptions]);

  useEffect(() => {
    if (!!selectedBrokerageId && !!reportId) {
      dispatch(
        getWIPDataByApplicantId({
          reportId: Number(reportId),
          showAll: showAllProject,
        })
      );
    }
  }, [showAllProject]);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    if (status === 'succeed') {
      if (
        type === 'GET_APPLICANT_BY_BROKERAGE' &&
        !!applicantsByBrokrageData?.length
      ) {
        setApplicantOptions(applicantsByBrokrageData);
      }
    }
  }, [brokerageDashboard.status, selectedBrokerageId]);

  useEffect(() => {
    if (!brokerageDashboard.applicantsByBrokrageData && userType === 'broker') {
      dispatch(ApplicantBrokerage());
    } else if (
      brokerageDashboard.applicantsByBrokrageData &&
      userType === 'broker'
    ) {
      setApplicantOptions(brokerageDashboard.applicantsByBrokrageData);
    } else if (userType === 'applicant') {
      dispatch(fetchApplicantUserData({ applicantId: Number(userId)! }));
    }
    return () => setProgressInfoLoading(false);
  }, []);

  useEffect(() => {
    setSelectedApplicantData(applicantProfile?.user?.data);
  }, [applicantProfile.user]);

  useEffect(() => {
    switch (progressReport.status) {
      case 'succeed': {
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setProgressInfo(progressReport.allWIPdata.progressInfo);
          setProgressInfoLoading(false);
          if (!progressReport.allWIPdata.revenueBasis && selectedBrokerageId) {
            setShowPopup(true);
          }
        }
        // if (progressReport.type === 'UPDATE_REVNUE_API') {
        //   dispatch(
        //     getWIPDataByApplicantId({
        //       applicantId: Number(selectedBrokerageId),
        //       showAll: showAllProject,
        //     })
        //   );
        // }

        if (progressReport.type === 'REMOVE_WIP_API') {
          toast({
            title: progressReport.message,
            status: 'success',
          });
        }
        if (progressReport.type === 'UPLOAD_WIP_TEMPLATE' && templateFile) {
          setLoader((loader) => ({ ...loader, wipTemplateUpload: false }));
          toast({
            title: progressReport?.uploadTemplte?.message,
            status: 'success',
          });
          setTemplateFile(null);
          if (!!progressReport?.uploadTemplte?.projectList?.length) {
            openProjectListModal();
          } else if (!!progressReport?.uploadTemplte?.bondList?.length) {
            openBondListModal();
          } else {
            !!selectedBrokerageId &&
              dispatch(
                getWIPTableList({
                  applicantId: Number(selectedBrokerageId),
                })
              );
          }
        }
        if (progressReport.type === 'GET_WIP_LIST_BY_APPLICANT_ID') {
          console.log(
            'progressReport?.WIPTableList',
            progressReport?.WIPTableList
          );
          setLoader((loader) => ({ ...loader, tableContent: false }));
          setWIPTableList(progressReport?.WIPTableList);
        }
        if (progressReport.type === 'WIP_UPDATE_PROJECT_STATUS') {
          setLoader((loader) => ({ ...loader, isUpdateProjectStatus: false }));
          toast({
            title: 'Work in progress report updated successfully',
            status: 'success',
          });
          closeProjectListModal();
          !!selectedBrokerageId &&
            dispatch(
              getWIPTableList({
                applicantId: Number(selectedBrokerageId),
              })
            );
        }
        if (progressReport.type === 'WIP_UPDATE_BOND_NUMBERS') {
          setLoader((loader) => ({ ...loader, isBondNumberUpdating: false }));
          toast({
            title: 'Work in progress report updated successfully',
            status: 'success',
          });
          closeBondListModal();
          !!selectedBrokerageId &&
            dispatch(
              getWIPTableList({
                applicantId: Number(selectedBrokerageId),
              })
            );
        }
        return;
      }
      case 'loading': {
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setProgressInfoLoading(false);
        }
        if (progressReport.type === 'UPLOAD_WIP_TEMPLATE') {
          setLoader((loader) => ({ ...loader, wipTemplateUpload: true }));
        }
        if (progressReport.type === 'GET_WIP_LIST_BY_APPLICANT_ID') {
          setLoader((loader) => ({ ...loader, tableContent: true }));
        }
        if (progressReport.type === 'WIP_UPDATE_PROJECT_STATUS') {
          setLoader((loader) => ({ ...loader, isUpdateProjectStatus: true }));
          closeProjectListModal();
        }
        if (progressReport.type === 'WIP_UPDATE_BOND_NUMBERS') {
          setLoader((loader) => ({ ...loader, isBondNumberUpdating: true }));
          closeBondListModal();
        }
        return;
      }
      case 'failed': {
        selectedBrokerageId !== '' &&
          progressReport.error &&
          toast({
            title: progressReport.error,
            status: 'error',
            duration:
              progressReport.type === 'UPLOAD_WIP_TEMPLATE' ? null : 3000,
          });
        setProgressInfo(null);
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setProgressInfoLoading(false);
        }
        if (progressReport.type === 'UPLOAD_WIP_TEMPLATE') {
          setLoader((loader) => ({ ...loader, wipTemplateUpload: false }));
          setTemplateFile(null);
        }
        if (progressReport.type === 'GET_WIP_LIST_BY_APPLICANT_ID') {
          setLoader((loader) => ({ ...loader, tableContent: false }));
          setWIPTableList([]);
        }
        if (progressReport.type === 'WIP_UPDATE_PROJECT_STATUS') {
          setLoader((loader) => ({ ...loader, isUpdateProjectStatus: false }));
        }
        if (progressReport.type === 'WIP_UPDATE_BOND_NUMBERS') {
          setLoader((loader) => ({ ...loader, isBondNumberUpdating: false }));
        }
        return;
      }
    }
  }, [progressReport.status]);

  const userType = localStorage.getItem('userType');

  const handleCheckboxChange = (value: string) => {
    setReportBasisBy(value.toString());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherValue(event.target.value);
  };

  const handleBrokerageSelect = async (
    event: React.ChangeEvent<HTMLSelectElement> | string
  ) => {
    const selectedId = typeof event === 'string' ? event : event.target.value;
    setSelectedBrokerageId(selectedId);

    if (selectedId) {
      dispatch(
        getWIPTableList({
          applicantId: Number(selectedId),
        })
      );
    }
  };

  const handlePopupSubmit = async () => {
    if (progressReport.allWIPdata.id && reportBasisBy) {
      setShowPopup(false);
      setReportBasisBy(reportBasisBy.toString());
      dispatch(
        updateRevenue({
          documentId: progressReport.allWIPdata.id,
          revenueBasis:
            reportBasisBy.toString() === 'other'
              ? otherValue
              : reportBasisBy.toString(),
        })
      );
      setReportBasisBy('');
      setOtherValue('');
    }
  };

  useEffect(() => {
    if (userType && userType === 'applicant') {
      const userID = localStorage.getItem('userID') || '';
      handleBrokerageSelect(userID);
    }
  }, []);

  const handleWIPUploadTempate = (file: File) => {
    const isValid = isFileTypeAllowed(file?.name, WIP_TEMPLATE_DOC_TYPES);
    if (!isValid) {
      toast({
        title: 'Invalid file type!',
        status: 'error',
      });
      return;
    }
    setTemplateFile(file);
    const formData = new FormData();
    formData.append(`file`, file);
    formData.append(`applicantId`, selectedBrokerageId);
    dispatch(uploadWIPTemplate(formData));
  };

  console.log('showTable', selectedBrokerageId, showPopup);

  const showTable =
    !!selectedBrokerageId && !showPopup && !!reportId && selectedApplicantData;

  return (
    <>
      <Box>
        <Flex alignItems={'left'} flexDirection={'column'} columnGap={5} py={5}>
          <Flex
            alignItems={'center'}
            mb={5}
            gap={1}
            justifyContent={'space-between'}
          >
            {userType === 'broker' && (
              <Box
                maxW="max-content"
                display={'flex'}
                alignItems={'center'}
                gap={2}
              >
                <Select
                  w="100%"
                  defaultValue={selectedBrokerageId}
                  onChange={handleBrokerageSelect}
                  value={selectedBrokerageId}
                  name="userData.brokerageId"
                  borderRadius="5px"
                  bg={'#114684'}
                  color={'white'}
                  textAlign={'center'}
                  disabled={!!reportId}
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Applicant"
                  />
                  {applicantOptions?.map((user?: any, index?: number) => (
                    <option
                      style={{ color: 'black' }}
                      key={index}
                      value={user?.applicantId ?? ''}
                      label={user?.companyName ?? ''}
                    />
                  ))}
                </Select>
                {!selectedBrokerageId && (
                  <Text fontSize={'medium'} whiteSpace={'nowrap'}>
                    Please Select Applicant
                  </Text>
                )}
                {!!selectedBrokerageId && !!reportId && (
                  <Box ml={2}>
                    <Checkbox
                      isChecked={showAllProject}
                      onChange={(e) => setShowAllProject(e.target.checked)}
                      w={'max-content'}
                      isDisabled={
                        progressReport.type ===
                          'GET_WIP_DATA_BY_APPLICATIONID_API' &&
                        progressReport.status === 'loading'
                      }
                    >
                      Show All
                    </Checkbox>
                  </Box>
                )}
              </Box>
            )}

            <Flex
              direction={'column'}
              gap={2}
              alignItems={'flex-start'}
              maxW={'max-content'}
            >
              {progressReport.allWIPdata.revenueBasis && !!reportId && (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={2}
                  textAlign={'end'}
                  ml={'auto'}
                  w={'100%'}
                >
                  <Text fontWeight={'bold'}>
                    On what basis is revenue recognized?
                  </Text>
                  <Text>{progressReport.allWIPdata.revenueBasis}</Text>
                </Box>
              )}
              {!showTable && !!selectedBrokerageId && (
                <Box display={'flex'} flexWrap={'wrap'} gap={2} w={'100%'}>
                  <FileInput
                    icon={
                      <IconFileCv
                        style={{ width: 18, height: 18 }}
                        stroke={1.5}
                        color="white"
                      />
                    }
                    styles={{
                      input: {
                        maxWidth: '350px',
                        border: '1px solid #114684',
                        minWidth: '155px',
                        minHeight: '35px',
                        background: '#114684',
                        color: `white !important`,
                      },
                      placeholder: {
                        color: `white !important`,
                      },
                    }}
                    // @ts-ignore
                    placeholder="Upload Template"
                    // label="Upload Template"
                    multiple={false}
                    onChange={(payload: File) =>
                      handleWIPUploadTempate(payload)
                    }
                    value={templateFile}
                    // description={`The file format should be - xlsx, xls`}
                    variant="filled"
                    accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                  <Button
                    as="a"
                    href="/document/WIP_Template.xlsx"
                    download="Work in Progress (WIP) Template.xlsx"
                    bg="#114684"
                    color="white"
                    _hover={{ bg: '#114684', color: 'white' }}
                    w="fit-content"
                    fontWeight={'normal'}
                  >
                    Download Template
                  </Button>
                </Box>
              )}
            </Flex>
          </Flex>

          {progressInfoLoading && (
            <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
              <Spinner size="xl" />
            </Flex>
          )}

          {showTable ? (
            <MantineTable
              progressInfo={progressInfo}
              selectedBrokerageId={selectedBrokerageId}
              selectedId={2}
              selectedApplicantData={selectedApplicantData}
              showAllProject={showAllProject}
              reportId={Number(reportId)}
            />
          ) : (
            !!selectedBrokerageId && (
              <WIPReportListTable
                tableList={WIPTableList}
                tableContentLoader={loader.tableContent}
              />
            )
          )}
        </Flex>
        {/* {showPopup && (

      )} */}
      </Box>
      <Modal
        isOpen={showPopup}
        onClose={() => {
          setShowPopup(false);
          setSelectedBrokerageId('');
          setReportBasisBy('');
          setOtherValue('');
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>On what basis is revenue recognized?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="center" mb={3} flexDirection="column">
              <RadioGroup
                display="flex"
                flexDirection="column"
                justifyContent="center"
                onChange={handleCheckboxChange}
              >
                <Radio value="Completed Contract">Completed Contract</Radio>
                <Radio value="Percentage of Completion">
                  Percentage of Completion
                </Radio>
                <Radio value="Cash">Cash</Radio>
                <Radio value="other">Other (please specify)</Radio>
              </RadioGroup>
              {reportBasisBy === 'other' && (
                <Input
                  mt="2"
                  placeholder="Specify other basis"
                  value={otherValue}
                  onChange={handleInputChange}
                />
              )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={
                (reportBasisBy.toString() === 'other' &&
                  otherValue.trim() === '') ||
                !reportBasisBy.toString()
              }
              onClick={handlePopupSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <WIPProjectListModal
        isOpen={showProjectListModal}
        onClose={closeProjectListModal}
        isUpdateStatusLoader={loader.isUpdateProjectStatus}
        uploadTemplte={progressReport?.uploadTemplte!}
      />
      <WIPBondListModal
        isOpen={showBondListModal}
        onClose={closeBondListModal}
        isUpdateStatusLoader={loader.isBondNumberUpdating}
        uploadTemplte={progressReport?.uploadTemplte!}
      />
    </>
  );
};

export default ReportWorkingProgress;
