import { Flex, HStack } from '@chakra-ui/react';
import { Box, Divider, MantineProvider, Progress, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  MRT_GlobalFilterTextInput,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  AnalyticalReportDetails,
  FinancialData,
  ITableData,
} from 'src/Redux/Broker/AnalyticalReports/state';
import { EBITDAProvider } from 'src/context/EBITDAContext';
import { useAnalyticalReportContext } from '../..';
import {
  monthLabels,
  quarterlyEachYearLabels,
  semiAnuallyEachYearLabels,
} from '../../data';
import EditAnalyticalReportModal from './EditAnalyticalReportModal';

//ProfitabilityRatiosTable props interface
interface ProfitabilityRatiosTableProps {
  TData: AnalyticalReportDetails;
}

// Define the structure of our calculated ratios
interface ProfitabilityRatios {
  ratioName: string;
  average: number;
  [label: number]: number;
}

// // Sample financial data
// const financialData: FinancialData[] = [
//   {
//     label: 2020,
//     netSales: 100,
//     cogs: 37,
//     netIncome: 33,
//     interest: 5,
//     taxes: 10,
//     depreciation: 30,
//     amortization: 13,
//     totalAssets: 550,
//     shareholdersEquity: 275,
//   },
//   {
//     label: 2021,
//     netSales: 150,
//     cogs: 130,
//     netIncome: 60,
//     interest: 8,
//     taxes: 15,
//     depreciation: 10,
//     amortization: 5,
//     totalAssets: 857,
//     shareholdersEquity: 6000,
//   },
//   {
//     label: 2022,
//     netSales: 200,
//     cogs: 111,
//     netIncome: 71,
//     interest: 3,
//     taxes: 12,
//     depreciation: 15,
//     amortization: 5,
//     totalAssets: 473,
//     shareholdersEquity: 3550,
//   },
//   {
//     label: 2023,
//     netSales: 180,
//     cogs: 138,
//     netIncome: 69,
//     interest: 2,
//     taxes: 8,
//     depreciation: 12,
//     amortization: 6,
//     totalAssets: 627,
//     shareholdersEquity: 431,
//   },
//   {
//     label: 2024,
//     netSales: 220,
//     cogs: 152,
//     netIncome: 23,
//     interest: 4,
//     taxes: 7,
//     depreciation: 18,
//     amortization: 8,
//     totalAssets: 135,
//     shareholdersEquity: 328,
//   },
// ];

// Function to calculate profitability ratios
// const calculateRatios = (data: FinancialData) => {
//   const grossProfit = data.netSales - data.cogs;
//   const ebitda =
//     data.netIncome +
//     data.interest +
//     data.taxes +
//     data.depreciation +
//     data.amortization;
//   console.log('grossProfit', grossProfit, data);

//   return {
//     grossProfit,
//     grossMarginPercentage: (grossProfit / data.netSales) * 100,
//     ebitda,
//     ebitdaMarginPercentage: (ebitda / data.netSales) * 100,
//     netIncome: data.netIncome,
//     profitMargin: (data.netIncome / data.netSales) * 100,
//     returnOnAsset: (data.netIncome / data.totalAssets) * 100,
//     returnOnEquity: (data.netIncome / data.shareholdersEquity) * 100,
//   };
// };

// // Calculate ratios for all years
// const yearlyRatios = financialData.map(calculateRatios);

// // Create the restructured data
// const restructuredData: ProfitabilityRatios[] = [
//   {
//     ratioName: 'Gross Profit $',
//     average: 0,
//     2020: 0,
//     2021: 0,
//     2022: 0,
//     2023: 0,
//     2024: 0,
//   },
//   {
//     ratioName: 'Gross Margin %',
//     average: 0,
//     2020: 0,
//     2021: 0,
//     2022: 0,
//     2023: 0,
//     2024: 0,
//   },
//   {
//     ratioName: 'EBITDA $',
//     average: 0,
//     2020: 0,
//     2021: 0,
//     2022: 0,
//     2023: 0,
//     2024: 0,
//   },
//   {
//     ratioName: 'EBITDA Margin %',
//     average: 0,
//     2020: 0,
//     2021: 0,
//     2022: 0,
//     2023: 0,
//     2024: 0,
//   },
//   {
//     ratioName: 'Net Income $',
//     average: 0,
//     2020: 0,
//     2021: 0,
//     2022: 0,
//     2023: 0,
//     2024: 0,
//   },
//   {
//     ratioName: 'Profit Margin',
//     average: 0,
//     2020: 0,
//     2021: 0,
//     2022: 0,
//     2023: 0,
//     2024: 0,
//   },
//   {
//     ratioName: 'Return on Asset',
//     average: 0,
//     2020: 0,
//     2021: 0,
//     2022: 0,
//     2023: 0,
//     2024: 0,
//   },
//   {
//     ratioName: 'Return on Equity',
//     average: 0,
//     2020: 0,
//     2021: 0,
//     2022: 0,
//     2023: 0,
//     2024: 0,
//   },
// ];

// Populate the restructured data
// yearlyRatios.forEach((ratio, index) => {
//   const year = financialData[index].label;
//   Object.keys(ratio).forEach((key, ratioIndex) => {
//     restructuredData[ratioIndex][year as any] =
//       ratio[key as keyof typeof ratio];
//   });
// });

// Calculate averages
// restructuredData.forEach((row) => {
//   const sum = Object.keys(row).reduce((acc, key: string) => {
//     return key !== 'ratioName' && key !== 'average'
//       ? acc + row[key as any]
//       : acc;
//   }, 0);
//   row.average = sum / 5; // 5 years of data
// });

// const getTooltipContent = (
//   ratioName: string,
//   label: number | string,
//   TData: FinancialData[]
// ) => {
//   console.log('sdsdsdsssd', ratioName, label, TData);

//   const data = TData.find((d) => d.label === label);
//   if (!data) return '';

//   switch (ratioName) {
//     case 'Gross Profit $':
//       return `Net Sales ($${data.netSales}) - CoGS ($${data.cogs})`;
//     case 'Gross Margin %':
//       return `(Net Sales ($${data.netSales}) - CoGS ($${data.cogs})) / Net Sales ($${data.netSales}) * 100`;
//     case 'EBITDA $':
//       return `Net Income ($${data.netIncome}) + Interest ($${data.interest}) + Taxes ($${data.taxes}) + Depreciation ($${data.depreciation}) + Amortization ($${data.amortization})`;
//     case 'EBITDA Margin %':
//       const ebitda =
//         data.netIncome +
//         data.interest +
//         data.taxes +
//         data.depreciation +
//         data.amortization;
//       return `EBITDA ($${ebitda.toFixed(2)}) / Total Revenue ($${data.netSales}) * 100`;
//     case 'Net Income $':
//       return `Net Income: $${data.netIncome}`;
//     case 'Profit Margin':
//       return `(Net Income ($${data.netIncome}) / Revenue ($${data.netSales})) * 100`;
//     case 'Return on Asset':
//       return `(Net Income ($${data.netIncome}) / Total Assets ($${data.totalAssets})) * 100`;
//     case 'Return on Equity':
//       return `(Net Income ($${data.netIncome}) / Shareholder's Equity ($${data.shareholdersEquity})) * 100`;
//     default:
//       return '';
//   }
// };

const getTooltipContent = (
  ratioName: string,
  label: number | string,
  TData: FinancialData[]
) => {
  console.log('sdsdsdsssd', ratioName, label, TData);

  const data = TData.find((d) => d.label === label);
  if (!data) return 'Data not available';

  const netSales = data.netSales ?? 0;
  const cogs = data.cogs ?? 0;
  const netIncome = data.netIncome ?? 0;
  const interest = data.interest ?? 0;
  const taxes = data.taxes ?? 0;
  const depreciation = data.depreciation ?? 0;
  const amortization = data.amortization ?? 0;
  const totalAssets = data.totalAssets ?? 1; // Avoid division by zero
  const shareholdersEquity = data.shareholdersEquity ?? 1; // Avoid division by zero

  const grossProfit = netSales - cogs;
  const ebitda = netIncome + interest + taxes + depreciation + amortization;

  switch (ratioName) {
    case 'Gross Profit $':
      return `Net Sales ($${netSales}) - CoGS ($${cogs}) = $${grossProfit}`;

    case 'Gross Margin %':
      return `Gross Profit (${grossProfit} / Net Sales $${netSales}) * 100 = ${netSales ? (grossProfit / netSales) * 100 : 0}%`;

    case 'EBITDA $':
      return `Net Income ($${netIncome}) + Interest ($${interest}) + Taxes ($${taxes}) + Depreciation ($${depreciation}) + Amortization ($${amortization}) = $${ebitda}`;

    case 'EBITDA Margin %':
      return `EBITDA ($${ebitda}) / Net Sales ($${netSales}) * 100 = ${netSales ? (ebitda / netSales) * 100 : 0}%`;

    case 'Net Income $':
      return `Net Income: $${netIncome}`;

    case 'Profit Margin':
      return `(Net Income ($${netIncome}) / Net Sales ($${netSales})) * 100 = ${netSales ? (netIncome / netSales) * 100 : 0}%`;

    case 'Return on Asset':
      return `(Net Income ($${netIncome}) / Total Assets ($${totalAssets})) * 100 = ${totalAssets ? (netIncome / totalAssets) * 100 : 0}%`;

    case 'Return on Equity':
      return `(Net Income ($${netIncome}) / Shareholder's Equity ($${shareholdersEquity})) * 100 = ${shareholdersEquity ? (netIncome / shareholdersEquity) * 100 : 0}%`;

    default:
      return 'No data available';
  }
};

const calculateRatios = (data: Record<string, any>) => {
  if (!data) return null; // Ensure data exists

  // const netSales = data.G || 1; // Prevent division by zero
  // const totalAssets = data.totalAssets || 1;
  // const shareholdersEquity = data.shareholdersEquity || 1;

  // const grossProfit = (data.netSales ?? 0) - (data.cogs ?? 0);
  // const ebitda =
  //   (data.netIncome ?? 0) +
  //   (data.interest ?? 0) +
  //   (data.taxes ?? 0) +
  //   (data.depreciation ?? 0) +
  //   (data.amortization ?? 0);

  // console.log('grossProfit', grossProfit, data);

  return {
    grossProfit: data.grossProfit,
    grossMarginPercentage: data.grossMargin,
    ebitda: data.ebitda,
    ebitdaMarginPercentage: data.ebitdaMargin,
    netIncome: data.netIncome,
    profitMargin: data.profitMargin,
    returnOnAsset: data.returnOnAssets,
    returnOnEquity: data.returnOnEquity,
    // yoyGrowth: data.yoyGrowth,
  };
};

const ratioNames = [
  'Gross Profit $',
  'Gross Margin %',
  'EBITDA $',
  'EBITDA Margin %',
  'Net Income $',
  'Profit Margin',
  'Return on Asset',
  'Return on Equity',
];

const ProfitabilityRatiosTable: React.FC<ProfitabilityRatiosTableProps> = ({
  TData,
}) => {
  const { contentLoader: loading, analyticalReportPeriod } =
    useAnalyticalReportContext();
  const [
    isEditAnalyticalReport,
    { open: openEditAnalyticalReport, close: closeEditAnalyticalReport },
  ] = useDisclosure(false);

  const [restructuredData, setRestructuredData] = useState<any>([]);
  console.log('TData', TData);

  useEffect(() => {
    const tabledata: any[] = TData?.tableData;
    const labels = tabledata?.map(({ label }) => label); // Extracting labels dynamically

    // Restructure the data dynamically
    const restructuredData =
      tabledata && tabledata?.length > 0
        ? ratioNames.map((ratioName) => {
            return {
              ratioName,
              ...Object.fromEntries(labels?.map((label) => [label, 0]) ?? []), // Dynamically adding labels as keys
            };
          })
        : [];

    // Calculate ratios for all years
    const yearlyRatios =
      tabledata && tabledata?.length > 0 ? tabledata?.map(calculateRatios) : [];

    console.log('yearlyRatios', yearlyRatios);

    // // Populate the restructured data
    yearlyRatios?.forEach((ratio: any, index: any) => {
      const year =
        tabledata && tabledata?.length > 0 && tabledata?.[index]?.label;
      Object.keys(ratio).forEach((key: any, ratioIndex: any) => {
        (restructuredData as any)[ratioIndex][year as any] =
          ratio[key as keyof typeof ratio];
      });
    });
    setRestructuredData(restructuredData);
  }, [TData]);

  const renderCell =
    (label: string) =>
    ({ cell, row }: { cell: any; row: any }) => {
      const { ratioName } = row?.original;
      const value = Number(row.original[label] ?? 0);

      let formattedValue = value.toFixed(2);
      if (ratioName.includes('$')) {
        formattedValue = value.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else if (
        ratioName.includes('%') ||
        ratioName.toLowerCase().includes('margin')
      ) {
        formattedValue = `${value.toFixed(2)} %`;
      }

      return (
        <Box style={{ cursor: 'help' }}>
          <Text>{formattedValue}</Text>
        </Box>
      );
    };

  const getFormattedColumns = (
    tableData: Record<string, any>[],
    period: string
  ) => {
    const currentYear = new Date().getFullYear();
    let formattedColumns: MRT_ColumnDef<ITableData>[] = [
      {
        accessorKey: 'ratioName',
        header: 'Ratio',
        size: 180,
      },
    ];

    const tableDataWithoutAverageCol = tableData.filter(
      (elm) => !['average'].includes(elm.label)
    );

    if (period === 'Monthly' && tableDataWithoutAverageCol?.length > 0) {
      formattedColumns.push({
        accessorKey: 'monthly',
        header: `${currentYear}`,
        columns: tableDataWithoutAverageCol.map((elm) => ({
          accessorKey: elm.label,
          header: monthLabels[elm.label] || elm.label,
          Cell: renderCell(elm.label),
        })),
      });
    } else if (
      period === 'Quarterly' &&
      tableDataWithoutAverageCol?.length > 0
    ) {
      const currentYearData = tableDataWithoutAverageCol.filter((elm) =>
        ['q1', 'q2', 'q3', 'q4'].includes(elm.label)
      );
      const previousYearData = tableDataWithoutAverageCol.filter((elm) =>
        ['q5', 'q6', 'q7', 'q8'].includes(elm.label)
      );
      if (currentYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear}`,
          columns: currentYearData?.map((elm, index) => ({
            accessorKey: elm.label,
            header: quarterlyEachYearLabels[elm.label],
            Cell: renderCell(elm.label),
          })),
        });
      }
      if (previousYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear - 1}`,
          columns: previousYearData?.map((elm, index) => ({
            accessorKey: elm.label,
            header: quarterlyEachYearLabels[elm.label],
            Cell: renderCell(elm.label),
          })),
        });
      }
    } else if (
      period === 'Semi Annually' &&
      tableDataWithoutAverageCol?.length > 0
    ) {
      const currentYearData = tableDataWithoutAverageCol.filter((elm) =>
        ['s1', 's2'].includes(elm.label)
      );
      const previousYearData = tableDataWithoutAverageCol.filter((elm) =>
        ['s3', 's4'].includes(elm.label)
      );
      const twoPreviousYearData = tableDataWithoutAverageCol.filter((elm) =>
        ['s5', 's6'].includes(elm.label)
      );
      const threePreviousYearData = tableDataWithoutAverageCol.filter((elm) =>
        ['s7', 's8'].includes(elm.label)
      );

      if (currentYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear}`,
          columns: currentYearData?.map((elm, index) => ({
            accessorKey: elm.label,
            header: semiAnuallyEachYearLabels[elm.label],
            Cell: renderCell(elm.label),
          })),
        });
      }
      if (previousYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear - 1}`,
          columns: previousYearData?.map((elm, index) => ({
            accessorKey: elm.label,
            header: semiAnuallyEachYearLabels[elm.label],
            Cell: renderCell(elm.label),
          })),
        });
      }
      if (twoPreviousYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear - 2}`,
          columns: twoPreviousYearData?.map((elm, index) => ({
            accessorKey: elm.label,
            header: semiAnuallyEachYearLabels[elm.label],
            Cell: renderCell(elm.label),
          })),
        });
      }
      if (threePreviousYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear - 3}`,
          columns: threePreviousYearData?.map((elm, index) => ({
            accessorKey: elm.label,
            header: semiAnuallyEachYearLabels[elm.label],
            Cell: renderCell(elm.label),
          })),
        });
      }
    } else {
      const yearCols = tableDataWithoutAverageCol.map((elm, index) => {
        const yearNumber = parseInt(elm.label.replace(/\D/g, ''), 10);
        return {
          accessorKey: elm.label,
          header: `${currentYear - (yearNumber - 1)}`,
          Cell: renderCell(elm.label),
        };
      });
      formattedColumns.push(...yearCols);
      // formattedColumns.push(
      //   ...tableDataWithoutAverageCol.map((elm, index) => ({
      //     accessorKey: elm.label,
      //     header: `${currentYear - index}`,
      //     Cell: renderCell(elm.label),
      //   }))
      // );
    }

    const averageColumn = tableData
      ?.filter((el) => ['average'].includes(el.label))
      .map((elm) => ({
        accessorKey: elm.label,
        header: elm.label,
        Cell: renderCell(elm.label),
      }));

    formattedColumns.push(...averageColumn);

    return formattedColumns;
  };

  const columns = useMemo(
    () => getFormattedColumns(TData?.tableData, analyticalReportPeriod),
    [TData, analyticalReportPeriod]
  );

  // const columns = useMemo<MRT_ColumnDef<ITableData>[]>(
  //   () => [
  //     {
  //       accessorKey: 'ratioName',
  //       header: 'Ratio',
  //       size: 180,
  //     },
  //     ...TData?.tableData?.map((elm) => ({
  //       // accessorFn: (row) => row.grossProfitMargin, // elm?.label.toString(),
  //       accessorKey: elm?.label.toString(), // elm?.label.toString(),
  //       header: elm?.label.toString(),
  //       Cell: ({ cell, row }: { cell: any; row: any }) => {
  //         console.log('row data', { row, cell, elm });
  //         const { ratioName } = row?.original;
  //         if (ratioName.includes('$')) {
  //           return (
  //             // <Tooltip
  //             //   label={getTooltipContent(
  //             //     row.original.ratioName,
  //             //     elm?.label,
  //             //     TData?.tableData
  //             //   )}
  //             //   multiline
  //             //   width={300}
  //             //   position="top"
  //             //   withArrow
  //             // >
  //             //   </Tooltip>
  //             <Box style={{ cursor: 'help' }}>
  //               <Text>
  //                 {Number(
  //                   row.original[elm?.label.toString()] ?? 0
  //                 ).toLocaleString('en-US', {
  //                   minimumFractionDigits: 2,
  //                   maximumFractionDigits: 2,
  //                 })}
  //               </Text>
  //             </Box>
  //           );
  //         } else if (
  //           ratioName.includes('%') ||
  //           ratioName.toLowerCase().includes('margin')
  //         ) {
  //           return (
  //             <Box style={{ cursor: 'help' }}>
  //               <Text>
  //                 {Number(row.original[elm?.label.toString()] ?? 0).toFixed(2)}{' '}
  //                 %
  //               </Text>
  //             </Box>
  //           );
  //         } else {
  //           return (
  //             <Box style={{ cursor: 'help' }}>
  //               <Text>
  //                 {Number(row.original[elm?.label.toString()] ?? 0).toFixed(2)}{' '}
  //               </Text>
  //             </Box>
  //           );
  //         }
  //       },
  //     })),
  //   ],
  //   [restructuredData, TData]
  // );
  console.log('restructuredData', restructuredData);

  const table = useMantineReactTable({
    columns,
    data: restructuredData, // restructuredData,
    enableTopToolbar: true,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    positionPagination: 'none',
    getRowId: (row, index) => '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: loading ? 'hidden' : 'auto',
      },
    },
    mantineTableProps: {
      striped: true,
      highlightOnHover: true,
      sx: {
        tableLayout: 'fixed',
      },
    },
    renderTopToolbar: ({ table }) => {
      console.log('table', table);

      return (
        <Flex direction={'column'} justifyContent={'space-between'} px={5}>
          <HStack justifyContent={'flex-end'} alignItems={'center'}>
            {/* <Button
              bg={'#114684'}
              color={'white'}
              minW={'150px'}
              p={2}
              _hover={{ bg: '#114684', color: 'white' }}
              type="button"
              onClick={() => {
                openEditAnalyticalReport();
              }}
            >
              Edit
            </Button> */}
            <Flex
              py={1}
              alignItems={'center'}
              flexWrap={'wrap'}
              justifyContent={'flex-end'}
            >
              <Flex alignItems={'center'} gap={4} p={3}>
                <MRT_GlobalFilterTextInput table={table} />
                <MRT_ToolbarInternalButtons table={table} />
              </Flex>
              <Divider
                size="md"
                orientation="vertical"
                h={40}
                style={{ alignSelf: 'center' }}
              />
              <Box className="pagination-wrapper">
                <MRT_TablePagination position="top" table={table} />
              </Box>
            </Flex>
          </HStack>
          {loading && <Progress value={100} animate={true} />}
        </Flex>
      );
    },
    state: {
      showProgressBars: loading,
      isLoading: loading,
    },
  });

  return (
    <>
      <MantineProvider>
        <MantineReactTable table={table} />
      </MantineProvider>
      <EBITDAProvider>
        <EditAnalyticalReportModal
          isOpen={isEditAnalyticalReport}
          onClose={() => {
            closeEditAnalyticalReport();
          }}
          TData={TData}
        />
      </EBITDAProvider>
    </>
  );
};

export default ProfitabilityRatiosTable;
