import {
  Badge,
  Box,
  // ActionIcon,
  Button,
  Checkbox,
  Flex,
  Grid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ActionIcon, Progress, Title, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider, modals } from '@mantine/modals';
import {
  IconDownload,
  IconEdit,
  IconRefresh,
  IconTrash,
} from '@tabler/icons-react';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MRT_EditActionButtons,
  MRT_GlobalFilterTextInput,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
  // createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import {
  convertNaNtoZero,
  fixedNumber,
  getDateFormat,
  replaceNullUndefined,
} from 'src/utils/helpers';
import {
  addWIPData,
  getWIPDataByApplicantId,
  refreshProgressReport,
  removeWIPData,
  updateWIPData,
} from '../../../Redux/Broker/Reports/WorkInProgress/slice';
import { IApplicantData } from '../../../Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import RefreshWIPReportInfoModal from './WIPReportList/RefreshWIPReportInfoModal';
import { WIPExceleColumns, type ProgressInfoAttributes } from './data';
import './index.css';

interface dataProps {
  progressInfo: ProgressInfoAttributes[];
  selectedBrokerageId: string;
  selectedId: number;
  selectedApplicantData: IApplicantData | undefined;
  showAllProject: boolean;
  reportId: number;
}

function convertData<T extends Record<string, unknown>>(data: Partial<T>): T {
  const convertedData = {} as T;
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (typeof value === 'string') {
        const newVal = parseFloat(value) as T[Extract<keyof T, string>];
        // @ts-ignore
        convertedData[key] = isNaN(newVal)
          ? value === ''
            ? 0
            : value
          : newVal;
      } else {
        convertedData[key] = value as T[Extract<keyof T, string>];
      }
    }
  }
  return convertedData;
}

const calculatedData = (values: ProgressInfoAttributes) => {
  const convertedData = convertData<ProgressInfoAttributes>(values);
  const newValues: ProgressInfoAttributes = {
    ...convertedData,
  };

  // Total Estimated Cost $   => totalEstimatedCost
  // Total Contract Value $   => totalContractValue
  // Total Billed $   => totalBilled
  // To Be Billed $   => toBeBilled
  // Cost to Date $   => costToDate
  // Remaining Budget $   => remainingBudget
  // % Earned   => percentEarned
  // Earned Value $   => earnedValue
  // Billings in Excess of Earned Value $   => billingsInExcessOfEarnedValue
  // Cost in Excess of Billings $  => costInExcessOfBillings
  // Estimated GP $  => forecastedGP
  // Estimated GM $  => forecastedGPMargin
  // Current GP $  => currentGP
  // Current GM $  => currentGPMargin
  // +/- Billings $  => underOverBillings

  // use input values
  newValues.totalContractValue = parseFloat(
    Number(values.totalContractValue ?? 0).toFixed(2)
  );
  newValues.totalBilled = parseFloat(
    Number(values.totalBilled ?? 0).toFixed(2)
  );
  newValues.totalEstimatedCost = parseFloat(
    Number(values.totalEstimatedCost ?? 0).toFixed(2)
  );
  newValues.costToDate = parseFloat(Number(values.costToDate ?? 0).toFixed(2));

  newValues.revisedContractValue = parseFloat(
    (Number(newValues.totalContractValue) ?? 0).toFixed(2)
  );
  newValues.toBeBilled = parseFloat(
    (
      (Number(newValues.totalContractValue) ?? 0) -
      (Number(newValues.totalBilled) ?? 0)
    ).toFixed(2)
  );
  newValues.revisedBudget = parseFloat(
    (Number(newValues.totalEstimatedCost) ?? 0).toFixed(2)
  );
  newValues.remainingBudget = parseFloat(
    (
      (Number(newValues.totalEstimatedCost) ?? 0) -
      (Number(newValues.costToDate) ?? 0)
    ).toFixed(2)
  );
  newValues.percentEarned = Math.floor(
    parseFloat(
      (
        (Number(newValues.costToDate) / Number(newValues.totalEstimatedCost)) *
        100
      ).toFixed(2)
    )
  );
  newValues.earnedValue = parseFloat(
    (
      (Number(newValues.percentEarned / 100) ?? 0) *
      newValues.totalContractValue
    ).toFixed(2)
  );
  newValues.billingsInExcessOfEarnedValue = parseFloat(
    (
      (Number(newValues.totalBilled) ?? 0) -
      (Number(newValues.earnedValue) ?? 0)
    ).toFixed(2)
  );
  newValues.costInExcessOfBillings = parseFloat(
    (
      (Number(newValues.totalBilled) ?? 0) - (Number(newValues.costToDate) ?? 0)
    ).toFixed(2)
  );
  newValues.forecastedGP = parseFloat(
    (
      (Number(newValues.totalContractValue) ?? 0) -
      (Number(newValues.totalEstimatedCost) ?? 0)
    ).toFixed(2)
  );
  newValues.forecastedGPMargin = parseFloat(
    (
      (Number(newValues.forecastedGP) / Number(newValues.totalContractValue)) *
      100
    ).toFixed(2)
  );
  newValues.currentGP = parseFloat(
    ((Number(newValues.earnedValue) ?? 0) - newValues.costToDate).toFixed(2)
  );
  newValues.currentGPMargin = parseFloat(
    (
      (Number(newValues.currentGP) / Number(newValues.totalBilled)) *
      100
    ).toFixed(2)
  );
  newValues.underOverBillings = parseFloat(
    (
      (Number(newValues.revisedContractValue) ?? 0) *
        (Number(newValues.percentEarned) ?? 0) -
      (Number(newValues.totalBilled) ?? 0)
    ).toFixed(2)
  );

  return convertNaNtoZero(newValues);
};

export const formatNumber = (number: number): string => {
  // REVERT MAY BE
  if (number !== 0 && !number) return '';
  const value = number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return value ? `$ ${value}` : '';
};

export const percentageConvert = (value: any) =>
  `${(typeof value === 'number' && value !== 0 ? Math.round(value) : value) ?? 0} %`;

const Example = ({
  selectedBrokerageId,
  selectedApplicantData,
  showAllProject,
  reportId,
}: dataProps) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const dispatch = useAppDispatch();
  const progressReport = useAppSelector((state) => state.brokerReportProgress);
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );

  const [tableData, setTableData] = useState<any>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({
    isCreatingUser: false,
    isUpdatingUser: false,
    isDeletingUser: false,
  });
  const [projectBonded, setProjectBonded] = useState<boolean | undefined>(
    undefined
  );
  const [
    isRefreshInfoModal,
    { open: openRefreshInfoModal, close: closeRefreshInfoModal },
  ] = useDisclosure(false);

  useEffect(() => {
    switch (progressReport.status) {
      case 'succeed': {
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setIsLoadingData(false);
          setTableData(progressReport.allWIPdata.progressInfo);
        }
        if (progressReport.type === 'UPDATE_WIP_API') {
          setIsLoading((state) => ({ ...state, isUpdatingUser: false }));
          dispatch(
            getWIPDataByApplicantId({
              reportId: Number(reportId),
              showAll: showAllProject,
            })
          );
        }
        if (progressReport.type === 'REMOVE_WIP_API') {
          setIsLoading((state) => ({ ...state, isDeletingUser: false }));
          dispatch(
            getWIPDataByApplicantId({
              reportId: Number(reportId),
              showAll: showAllProject,
            })
          );
        }
        if (progressReport.type === 'ADD_WIP_API') {
          setIsLoading((state) => ({ ...state, isCreatingUser: false }));
          dispatch(
            getWIPDataByApplicantId({
              reportId: Number(reportId),
              showAll: showAllProject,
            })
          );
        }
        if (progressReport.type === 'REFRESH_WIP_API') {
          setIsLoading((state) => ({ ...state, isUpdatingUser: false }));
          closeRefreshInfoModal();
          dispatch(
            getWIPDataByApplicantId({
              reportId: Number(reportId),
              showAll: showAllProject,
            })
          );
        }
        break;
      }
      case 'loading': {
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setIsLoadingData(true);
        }
        if (
          progressReport.type === 'UPDATE_WIP_API' ||
          progressReport.type === 'REFRESH_WIP_API'
        ) {
          setIsLoading((state) => ({ ...state, isUpdatingUser: true }));
        }
        if (progressReport.type === 'REMOVE_WIP_API') {
          setIsLoading((state) => ({ ...state, isDeletingUser: true }));
        }
        if (progressReport.type === 'ADD_WIP_API') {
          setIsLoading((state) => ({ ...state, isCreatingUser: true }));
        }
        break;
      }
      case 'failed': {
        if (progressReport.type === 'GET_WIP_DATA_BY_APPLICATIONID_API') {
          setIsLoadingData(false);
          setTableData([]);
        }

        const wipActionTypes = [
          'UPDATE_WIP_API',
          'REMOVE_WIP_API',
          'ADD_WIP_API',
          'REFRESH_WIP_API',
        ];

        if (
          typeof progressReport.type === 'string' &&
          wipActionTypes.includes(progressReport.type)
        ) {
          setIsLoading((state) => ({
            isCreatingUser: false,
            isDeletingUser: false,
            isUpdatingUser: false,
          }));
          if (progressReport.type === 'REFRESH_WIP_API') {
            closeRefreshInfoModal();
          }
        }
        break;
      }
      default:
        break;
    }
  }, [progressReport.status]);

  const columns = useMemo<MRT_ColumnDef<ProgressInfoAttributes>[]>(
    () => [
      {
        accessorKey: 'projectName',
        header: 'Project Name',
        mantineEditTextInputProps: ({ row }) => {
          return {
            label: (
              <Flex gap={1}>
                <>Project Name</>
                {/* {row.index !== -1 && row.id !== 'mrt-row-create' && (
                  <Badge variant="solid" colorScheme="green">
                    QBO
                  </Badge>
                )} */}
              </Flex>
            ),
            error: validationErrors?.projectName,
          };
        },
        Header: () => (
          <Flex flexDirection={'column'}>
            <>Project Name</>
            {/* <Badge variant="solid" colorScheme="green">
              QBO
            </Badge> */}
          </Flex>
        ),
        Cell: ({ cell, row }) => {
          const cellValue = cell.getValue() as any;
          return (
            <Flex alignItems="flex-start" justifyContent={'space-between'}>
              <Text>{cellValue}</Text>
              {row?.original?.generatedByMethod === 'qbo' && (
                <Badge variant="solid" colorScheme="green">
                  QBO
                </Badge>
              )}
            </Flex>
          );
        },
      },
      {
        accessorKey: 'projectStatus',
        header: 'Project Status',
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { value: 'active', label: 'Active' },
            { value: 'completed', label: 'Completed' },
          ],
          placeholder: 'Select Project Status',
          defaultValue: ['active'],
        },
        Cell: ({ cell }) => {
          const cellValue = cell.getValue() as any;
          return <Text textTransform={'capitalize'}>{cellValue}</Text>;
        },
      },
      {
        accessorKey: 'totalContractValue',
        header: 'Total Contract Value ',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.totalContractValue,
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      // {
      //   accessorKey: 'approvedChangeRequest',
      //   header: 'Approved PCRs',
      //   mantineEditTextInputProps: {
      //     type: 'number',
      //     error: validationErrors?.approvedChangeRequest,
      //   },
      //   Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      // },
      // {
      //   accessorKey: 'revisedContractValue',
      //   header: 'Revised Contract',
      //   mantineEditTextInputProps: {
      //     type: 'number',
      //     error: validationErrors?.revisedContractValue,
      //     disabled: true,
      //     display: 'none',
      //   },
      //   Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      // },
      {
        accessorKey: 'totalBilled',
        header: 'Total Billed',
        size: 105,
        maxSize: 105,
        Header: () => (
          <Flex flexDirection={'column'}>
            <>Total Billed</>
            {/* <Badge variant="solid" colorScheme="green">
              QBO
            </Badge> */}
          </Flex>
        ),
        mantineEditTextInputProps: ({ row }) => {
          return {
            label: (
              <Flex gap={1}>
                <>Total Billed</>
                {/* {row.index !== -1 && row.id !== 'mrt-row-create' && (
                  <Badge variant="solid" colorScheme="green">
                    QBO
                  </Badge>
                )} */}
              </Flex>
            ),
            error: validationErrors?.projectName,
            type: 'number',
          };
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      {
        accessorKey: 'toBeBilled',
        header: 'Total Contract Value - Total Billed',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.toBeBilled,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        Header: () => (
          <Box>
            <Text>To Be Billed</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'totalEstimatedCost',
        header: 'Total Estimated Cost',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.totalEstimatedCost,
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      // {
      //   accessorKey: 'approvedChanges',
      //   header: 'Auth. Budget Adj.',
      //   mantineEditTextInputProps: {
      //     type: 'number',
      //     error: validationErrors?.approvedChanges,
      //   },
      //   Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      // },
      // {
      //   accessorKey: 'revisedBudget',
      //   header: 'Revised Budget',
      //   mantineEditTextInputProps: {
      //     type: 'number',
      //     error: validationErrors?.revisedBudget,
      //     disabled: true,
      //     display: 'none',
      //     // value: row
      //   },
      //   Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      // },
      {
        accessorKey: 'costToDate',
        header: 'Cost to Date',
        size: 105,
        maxSize: 105,
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        mantineEditTextInputProps: ({ row }) => {
          return {
            label: (
              <Flex gap={1}>
                <>Cost to Date</>
                {/* {row.index !== -1 && row.id !== 'mrt-row-create' && (
                  <Badge variant="solid" colorScheme="green">
                    QBO
                  </Badge>
                )} */}
              </Flex>
            ),
            error: validationErrors?.projectName,
            type: 'number',
          };
        },
        Header: () => (
          <Flex flexDirection={'column'}>
            <>Cost to Date</>
            {/* <Badge variant="solid" colorScheme="green">
              QBO
            </Badge> */}
          </Flex>
        ),
      },

      {
        accessorKey: 'remainingBudget',
        header: 'Total Estimated Cost - Cost to Date',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.remainingBudget,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        Header: () => (
          <Box>
            <Text>Remaining Budget</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'percentEarned',
        header: '(Cost to Date / Total Estimated Cost) × 100',
        size: 115,
        maxSize: 115,
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.percentEarned,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{percentageConvert(cell.getValue() as number)}</>,
        Header: () => (
          <Box>
            <Text>% Earned</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'earnedValue',
        header: '(% Earned / 100) × Total Contract Value',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.earnedValue,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        Header: () => (
          <Box>
            <Text>Earned Value</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'billingsInExcessOfEarnedValue',
        header: 'Total Billed - Earned Value',
        size: 145,
        maxSize: 145,
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.billingsInExcessOfEarnedValue,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => (
          <Flex alignItems={'center'}>
            <>
              <>{formatNumber(Math.abs(cell.getValue() as number))}</>
            </>
            {typeof cell.getValue() === 'number' &&
              cell.getValue() !== 0 &&
              (Number(cell.getValue() as number) > 0 ? (
                <Tooltip
                  label="You have overbilled by this amount"
                  position="top"
                >
                  <ActionIcon>
                    <FaLongArrowAltUp color="green" />
                  </ActionIcon>
                </Tooltip>
              ) : (
                <Tooltip
                  label="You have underbilled by this amount"
                  position="top"
                >
                  <ActionIcon>
                    <FaLongArrowAltDown color="red" />
                  </ActionIcon>
                </Tooltip>
              ))}
          </Flex>
        ),
        Header: () => (
          <Box>
            <Text>O/U Billing : Earned Value</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'costInExcessOfBillings',
        header: 'Total Billed - Cost to Date',
        size: 120,
        maxSize: 120,
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.costInExcessOfBillings,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => (
          <Flex alignItems={'center'}>
            <>{formatNumber(Math.abs(cell.getValue() as number))}</>
            {typeof cell.getValue() === 'number' &&
              cell.getValue() !== 0 &&
              (Number(cell.getValue() as number) > 0 ? (
                <Tooltip
                  label="You have overbilled by this amount"
                  position="top"
                >
                  <ActionIcon>
                    <FaLongArrowAltUp color="green" />
                  </ActionIcon>
                </Tooltip>
              ) : (
                <Tooltip
                  label="You have underbilled by this amount"
                  position="top"
                >
                  <ActionIcon>
                    <FaLongArrowAltDown color="red" />
                  </ActionIcon>
                </Tooltip>
              ))}
          </Flex>
        ),
        Header: () => (
          <Box>
            <Text>O/U Billing : Cost to Date</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'forecastedGP',
        header: 'Total Contract Value - Total Estimated Cost',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.forecastedGP,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        Header: () => (
          <Box>
            <Text>Estimated GP</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'forecastedGPMargin',
        header: '(Estimated GP / Total Contract Value) × 100',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.forecastedGPMargin,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{percentageConvert(cell.getValue() as number)}</>,
        Header: () => (
          <Box>
            <Text>Estimated GM</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'currentGP',
        header: 'Earned value - Cost to Date',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.currentGP,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        Header: () => (
          <Box>
            <Text>Current GP</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'currentGPMargin',
        header: '(Current GP / Total Billed) × 100',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.currentGPMargin,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{percentageConvert(cell.getValue() as number)}</>,
        Header: () => (
          <Box>
            <Text>Current GM</Text>
          </Box>
        ),
      },
      // {
      //   accessorKey: 'underOverBillings',
      //   header: '+/- Billings',
      //   size: 110,
      //   maxSize: 110,
      //   mantineEditTextInputProps: {
      //     type: 'number',
      //     error: validationErrors?.underOverBillings,
      //     disabled: true,
      //     display: 'none',
      //   },
      //   Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      // },
      {
        accessorKey: 'projectBonded',
        header: 'Is this project bonded?',
        mantineEditTextInputProps: ({
          row,
        }: {
          row: MRT_Row<ProgressInfoAttributes>;
        }) => {
          let { id, projectBonded: apiProjectBonded } = row.original;
          return {
            label: (
              <Flex gap={1} flexGrow={1}>
                <>Is this project bonded?</>
              </Flex>
            ),
            inputContainer(children) {
              return (
                <Stack spacing={5} direction="row" flexGrow={1} width={'100%'}>
                  <Checkbox
                    isChecked={projectBonded === true}
                    colorScheme="green"
                    onChange={(e) => setProjectBonded(true)}
                    name={'projectBonded'}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={projectBonded === false}
                    onChange={(e) => setProjectBonded(false)}
                    name={'projectBonded'}
                  >
                    No
                  </Checkbox>
                </Stack>
              );
            },
            type: 'checkbox',
            styles: {
              root: {
                gridColumnStart: 1,
                gridColumnEnd: 3,
                display: 'flex',
                gap: '1rem',
              },
              label: {
                width: '100%',
              },
              input: {
                width: '100%',
              },
            },
          };
        },
        Cell: ({ cell, renderedCellValue }) => {
          const cellValue = cell.getValue() as any;
          return (
            <Text textTransform={'capitalize'}>
              {renderedCellValue === true && 'Yes'}
              {renderedCellValue === false && 'No'}
            </Text>
          );
        },
      },
      {
        accessorKey: 'bondNumber',
        header: 'Bond Numbers',
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.bondNumber,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }: { cell: any }) => (
          <>{Array.isArray(cell.getValue()) && cell.getValue().join(',')}</>
        ),
      },
    ],
    [validationErrors, projectBonded]
  );

  const handleExportData = (...data: any) => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      decimalSeparator: '.',
      // useKeysAsHeaders: true,
      filename: `${selectedApplicantData?.companyName} Work in Progress Report`,
      replaceUndefinedWith: '',
      showTitle: true,
      showColumnHeaders: true,
      columnHeaders: WIPExceleColumns.map((column) => ({
        key: column.accessorKey as string,
        displayLabel: column.header,
      })),
      // title: `Working Progress report of ${
      //   selectedApplicantData?.companyName
      // } as on ${
      //   progressReport.allWIPdata.updatedInfo
      //     ? progressReport.allWIPdata.updatedInfo
      //     : new Date()
      // }`,
      title: `${progressReport?.allWIPdata?.reportInfo?.qboCompanyName} \r\n${progressReport?.allWIPdata?.reportInfo?.reportName} Summary \r\nAccounting data last updated at ${progressReport?.allWIPdata?.updatedInfo ? progressReport?.allWIPdata?.updatedInfo : new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
    });
    let updatetableData = tableData?.map((item: any) => {
      return {
        ...item,
        projectBonded: item?.projectBonded
          ? item?.projectBonded === true
            ? 'Yes'
            : 'No'
          : null,
        percentEarned: item.percentEarned
          ? fixedNumber(item.percentEarned / 100)
          : 0,
        forecastedGPMargin: item.forecastedGPMargin
          ? fixedNumber(item.forecastedGPMargin / 100)
          : 0,
        currentGPMargin: item.currentGPMargin
          ? fixedNumber(item.currentGPMargin / 100)
          : 0,
      };
    });

    const refineData = updatetableData?.map((element: Record<string, any>) => {
      return replaceNullUndefined(element);
    });

    const csv = generateCsv(csvConfig)(refineData);
    download(csvConfig)(csv);
  };

  //call CREATE hook
  // const { mutateAsync: createUser, isLoading: isCreatingUser } = useCreateUser() as any;
  //call READ hook
  const isLoadingUsersError = false;
  const isFetchingUsers = false;

  //call UPDATE hook
  // const { mutateAsync: updateUser, isLoading: isUpdatingUser } =
  //   useUpdateUser() as any;
  // //call DELETE hook
  // const { mutateAsync: deleteUser, isLoading: isDeletingUser } =
  //   useDeleteUser() as any;

  //CREATE action
  const handleCreateProgress: MRT_TableOptions<ProgressInfoAttributes>['onCreatingRowSave'] =
    async ({ values, exitCreatingMode }) => {
      const newValidationErrors = validateUser(values as any);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      // await updateUser(values);
      if (progressReport.allWIPdata.id && Number(selectedBrokerageId) >= 0) {
        const progressInfo = calculatedData(values as any);
        const payload = {
          applicantId: Number(selectedBrokerageId),
          documentId: progressReport.allWIPdata.id,
          progressInfo: {
            ...progressInfo,
            projectBonded,
          },
        };
        // const newPayload:ProgressInfoAttributes
        dispatch(addWIPData(payload));
      }
      setProjectBonded(undefined);
      exitCreatingMode();
    };

  //UPDATE action
  const handleSaveUser: MRT_TableOptions<ProgressInfoAttributes>['onEditingRowSave'] =
    async ({ values, table, row }) => {
      const newValidationErrors = validateUser(values as any);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      // await updateUser(values);
      if (
        progressReport.allWIPdata.id &&
        !!row.original.uniqueProjectId &&
        Number(selectedBrokerageId) >= 0
      ) {
        const progressInfo = calculatedData(values as any);
        const payload = {
          applicantId: Number(selectedBrokerageId),
          documentId: progressReport.allWIPdata.id,
          // progressId: Number(row.original.progressId),
          uniqueProjectId: row.original.uniqueProjectId,
          progressInfo: {
            ...progressInfo,
            progressId: row.original.progressId,
            projectBonded,
          },
        };
        // const newPayload:ProgressInfoAttributes
        dispatch(updateWIPData(payload));
      }
      setProjectBonded(undefined);
      table.setEditingRow(null); //exit editing mode
    };

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<ProgressInfoAttributes>) =>
    modals.openConfirmModal({
      title: `DELETE`,
      children: (
        <Text>
          Are you sure you want to delete {row.original.projectName}? This
          action cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        if (progressReport.allWIPdata.id && !!row.original.uniqueProjectId) {
          dispatch(
            removeWIPData({
              documentId: progressReport.allWIPdata.id,
              uniqueProjectId: row.original.uniqueProjectId,
            })
          );
        }
      },
    });

  const handleRefreshData = () => {
    openRefreshInfoModal();
  };

  const handleRefreshConfirm = () => {
    selectedApplicantData?.applicantId &&
      dispatch(
        refreshProgressReport({
          applicantId: selectedApplicantData.applicantId,
          reportId: reportId,
        })
      );
  };

  const table = useMantineReactTable({
    columns,
    data: tableData as any,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row, index) => '' + index + row.progressId,
    enableDensityToggle: false,
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions', 'projectName'] },
    },
    defaultColumn: {
      minSize: 100,
      maxSize: 100,
      size: 100,
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 300, //make actions column wider
      },
    },
    mantineToolbarAlertBannerProps: false
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: isLoadingData ? 'hidden' : 'visible',
        // maxHeight: 'unset !important',
        // overflow: 'visible !important', //requird for sticky header
        overflow: isLoadingData ? 'hidden' : 'auto', //required for table vertical scroll
        // marginTop: isFullScreen ? "50px" : 0
      },
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
      sx: {
        '& .mantine-TableHeadCell-Content-Labels': {
          display: 'flex',
          justifyContent: 'space-between',
          gap: '7px',
        },
      },
    },
    mantineEditRowModalProps: {
      withCloseButton: true,
      className: 'progress-report-edit-modal',
    },
    // mantinePaperProps: {
    //   sx: {
    //     overflow: 'visible',
    //   },
    // },
    // mantineTableHeadProps: {
    //   sx: {
    //     position: 'sticky',
    //     top: 0,
    //     zIndex: 100,
    //   },
    // },
    enableStickyHeader: true,
    enableColumnPinning: true,
    enablePagination: false,
    positionPagination: 'none',
    onCreatingRowCancel: () => {
      setValidationErrors({});
      setProjectBonded(undefined);
    },
    onCreatingRowSave: handleCreateProgress,
    onEditingRowCancel: () => {
      setValidationErrors({});
      setProjectBonded(undefined);
    },
    onEditingRowSave: handleSaveUser,
    enableBottomToolbar: false,
    renderCreateRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Add New Row</Title>
        <Grid gridTemplateColumns={'1fr 1fr'} gap={4}>
          {internalEditComponents}
          {/* {internalEditComponents.map((child) => {
            return child
          })} */}
          {/* {row.getAllCells().map((child) => {
            return <>{child}</>
          })} */}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderEditRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Edit User</Title>
        <Grid gridTemplateColumns={'1fr 1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderRowActions: ({ row }) => (
      <Flex gap="md">
        <ActionIcon
          onClick={() => {
            setProjectBonded(undefined);
            table.setEditingRow(row);
            setProjectBonded(row.original.projectBonded ?? undefined);
          }}
          size={'sm'}
        >
          <Tooltip label="Edit" position="right">
            <IconEdit />
          </Tooltip>
        </ActionIcon>
        <Tooltip label="Delete" position="right">
          <ActionIcon
            color="red"
            onClick={() => openDeleteConfirmModal(row)}
            size={'sm'}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbar: ({ table }) => (
      <Flex direction={'column'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
              }}
              className="primary-btn"
            >
              Add New Row
            </Button>
            <Button
              color="lightblue"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={(...data) => handleExportData(data)}
              leftIcon={<IconDownload />}
              variant="filled"
              className="primary-btn"
            >
              Export All Data
            </Button>
            <Flex flexDirection={'column'}>
              <Flex alignItems={'flex-start'} gap={1}>
                <ActionIcon
                  style={{ color: '#114684' }}
                  onClick={() => {
                    handleRefreshData();
                  }}
                  size={'sm'}
                  disabled={isLoading.isUpdatingUser}
                >
                  <Tooltip
                    label={'Refresh Data'}
                    bg="gray.300"
                    color="black"
                    position="right"
                  >
                    <IconRefresh />
                  </Tooltip>
                </ActionIcon>
                <Text fontSize={'smaller'}>
                  Accounting data last updated at
                </Text>
                <Text fontSize={'smaller'}>
                  {getDateFormat(
                    progressReport?.allWIPdata?.updatedAt!,
                    'MMM DD, YYYY hh:mm A'
                  )}
                </Text>
              </Flex>
              <Text>
                <span>Work In Progress as of&nbsp;</span>
                {/* <Tooltip
                  label={'Start Date'}
                  bg="gray.300"
                  color="black"
                  position="top"
                >
                  <span>
                    {`${moment(progressReport.allWIPdata.reportInfo?.startPeriod).format('MMMM DD, YYYY')} -`}
                    &nbsp;
                  </span>
                </Tooltip> */}
                <Tooltip
                  label={'End Date'}
                  bg="gray.300"
                  color="black"
                  position="right"
                >
                  <span
                    style={{ cursor: 'pointer' }}
                  >{`${moment(progressReport.allWIPdata?.reportAsOf).format('MMMM DD, YYYY')}`}</span>
                </Tooltip>
              </Text>
            </Flex>
            {/* <Box className='pagination-wrapper'>
          <MRT_TablePagination position='top' table={table} />
        </Box> */}
          </Box>
          <Flex py={1} alignItems={'center'}>
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            {/* <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box> */}
          </Flex>
        </Flex>
        {(Object.keys(isLoading).some((key) => isLoading[key]) ||
          isLoadingData) && <Progress value={100} animate={true} />}
      </Flex>
    ),
    onIsFullScreenChange: (value) => setIsFullScreen(value),
    state: {
      isSaving: Object.keys(isLoading).some((key) => isLoading[key]),
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isLoadingData,
      isFullScreen: isFullScreen,
    },
  });

  return (
    <>
      <MantineReactTable table={table} key={'working-progress'} />
      <RefreshWIPReportInfoModal
        isOpen={isRefreshInfoModal}
        onClose={closeRefreshInfoModal}
        onConfirm={handleRefreshConfirm}
        refreshLoader={isLoading?.isUpdatingUser}
      />
    </>
  );
};

//CREATE hook (post new user to api)
// function useCreateUser() {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: async (user: ProgressInfoAttributes) => {
//       //send api update request here
//       await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
//       return Promise.resolve();
//     },
//     //client side optimistic update
//     onMutate: (newUserInfo: ProgressInfoAttributes) => {
//       queryClient.setQueryData(
//         ['users'],
//         (prevUsers: any) =>
//           [
//             ...prevUsers,
//             {
//               ...newUserInfo,
//               id: (Math.random() + 1).toString(36).substring(7),
//             },
//           ] as ProgressInfoAttributes[],
//       );
//     },
//     // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
//   });
// }

//READ hook (get users from api)
// function useGetUsers(fetchedUsers) {
//   return useQuery<ProgressInfoAttributes[]>({
//     queryKey: ['users'],
//     queryFn: async () => {
//       //send api request here
//       await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
//       return Promise.resolve(fetchedUsers);
//     },
//     refetchOnWindowFocus: false,
//   });
// }

//UPDATE hook (put user in api)
// function useUpdateUser() {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: async (user: ProgressInfoAttributes) => {
//       //send api update request here
//       await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
//       return Promise.resolve();
//     },
//     //client side optimistic update
//     onMutate: (newUserInfo: ProgressInfoAttributes) => {
//       queryClient.setQueryData(
//         ['users'],
//         (prevUsers: any) =>
//           prevUsers?.map((prevUser: ProgressInfoAttributes) =>
//             prevUser.progressId === newUserInfo.progressId ? newUserInfo : prevUser,
//           ),
//       );
//     },
//     // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
//   });
// }

//DELETE hook (delete user in api)
// function useDeleteUser() {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: async (userId: string) => {
//       //send api update request here
//       await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
//       return Promise.resolve();
//     },
//     //client side optimistic update
//     onMutate: (userId: string) => {
//       queryClient.setQueryData(
//         ['users'],
//         (prevUsers: any) =>
//           prevUsers?.filter((user: ProgressInfoAttributes) => user.progressId !== userId),
//       );
//     },
//     // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
//   });
// }

// const queryClient = new QueryClient();

const MantineTable = ({
  progressInfo,
  selectedBrokerageId,
  selectedId,
  selectedApplicantData,
  showAllProject,
  reportId,
}: dataProps) => (
  //Put this with your other react-query providers near root of your app
  // <QueryClientProvider client={queryClient}>
  <ModalsProvider>
    <Box maxW={'1550px'} mx={'auto'} width={'100%'}>
      <Example
        progressInfo={progressInfo}
        selectedBrokerageId={selectedBrokerageId}
        selectedId={selectedId}
        selectedApplicantData={selectedApplicantData}
        showAllProject={showAllProject}
        reportId={reportId}
      />
    </Box>
  </ModalsProvider>
  // </QueryClientProvider>
);

export default MantineTable;

export const validateRequired = (value: string) => !!value?.length;
const validateEmail = (email: string) =>
  !!email?.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

function validateUser(user: ProgressInfoAttributes) {
  return {
    projectName: !validateRequired(user.projectName)
      ? 'First Name is Required'
      : '',
    // lastName: !validateRequired(user.lastName) ? 'Last Name is Required' : '',
    // email: !validateEmail(user.email) ? 'Incorrect Email Format' : '',
  };
}
