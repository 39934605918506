import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Input,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FileInput, Group, MultiSelect } from '@mantine/core';
import { IconFileCv } from '@tabler/icons-react';
import { useFormik } from 'formik';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import { forwardRef, useEffect, useRef, useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { FaTimes } from 'react-icons/fa';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  fetchApplicantUserData,
  removeApplicantProfile,
} from 'src/Redux/Applicant/ApplicantProfile/ApplicantProfile';
import {
  createBondApplication,
  getBondApploicationReference,
  getWipBondApplicationDetails,
  removeBondApplicationType,
  updateBondApplication,
} from 'src/Redux/Broker/BondApplicationForm/slice';
import {
  BondApplicationAPIData,
  WipApplicationAPIData,
} from 'src/Redux/Broker/BondApplicationForm/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { bondApplicationYupSchema } from 'src/ValidationSchema/Bond/bond-application';
import { useAppToast, useS3FileUpload } from 'src/hooks';
import {
  addFocusEventListenerOnInput,
  getFileNames,
  preventAlphabetsTyping,
  removeFields,
  removeFocusEventListenerOnInput,
  trimStringValues,
} from 'src/utils/helpers';
import Swal from 'sweetalert2';
import {
  BondApplicationAttributes,
  BondType,
  applicationTypeOptions,
  bondTypeMap,
  contractTypeOptions,
  convertToYearsAndMonths,
  getBonfFormDownOption,
  getContractFormDownOption,
  getTaxTypeDownOption,
  initialBondApplicationState,
  paymentTermsOptions,
  typeOfOwnerOptions,
  typeOfWorkOptions,
} from './data';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs';

const BidBondReference = () => {
  const location = useLocation();
  const { id } = useParams();

  const { applicantId, isViewMode, applicationId } = location?.state || {};
  const [wipData, setWipData] = useState<WipApplicationAPIData | null>(null);
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const bondApplicationSlice = useAppSelector(
    (state) => state.bondApplicationSlice
  );
  const userData = useAppSelector((state) => state.broker.brokerProfileData);
  const applicantSlice = useAppSelector(
    (state) => state.applicantProfile?.user
  );
  const navigate = useNavigate();

  const { S3UploadError, S3UploadLoading, S3UploadResponse, uploadFilesInS3 } =
    useS3FileUpload();

  const [loader, setLoader] = useState<{
    btnLoader: boolean;
    contentLoader: boolean;
  }>({
    contentLoader: false,
    btnLoader: false,
  });

  const [fileNames, setFileNames] = useState<{
    uploadContractFiles: string | null;
    attachmentsFiles: string | null;
    preQualificationLetterFiles: string | null;
    penaltyContranctReferencesFiles: string | null;
    liquidatedDamagesReferencesFiles: string | null;
    designResponsibilityReferencesFiles: string | null;
    privateOwnersClauseAttachment: string | null;
  }>({
    uploadContractFiles: null,
    attachmentsFiles: null,
    preQualificationLetterFiles: null,
    penaltyContranctReferencesFiles: null,
    liquidatedDamagesReferencesFiles: null,
    designResponsibilityReferencesFiles: null,
    privateOwnersClauseAttachment: null,
  });

  const autoCompleteRef: any = useRef(null);
  const dateRequisitionRef = useRef<DatePickerInput>(null);
  const dateRequiredRef = useRef<DatePickerInput>(null);
  const projectStartDateRef = useRef<DatePickerInput>(null);
  const projectEndDateRef = useRef<DatePickerInput>(null);
  const dateTenderRef = useRef<DatePickerInput>(null);
  const dateContractRef = useRef<DatePickerInput>(null);
  const dateApprovedRef = useRef<DatePickerInput>(null);
  const workOnHandsAsOfRef = useRef<DatePickerInput>(null);
  const userType = localStorage.getItem('userType');
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [updatedFields, setUpdatedFields] = useState<Record<string, any>>({});

  // console.log('location', location, applicantId, id, typeof id, isViewMode);
  // console.log(
  //   'brokerProfileData',
  //   userData,
  //   'applicantSlice',
  //   applicantSlice,
  //   !Object.keys(applicantSlice?.data || {}).length
  // );

  useEffect(() => {
    if (!!id) {
      dispatch(
        getBondApploicationReference({
          id: Number(id),
          isCreatedFromApplication: !!applicationId,
        })
      );
    }

    return () => {
      dispatch(removeBondApplicationType({}));
      dispatch(removeApplicantProfile());
    };
  }, []);

  useEffect(() => {
    if (applicantId && !id) {
      dispatch(
        getWipBondApplicationDetails({
          applicantId: applicantId,
        })
      );
    }
  }, [applicantId]);

  useEffect(() => {
    if (!applicantSlice || !Object.keys(applicantSlice?.data || {}).length) {
      dispatch(fetchApplicantUserData({ applicantId }) as any);
    }
    if (userData?.data && applicantSlice?.data && !id) {
      formik.setValues({
        ...formik.values,
        brokerEmail: userData?.data?.email,
        brokerName: userData?.data?.firstName + ' ' + userData?.data?.lastName,
        brokerPhone: userData?.data?.contactNumber,
        applicantEmail: applicantSlice?.data?.email,
        applicantName: applicantSlice?.data?.companyName,
        applicantPhone: applicantSlice?.data?.businessPhone,
        workOnHandsAsOfDate: wipData?.workOnHandsAsOfDate
          ? moment(wipData.workOnHandsAsOfDate).format('DD/MM/YYYY')
          : '',
        totalBondedJobs: {
          contractPrice: wipData?.totalBondedJobs?.contractPrice,
          costToComplete: wipData?.totalBondedJobs?.costToComplete,
        },
        totalUnbondedJobs: {
          contractPrice: wipData?.totalUnbondedJobs?.contractPrice,
          costToComplete: wipData?.totalUnbondedJobs?.costToComplete,
        },
      });
    }
  }, [applicantSlice]);

  useEffect(() => {
    const { status, type, error, msg } = bondApplicationSlice;

    switch (status) {
      case 'loading': {
        if (type === 'GET_BOND_APPLICATION_FORM_DETAILS') {
          setLoader({ ...loader, btnLoader: true, contentLoader: true });
        }
        if (
          type === 'CREATE_BOND_APPLICATION_FORM' ||
          type === 'UPDATE_BOND_APPLICATION_FORM_DETAILS'
        ) {
          setLoader({ ...loader, btnLoader: true });
        }
        break;
      }
      case 'succeed': {
        console.log('bondRequestSlice111', bondApplicationSlice);
        if (type === 'GET_BOND_APPLICATION_FORM_DETAILS') {
          setLoader({ ...loader, btnLoader: false, contentLoader: false });
          setBondFormData(bondApplicationSlice?.bondApplicationData!);
        }
        if (type === 'GET_WIP_BOND_DETAILS') {
          setLoader({ ...loader, contentLoader: false });
          setWipData(bondApplicationSlice?.wipBondDetails);
        }
        if (
          type === 'CREATE_BOND_APPLICATION_FORM' ||
          type === 'UPDATE_BOND_APPLICATION_FORM_DETAILS'
        ) {
          setLoader({
            ...loader,
            btnLoader: false,
          });
          toast({
            title: msg,
            status: 'success',
          });
          dispatch(removeBondApplicationType({}));
          navigate(`/${userType}/bond-request`);
          // setBondFormData(bondApplicationSlice?.bondApplicationData!);
        }
        break;
      }
      case 'failed': {
        toast({
          title: error,
          status: 'error',
        });
        if (type === 'GET_BOND_APPLICATION_FORM_DETAILS') {
          setLoader({ ...loader, contentLoader: false });
          navigate('/');
        }
        if (
          type === 'CREATE_BOND_APPLICATION_FORM' ||
          type === 'UPDATE_BOND_APPLICATION_FORM_DETAILS'
        ) {
          setLoader({
            ...loader,
            btnLoader: false,
          });
        }
        break;
      }
      default:
        break;
    }
  }, [bondApplicationSlice.status, bondApplicationSlice.type]);

  const setBondFormData = (bondApplicationData: BondApplicationAPIData) => {
    console.log(bondApplicationData, 'bondApplicationData11');
    if (!!Object.keys(bondApplicationData!).length) {
      const {
        id,
        brokerId,
        createdBy,
        createdByType,
        createdAt,
        // assignedBy,
        // assignedTo,
        // assignedAt,
        bondNumber,
        applicationId,
        approvedDate,
        approver,
        approvalStamp,
        reasonOfRejection,
        reasonOfRejectionOther,
        brokerageLogoUrl,
        bidBondId,
        ...rest
      } = (bondApplicationData as any)!;

      console.log(rest, 'rest');
      const bondFormFiles = {
        uploadContractFiles: getFileNames(rest?.uploadContract),
        attachmentsFiles: getFileNames(rest?.bidBondDetails?.attachments),
        preQualificationLetterFiles: getFileNames(
          rest?.bidBondDetails?.preQualificationLetter
        ),
        penaltyContranctReferencesFiles: getFileNames(
          rest?.penaltyContranctReferences
        ),
        liquidatedDamagesReferencesFiles: getFileNames(
          rest?.liquidatedDamagesReferences
        ),
        designResponsibilityReferencesFiles: getFileNames(
          rest?.designResponsibilityReferences
        ),
        privateOwnersClauseAttachment: getFileNames(
          rest?.privateOwnersClauseAttachment
        ),
      };
      formik.setValues(rest);
      setFileNames(bondFormFiles);
    }
  };

  const formik = useFormik({
    onSubmit: async (values: BondApplicationAttributes) => {
      console.log('onsubmit', values); // values
      try {
        // delete values.uploadContract;
        const clonedValues = _cloneDeep(values);
        const updatedValues = trimStringValues(
          removeFields(clonedValues, [
            'uploadContractAttachments',
            'bidBondDetailsUpload',
            'penaltyContranctReferencesAttachments',
            'liquidatedDamagesReferencesAttachments',
            'designResponsibilityAttachments',
            'preQualificationLetterAttachments',
            'isRejected',
            'assignedBy',
            'assignedTo',
            'assignedAt',
            'isCreatedFromBidBond',
            'generatedFinalBondId',
            'history',
          ])
        );
        const toalSumContractPrice = getTotalSum(values, 'contractPrice');
        const toalSumCostToComplete = getTotalSum(values, 'costToComplete');
        const payload = {
          ...updatedValues,
          applicantId: applicantId,
          totalSum: {
            contractPrice: toalSumContractPrice,
            costToComplete: toalSumCostToComplete,
          },
        };
        if (!isViewMode) {
          if (!!id) {
            await dispatch(
              updateBondApplication({
                formData: payload,
                bondId: Number(id),
                isCreatedFromApplication: !!applicationId,
              })
            );
          } else {
            await dispatch(createBondApplication({ formData: payload }));
          }
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        console.log('finally');
      }
    },
    initialValues: initialBondApplicationState,
    validationSchema: bondApplicationYupSchema,
  });

  const handleClear = (key: string, index?: any) => {
    formik.setFieldValue(key, null);
    formik.setFieldValue('projectLocationCountry', null);
    autoCompleteRef.current.value = '';
  };

  const handleAddBox = (index: number) => {
    formik.setValues((prevValues: any) => {
      const newValues = { ...prevValues };
      let tenderResults = newValues?.tenderResults || {};
      const bidders = newValues?.tenderResults?.bidders || [];

      bidders?.push({
        companyName: null,
        amount: null,
      });

      tenderResults = { ...tenderResults, bidders: bidders };

      return {
        ...newValues,
        tenderResults,
      };
    });
  };

  const handleRemoveBox = (index: number) => {
    formik.setValues((prevValues) => {
      const newValues = { ...prevValues };
      let tenderResults = newValues?.tenderResults || {};
      const bidders = newValues?.tenderResults?.bidders || [];

      if (bidders?.length > 1) {
        const removeIndex = index;
        bidders?.splice(removeIndex, 1);
        tenderResults = { ...tenderResults, bidders: bidders };

        return {
          ...newValues,
          tenderResults,
        };
      }

      return newValues;
    });
  };
  const handleAddBoxWorkSublet = (index: number) => {
    formik.setValues((prevValues: any) => {
      const newValues = { ...prevValues };
      const workSublet = newValues?.workSublet || [];

      workSublet?.push({
        name: null,
        amount: null,
        bonded: null,
      });

      return {
        ...newValues,
        workSublet,
      };
    });
  };

  const handleRemoveBoxWorkSublet = (index: number) => {
    formik.setValues((prevValues) => {
      const newValues = { ...prevValues };
      const workSublet = newValues?.workSublet || [];

      if (workSublet?.length > 1) {
        const removeIndex = index;
        workSublet?.splice(removeIndex, 1);

        return {
          ...newValues,
          workSublet,
        };
      }

      return newValues;
    });
  };

  const getTotalSum = (formikValues: any, key: string) => {
    const { totalBondedJobs, totalUnbondedJobs, totalOutstandingBids } =
      formikValues!;
    const totalSum =
      totalBondedJobs?.[`${key}`] +
      totalUnbondedJobs?.[`${key}`] +
      totalOutstandingBids?.[`${key}`];
    return totalSum;
  };

  const handleUploadAttachments = async (
    payload: File[],
    attachmentFieldName: string,
    filedName: string
  ) => {
    try {
      formik.setFieldValue(attachmentFieldName, payload);
      const formData = new FormData();
      payload.forEach((file, index) => {
        formData.append(`files`, file);
      });
      const res = await uploadFilesInS3(formData);
      console.log('res', res);

      const filedData = res?.data?.map((item: any) => {
        return {
          linkId: item?.encoding,
          link: item?.location,
          urlKey: item?.key,
        };
      });
      formik.setFieldValue(filedName, filedData);
    } catch (error) {
      console.log('Upload error', error);
    }
  };

  const { values, setFieldValue } = formik;
  const {
    estimatedContractPrice,
    performanceBondPercentage,
    paymentBondPercentage,
    siteImprovementBondPercentage,
    environmentalOrReclamationBondPercentage,
    completionBondPercentage,
    materialBondPercentage,
    maintenanceBondPercentage,
    supplyBondPercentage,
    subdivisionBondPercentage,
    licenseAndPermitBondPercentage,
  } = values.bidBondDetails ?? {};

  useEffect(() => {
    // Calculate performance bond amount
    if (performanceBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(performanceBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue('bidBondDetails.performanceBondAmount', calculatedAmount);
    }

    // Calculate payment bond amount
    if (paymentBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(paymentBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue('bidBondDetails.paymentBondAmount', calculatedAmount);
    }

    // Calculate site improvement bond amount
    if (siteImprovementBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(siteImprovementBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue(
        'bidBondDetails.siteImprovementBondAmount',
        calculatedAmount
      );
    }

    // Calculate environmental or reclamation bond amount
    if (environmentalOrReclamationBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(environmentalOrReclamationBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue(
        'bidBondDetails.environmentalOrReclamationBondAmount',
        calculatedAmount
      );
    }

    // Calculate completion bond amount
    if (completionBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(completionBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue('bidBondDetails.completionBondAmount', calculatedAmount);
    }

    // Calculate material bond amount
    if (materialBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(materialBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue('bidBondDetails.materialBondAmount', calculatedAmount);
    }

    if (maintenanceBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(maintenanceBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue('bidBondDetails.maintenanceBondAmount', calculatedAmount);
    }

    if (supplyBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(supplyBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue('bidBondDetails.supplyBondAmount', calculatedAmount);
    }

    if (subdivisionBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(subdivisionBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue('bidBondDetails.subdivisionBondAmount', calculatedAmount);
    }

    if (licenseAndPermitBondPercentage && estimatedContractPrice) {
      const calculatedAmount = (
        (Number(licenseAndPermitBondPercentage) / 100) *
        estimatedContractPrice
      ).toFixed(2);
      setFieldValue(
        'bidBondDetails.licenseAndPermitBondAmount',
        calculatedAmount
      );
    }
  }, [
    performanceBondPercentage,
    paymentBondPercentage,
    siteImprovementBondPercentage,
    environmentalOrReclamationBondPercentage,
    completionBondPercentage,
    materialBondPercentage,
    estimatedContractPrice,
    setFieldValue,
    maintenanceBondPercentage,
    supplyBondPercentage,
    subdivisionBondPercentage,
    licenseAndPermitBondPercentage,
  ]);

  console.log('formik', formik);

  const SelectItem = forwardRef<HTMLDivElement, any>(
    ({ label, tooltip, ...others }: any, ref: any) => (
      <div ref={ref} {...others}>
        <Tooltip label={tooltip} hasArrow placement="right-start">
          <Group noWrap>
            <Text>{label}</Text>
          </Group>
        </Tooltip>
      </div>
    )
  );

  const isDateValid = moment(
    formik.values.workOnHandsAsOfDate,
    'DD/MM/YYYY',
    true
  ).isValid();

  const stepRef = useRef<any>(null);
  const UserType = localStorage.getItem('userType');

  const OnHandelFinish = async () => {
    stepRef?.current?.formik?.handleSubmit();

    Swal.fire({
      title: `Is ${UserType === 'broker' ? 'broker' : 'applicant'} the final approver?`,
      icon: 'info',
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonColor: '#28a745',
      confirmButtonText: 'Yes',
      denyButtonColor: 'red',
      denyButtonAriaLabel: 'No',
      denyButtonText: 'No',
      showCloseButton: true,
      customClass: {
        confirmButton: 'btn-green',
        denyButton: 'btn-red',
      },
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'You are taking full responsibility and accuracy of the application, are you sure you want to be the final approver?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#28a745',
          confirmButtonText: 'Yes',
          cancelButtonColor: 'red',
          cancelButtonText: 'No',
          showCloseButton: true,
          customClass: {
            confirmButton: 'btn-green',
            cancelButton: 'btn-red',
          },
          allowOutsideClick: false,
        }).then(async (secondResult: any) => {
          try {
            if (secondResult.isConfirmed) {
              formik.setFieldValue('status', 'completed');
              formik.handleSubmit();
              Swal.fire({
                title: `Application is now complete and ready to download.`,
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                confirmButtonColor: '#28a745',
                confirmButtonText: 'Yes',
                cancelButtonColor: 'red',
                cancelButtonText: 'No',
                showCloseButton: true,
                customClass: {
                  confirmButton: 'btn-green',
                  cancelButton: 'btn-red',
                },
                allowOutsideClick: false,
              }).then(async (thirdResult) => {
                if (thirdResult.dismiss) {
                  navigate('/broker/bond-request');
                } else {
                  // User clicked "No" on the final dialog
                  // Do something else if needed
                }
              });
            }
          } catch (error) {
            // Handle error
            console.error('API call error:', error);
          }
        });
      }
    });
  };

  return (
    <Box m={10}>
      <Box>
        <Text fontWeight="600" color="#114684" textAlign="center" fontSize={20}>
          Bid Bond
        </Text>
      </Box>
      <Container
        maxW={'container.lg'}
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        h="auto"
        p="20px"
        borderRadius="20px"
        gap={5}
        bg={'white'}
        // overflow="auto" height="calc(100vh - 150px)" sx={{ "&::-webkit-scrollbar": { display: "none" } }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            display={'flex'}
            flexDirection="column"
            gap={5}
            boxShadow="0px 0px 5px 0px rgba(0,0,0,0.3)"
            borderRadius="20px"
            p="20px"
          >
            <Box>
              <Text
                fontWeight="600"
                color="#114684"
                textAlign="center"
                fontSize={20}
              >
                Contact & Project Overview{' '}
              </Text>
            </Box>

            {/* build ui from BondApplicationAttributes from loop */}
            <Box
              display={'grid'}
              gridTemplateColumns={{
                base: 'repeat(auto-fill, minmax(220px, 1fr))',
                md: 'repeat(auto-fill, minmax(270px, 1fr))',
              }}
              gap={5}
            >
              {/* Input Type text filed ex. Attention (Underwriter Name)
                    Input Type number filed ex. Broker Phone
                    Input Type Date ex. Date of Requisition
                    Google Autocomplete ex. Project Location
                    */}

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Attention (Underwriter Name){' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Attention (Underwriter Name)"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.attention ?? ''}
                  name="attention"
                  readOnly={!!isViewMode}
                  focusBorderColor={'#7f7f7f'}
                  borderColor={'#7f7f7f'}
                  _hover={{
                    borderColor: '#7f7f7f',
                  }}
                />
                {formik.touched.attention && formik.errors.attention && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.attention}
                  </div>
                )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Final Bond Number </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Final Bond Number"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.finalBondNumber ?? ''}
                  name="finalBondNumber"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={!!isViewMode}
                />
                {formik.touched.finalBondNumber &&
                  formik.errors.finalBondNumber && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.finalBondNumber}
                    </div>
                  )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Broker Name {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Broker Name"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.brokerName ?? ''}
                  name="brokerName"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={true}
                />
                {formik.touched.brokerName && formik.errors.brokerName && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.brokerName}
                  </div>
                )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Broker Phone</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Broker Phone"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.brokerPhone ?? ''}
                  name="brokerPhone"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={true}
                />
                {formik.touched.brokerPhone && formik.errors.brokerPhone && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.brokerPhone}
                  </div>
                )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Broker Email {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Broker Email"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.brokerEmail ?? ''}
                  name="brokerEmail"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={true}
                />
                {formik.touched.brokerEmail && formik.errors.brokerEmail && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.brokerEmail}
                  </div>
                )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Applicant Name{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Applicant Name"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.applicantName ?? ''}
                  name="applicantName"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={true}
                />
                {formik.touched.applicantName &&
                  formik.errors.applicantName && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.applicantName}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Applicant Phone</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Applicant Phone"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.applicantPhone ?? ''}
                  name="applicantPhone"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={true}
                />
                {formik.touched.applicantPhone &&
                  formik.errors.applicantPhone && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.applicantPhone}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Applicant Email{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Applicant Email"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.applicantEmail ?? ''}
                  name="applicantEmail"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={true}
                />
                {formik.touched.applicantEmail &&
                  formik.errors.applicantEmail && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.applicantEmail}
                    </div>
                  )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Date of Requisition</li>
                </Text>
                <DatePickerInput
                  ref={dateRequisitionRef}
                  value={moment(formik.values.dateOfRequisition).toDate()}
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('dateOfRequisition', date);
                  }}
                  locale="en"
                  placeholder="Date of Requisition"
                  className={`react-datepicker-component react-datepicker-input input gray-border`}
                  showOnInputClick={true}
                  onShow={() =>
                    addFocusEventListenerOnInput(dateRequisitionRef)
                  }
                  onHide={() =>
                    removeFocusEventListenerOnInput(dateRequisitionRef)
                  }
                  // disabled
                  displayFormat="DD/MM/YYYY hh:mm"
                  minDate={moment(new Date()).toDate()}
                />
                {formik.touched.dateOfRequisition &&
                  formik.errors.dateOfRequisition && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.dateOfRequisition}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Date Required{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <DatePickerInput
                  ref={dateRequiredRef}
                  value={
                    formik.values.dateRequired !== null
                      ? formik.values.dateRequired
                      : undefined
                  }
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('dateRequired', date);
                  }}
                  locale="en"
                  placeholder="Date Required"
                  className={`react-datepicker-component react-datepicker-input input gray-border`}
                  showOnInputClick={true}
                  onShow={() => addFocusEventListenerOnInput(dateRequiredRef)}
                  onHide={() =>
                    removeFocusEventListenerOnInput(dateRequiredRef)
                  }
                  minDate={moment(new Date()).toDate()}
                  disabled={!!isViewMode}
                />
                {formik.touched.dateRequired && formik.errors.dateRequired && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.dateRequired}
                  </div>
                )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    General Contractor Name{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="General Contractor Name"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.generalContractorName ?? ''}
                  name="generalContractorName"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={!!isViewMode}
                />
                {formik.touched.generalContractorName &&
                  formik.errors.generalContractorName && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.generalContractorName}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>General Contractor Phone</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="General Contractor Phone"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.generalContractorPhone ?? ''}
                  name="generalContractorPhone"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {formik.touched.generalContractorPhone &&
                  formik.errors.generalContractorPhone && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.generalContractorPhone}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    General Contractor Email{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="General Contractor Email"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.generalContractorEmail ?? ''}
                  name="generalContractorEmail"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={!!isViewMode}
                />
                {formik.touched.generalContractorEmail &&
                  formik.errors.generalContractorEmail && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.generalContractorEmail}
                    </div>
                  )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Engineer/Architect Name{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Engineer/Architect Name"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.engineerOrArchitectName ?? ''}
                  name="engineerOrArchitectName"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={!!isViewMode}
                />
                {formik.touched.engineerOrArchitectName &&
                  formik.errors.engineerOrArchitectName && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.engineerOrArchitectName}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Engineer/Architect Phone</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Engineer/Architect Phone"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.engineerOrArchitectPhone ?? ''}
                  name="engineerOrArchitectPhone"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {formik.touched.engineerOrArchitectPhone &&
                  formik.errors.engineerOrArchitectPhone && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.engineerOrArchitectPhone}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Engineer/Architect Email{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Engineer/Architect Email"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.engineerOrArchitectEmail ?? ''}
                  name="engineerOrArchitectEmail"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={!!isViewMode}
                />
                {formik.touched.engineerOrArchitectEmail &&
                  formik.errors.engineerOrArchitectEmail && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.engineerOrArchitectEmail}
                    </div>
                  )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Owner Name {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Owner Name"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.ownerName ?? ''}
                  name="ownerName"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={!!isViewMode}
                />
                {formik.touched.ownerName && formik.errors.ownerName && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.ownerName}
                  </div>
                )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Owner Phone</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Owner Phone"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.ownerPhone ?? ''}
                  name="ownerPhone"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {formik.touched.ownerPhone && formik.errors.ownerPhone && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.ownerPhone}
                  </div>
                )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Owner Email {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Owner Email"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.ownerEmail ?? ''}
                  name="ownerEmail"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={!!isViewMode}
                />
                {formik.touched.ownerEmail && formik.errors.ownerEmail && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.ownerEmail}
                  </div>
                )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Type of owner for this project</li>
                </Text>
                <Select
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== 'Others') {
                      formik.setFieldValue('typeOfOwnerOther', null);
                    }
                    formik.setFieldValue('typeOfOwner', value);
                  }}
                  value={formik.values.typeOfOwner ?? ''}
                  name="typeOfOwner"
                  fontSize={'13px'}
                  _disabled={{ opacity: 1 }}
                  w="100%"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  disabled={!!isViewMode}
                >
                  {' '}
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Option"
                  />
                  {typeOfOwnerOptions?.map((item: any, index: number) => (
                    <option
                      style={{ color: 'black' }}
                      key={index}
                      value={item.value}
                      label={item.label}
                    />
                  ))}
                </Select>
                {formik.touched.typeOfOwner && formik.errors.typeOfOwner && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.typeOfOwner}
                  </div>
                )}
              </Box>
              {formik?.values?.typeOfOwner === 'Others' && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>Type of Owner (Other)</li>
                  </Text>

                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="Type of Owner (Other)"
                    borderRadius="5px"
                    onChange={formik.handleChange}
                    value={formik.values.typeOfOwnerOther ?? ''}
                    name="typeOfOwnerOther"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    readOnly={!!isViewMode}
                  />
                  {formik.touched.typeOfOwnerOther &&
                    formik.errors.typeOfOwnerOther && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.typeOfOwnerOther}
                      </div>
                    )}
                </Box>
              )}
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Type of work {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <MultiSelect
                  className="legalform_pdfPreference"
                  data={typeOfWorkOptions?.map((el: any) => ({
                    label: el?.label,
                    value: el?.value,
                  }))}
                  // label="Select Type Of Work"
                  placeholder="Type of Work"
                  maxDropdownHeight={160}
                  styles={{
                    input: {
                      maxHeight: '60px',
                    },
                  }}
                  onChange={(value) => {
                    if (!value.includes('Others')) {
                      formik.setFieldValue('typeOfWorkOther', null);
                    }
                    formik.setFieldValue('typeOfWork', value);
                  }}
                  value={formik.values.typeOfWork ?? []}
                  // withinPortal={true}
                  disabled={!!isViewMode}
                />
                {formik.touched.typeOfWork && formik.errors.typeOfWork && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.typeOfWork}
                  </div>
                )}
              </Box>
              {formik.values.typeOfWork?.includes('Others') && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>Type of Work (Other)</li>
                  </Text>

                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="Type of Work (Other)"
                    borderRadius="5px"
                    onChange={formik.handleChange}
                    value={formik.values.typeOfWorkOther ?? ''}
                    name="typeOfWorkOther"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    readOnly={!!isViewMode}
                  />
                  {formik.touched.typeOfWorkOther &&
                    formik.errors.typeOfWorkOther && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.typeOfWorkOther}
                      </div>
                    )}
                </Box>
              )}

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Work on Hands as of Date</li>
                </Text>

                {isDateValid && !showDatePicker ? (
                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="Work on Hands"
                    borderRadius="5px"
                    onClick={() => setShowDatePicker(true)}
                    onChange={formik.handleChange}
                    value={formik.values.workOnHandsAsOfDate ?? ''}
                    name="workOnHandsAsOfDate"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    readOnly={!!isViewMode}
                  />
                ) : (
                  <DatePickerInput
                    ref={workOnHandsAsOfRef}
                    value={
                      formik.values.workOnHandsAsOfDate
                        ? moment(
                            formik.values.workOnHandsAsOfDate,
                            'DD/MM/YYYY'
                          ).toDate()
                        : undefined
                    }
                    onChange={(date: Date | null) => {
                      const formattedDate = date
                        ? moment(date).format('DD/MM/YYYY')
                        : '';
                      formik.setFieldValue(
                        'workOnHandsAsOfDate',
                        formattedDate
                      );
                      setShowDatePicker(false); // Hide DatePicker after selecting date
                    }}
                    locale="en"
                    placeholder="Select Date"
                    className="react-datepicker-component react-datepicker-input input gray-border"
                    showOnInputClick={true}
                    minDate={moment(new Date()).toDate()}
                    onShow={() =>
                      addFocusEventListenerOnInput(workOnHandsAsOfRef)
                    }
                    onHide={() => {
                      removeFocusEventListenerOnInput(workOnHandsAsOfRef);
                      setShowDatePicker(false); // Hide DatePicker when clicking outside
                    }}
                    disabled={!!isViewMode}
                  />
                )}

                {formik.touched.workOnHandsAsOfDate &&
                  formik.errors.workOnHandsAsOfDate && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.workOnHandsAsOfDate}
                    </div>
                  )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Total Contract Price of Bonded Jobs</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Total Contract Price of Bonded Jobs"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.totalBondedJobs?.contractPrice ?? ''}
                  name="totalBondedJobs.contractPrice"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {(formik.touched.totalBondedJobs as any)?.contractPrice &&
                  (formik.errors.totalBondedJobs as any)?.contractPrice && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors.totalBondedJobs as any)?.contractPrice}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Total Backlog of Bonded Jobs</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Total Backlog of Bonded Jobs"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.totalBondedJobs?.costToComplete ?? ''}
                  name="totalBondedJobs.costToComplete"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {(formik.touched.totalBondedJobs as any)?.costToComplete &&
                  (formik.errors.totalBondedJobs as any)?.costToComplete && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors.totalBondedJobs as any)?.costToComplete}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Total Contract Price of Unbonded Jobs</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Total Contract Price of Unbonded Jobs"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.totalUnbondedJobs?.contractPrice ?? ''}
                  name="totalUnbondedJobs.contractPrice"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {(formik.touched.totalUnbondedJobs as any)?.contractPrice &&
                  (formik.errors.totalUnbondedJobs as any)?.contractPrice && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors.totalUnbondedJobs as any)?.contractPrice}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Total Backlog of Unbonded Jobs</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Total Backlog of Unbonded Jobs"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.totalUnbondedJobs?.costToComplete ?? ''}
                  name="totalUnbondedJobs.costToComplete"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {(formik.touched.totalUnbondedJobs as any)?.costToComplete &&
                  (formik.errors.totalUnbondedJobs as any)?.costToComplete && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors.totalUnbondedJobs as any)?.costToComplete}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Total Contract Price of Outstanding Bids</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Total Contract Price of Outstanding Bids"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={
                    formik.values.totalOutstandingBids?.contractPrice ?? ''
                  }
                  name="totalOutstandingBids.contractPrice"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {(formik.touched.totalOutstandingBids as any)?.contractPrice &&
                  (formik.errors.totalOutstandingBids as any)
                    ?.contractPrice && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {
                        (formik.errors.totalOutstandingBids as any)
                          ?.contractPrice
                      }
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Total Backlog of Outstanding Bids</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Total Backlog of Outstanding Bids"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={
                    formik.values.totalOutstandingBids?.costToComplete ?? ''
                  }
                  name="totalOutstandingBids.costToComplete"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {(formik.touched.totalOutstandingBids as any)?.costToComplete &&
                  (formik.errors.totalOutstandingBids as any)
                    ?.costToComplete && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {
                        (formik.errors.totalOutstandingBids as any)
                          ?.costToComplete
                      }
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Total (Contract price)</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Total (Contract price)"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={getTotalSum(formik.values, 'contractPrice')}
                  name="totalSum.contractPrice"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  disabled
                />
                {(formik.touched.totalSum as any)?.contractPrice &&
                  (formik.errors.totalSum as any)?.contractPrice && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors.totalSum as any)?.contractPrice}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Total (Cost to complete)</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Total (Cost to complete)"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={getTotalSum(formik.values, 'costToComplete')}
                  name="totalSum.costToComplete"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  disabled
                />
                {(formik.touched.totalSum as any)?.costToComplete &&
                  (formik.errors.totalSum as any)?.costToComplete && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors.totalSum as any)?.costToComplete}
                    </div>
                  )}
              </Box>
            </Box>

            <Divider
              orientation="horizontal"
              color={'#7f7f7f'}
              my={5}
              opacity={1}
            />

            <Box>
              <Text
                fontWeight="600"
                color="#114684"
                textAlign="center"
                fontSize={20}
              >
                Contract Details{' '}
              </Text>
            </Box>
            <Box
              display={'grid'}
              gridTemplateColumns={{
                base: 'repeat(auto-fill, minmax(150px, 1fr))',
                md: 'repeat(auto-fill, minmax(170px, 1fr))',
              }}
              gap={5}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Project Name {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Project Name"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.projectName ?? ''}
                  name="projectName"
                  focusBorderColor={
                    updatedFields.projectName ? 'red' : '#7f7f7f'
                  }
                  borderColor={updatedFields.projectName ? 'red' : '#7f7f7f'}
                  _hover={{
                    borderColor: updatedFields.projectName ? 'red' : '#7f7f7f',
                  }}
                  readOnly={
                    (!!id && !!formik.values?.isRejected) ||
                    !!isViewMode ||
                    (!!id && !!formik.values?.isCreatedFromBidBond)
                  }
                  cursor={
                    (!!id && !!formik.values?.isRejected) ||
                    !!isViewMode ||
                    (!!id && !!formik.values?.isCreatedFromBidBond)
                      ? 'not-allowed'
                      : 'initial'
                  }
                />
                {formik.touched.projectName && formik.errors.projectName && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.projectName}
                  </div>
                )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>
                    Project Location{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>

                <Box display="flex" position={'relative'}>
                  <ReactGoogleAutocomplete
                    apiKey={GOOGLE_MAPS_API_KEY}
                    defaultValue={formik.values.projectLocation ?? ''}
                    onPlaceSelected={(place) => {
                      if (place && place.formatted_address) {
                        console.log('place', place);

                        formik.setFieldValue(
                          'projectLocation',
                          place.formatted_address
                        );

                        place?.address_components?.forEach((item: any) => {
                          const types = item.types;
                          if (types.includes('country')) {
                            formik.setFieldValue(
                              'projectLocationCountry',
                              item.long_name
                            );
                          }
                        });
                      } else {
                        formik.setFieldValue('projectLocation', null);
                        formik.setFieldValue('projectLocationCountry', null);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className={`googlelocationcity`}
                    ref={autoCompleteRef}
                    options={{
                      types: [],
                    }}
                    style={{
                      padding: '7px 16px',
                      outline: '#7f7f7f',
                      cursor:
                        (!!id && !!formik.values?.isRejected) ||
                        !!isViewMode ||
                        (!!id && !!formik.values?.isCreatedFromBidBond)
                          ? 'not-allowed'
                          : 'initial',
                    }}
                    disabled={
                      (!!id && !!formik.values?.isRejected) ||
                      !!isViewMode ||
                      (!!id && !!formik.values?.isCreatedFromBidBond)
                    }
                  />
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    bg={'#f0f5f9'}
                    mr={2}
                    cursor={
                      (!!id && !!formik.values?.isRejected) ||
                      !!isViewMode ||
                      (!!id && !!formik.values?.isCreatedFromBidBond)
                        ? 'not-allowed'
                        : 'pointer'
                    }
                    _hover={{ color: '#114684' }}
                    position={'absolute'}
                    top={'50%'}
                    transform={'translateY(-50%)'}
                    right={0}
                  >
                    {formik.values?.projectLocation && (
                      <FaTimes
                        onClick={() =>
                          (!!id && !!formik.values?.isRejected) ||
                          !!isViewMode ||
                          (!!id && !!formik.values?.isCreatedFromBidBond)
                            ? {}
                            : handleClear('projectLocation')
                        }
                      />
                    )}
                  </Box>
                </Box>

                {formik.touched.projectLocation &&
                  formik.errors.projectLocation && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.projectLocation}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Project Start Date</li>
                </Text>
                <DatePickerInput
                  ref={projectStartDateRef}
                  value={
                    formik.values.projectStartDate !== null
                      ? formik.values.projectStartDate
                      : undefined
                  }
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('projectStartDate', date);
                  }}
                  locale="en"
                  placeholder="Project Start Date"
                  className={`react-datepicker-component react-datepicker-input input gray-border`}
                  showOnInputClick={true}
                  onShow={() =>
                    addFocusEventListenerOnInput(projectStartDateRef)
                  }
                  onHide={() =>
                    removeFocusEventListenerOnInput(projectStartDateRef)
                  }
                  minDate={moment(new Date()).toDate()}
                  disabled={!!isViewMode}
                />
                {formik.touched.projectStartDate &&
                  formik.errors.projectStartDate && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.projectStartDate}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Project End Date</li>
                </Text>
                <DatePickerInput
                  ref={projectEndDateRef}
                  value={
                    formik.values.projectEndDate !== null
                      ? formik.values.projectEndDate
                      : undefined
                  }
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('projectEndDate', date);
                  }}
                  locale="en"
                  placeholder="Project End Date"
                  className={`react-datepicker-component react-datepicker-input input gray-border`}
                  showOnInputClick={true}
                  onShow={() => addFocusEventListenerOnInput(projectEndDateRef)}
                  onHide={() =>
                    removeFocusEventListenerOnInput(projectEndDateRef)
                  }
                  minDate={moment(new Date()).toDate()}
                  disabled={!!isViewMode}
                />
                {formik.touched.projectEndDate &&
                  formik.errors.projectEndDate && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.projectEndDate}
                    </div>
                  )}
              </Box>

              {/* upload Contract */}
              <Box display={'flex'} flexDirection={'column'}>
                <FileInput
                  icon={
                    <IconFileCv
                      style={{ width: 18, height: 18 }}
                      stroke={1.5}
                    />
                  }
                  styles={{
                    input: {
                      maxWidth: '350px',
                      border: '1px solid #7f7f7f',
                      minWidth: '155px',
                      minHeight: '35px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                    placeholder: {
                      color: `black !important`,
                    },
                  }}
                  // @ts-ignore
                  placeholder={
                    !!id && !!fileNames?.uploadContractFiles
                      ? fileNames?.uploadContractFiles
                      : 'Upload Contract'
                  }
                  label="Upload Contract"
                  name="uploadContractAttachments"
                  multiple={true}
                  onChange={(payload: File[]) =>
                    handleUploadAttachments(
                      payload,
                      'uploadContractAttachments',
                      'uploadContract'
                    )
                  }
                  value={formik.values.uploadContractAttachments ?? []}
                  // description={`The file format should be - xlsx, xls`}
                  variant="filled"
                  accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  disabled={!!isViewMode}
                />
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Contract Form</li>
                </Text>
                <Select
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== 'Other') {
                      formik.setFieldValue('contractDetailsOther', null);
                    }
                    formik.setFieldValue('contractDetails', value);
                  }}
                  value={formik.values.contractDetails ?? ''}
                  name="contractDetails"
                  fontSize={'13px'}
                  _disabled={{ opacity: 1 }}
                  w="100%"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  disabled={!!isViewMode}
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Option"
                  />
                  {getContractFormDownOption(
                    formik.values?.projectLocationCountry!
                  )?.map((item: any, index: number) => (
                    <option
                      style={{ color: 'black' }}
                      key={index}
                      value={item.value}
                      label={item.label}
                    />
                  ))}
                </Select>
                {formik.touched.contractDetails &&
                  formik.errors.contractDetails && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.contractDetails}
                    </div>
                  )}
              </Box>
              {formik?.values?.contractDetails === 'Other' && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>Contract Details (Other)</li>
                  </Text>

                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="Contract Details (Other)"
                    borderRadius="5px"
                    onChange={formik.handleChange}
                    value={formik.values.contractDetailsOther ?? ''}
                    name="contractDetailsOther"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    readOnly={!!isViewMode}
                  />
                  {formik.touched.contractDetailsOther &&
                    formik.errors.contractDetailsOther && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.contractDetailsOther}
                      </div>
                    )}
                </Box>
              )}
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Private Owners Clause</li>
                </Text>

                <Stack spacing={5} direction="row">
                  {/* Yes Checkbox */}
                  <Checkbox
                    colorScheme="green"
                    isChecked={formik.values?.privateOwnersClause === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'privateOwnersClause',
                        e.target.checked
                      );

                      // Force validation of privateOwnersClauseReferences when "Yes" is selected
                      if (e.target.checked) {
                        formik.validateField('privateOwnersClauseAttachment');
                      }
                    }}
                    readOnly={!!isViewMode}
                  >
                    Yes
                  </Checkbox>

                  {/* No Checkbox */}
                  <Checkbox
                    colorScheme="red"
                    isChecked={formik.values?.privateOwnersClause === false}
                    onChange={(e) => {
                      formik.setValues({
                        ...formik.values,
                        privateOwnersClause: !e.target.checked,
                        privateOwnersClauseAttachment: null,
                      });

                      // Validate to clear any errors when "No" is selected
                      formik.validateField('privateOwnersClauseAttachment');
                    }}
                    readOnly={!!isViewMode}
                  >
                    No
                  </Checkbox>
                </Stack>

                {/* Display Errors for privateOwnersClause if any */}
                {formik.touched.privateOwnersClauseAttachment &&
                  formik.errors.privateOwnersClauseAttachment && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.privateOwnersClauseAttachment}
                    </div>
                  )}
              </Box>

              {/* Attachment Section when "Yes" is selected */}
              {formik?.values?.privateOwnersClause && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <Flex>
                      Attachment
                      <Tooltip
                        label="Please upload documentation pertaining to the Private Owners Clause"
                        placement="top"
                      >
                        <span>
                          <HiMiniInformationCircle
                            size={18}
                            color="#114684"
                            cursor="pointer"
                          />
                        </span>
                      </Tooltip>
                    </Flex>
                  </Text>

                  <Box display={'flex'} flexDirection={'column'}>
                    <FileInput
                      icon={
                        <IconFileCv
                          style={{ width: 18, height: 18 }}
                          stroke={1.5}
                        />
                      }
                      styles={{
                        input: {
                          maxWidth: '350px',
                          border: '1px solid #7f7f7f',
                          minWidth: '155px',
                          minHeight: '35px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        placeholder: {
                          color: `black !important`,
                        },
                      }}
                      name="privateOwnersClauseAttachment"
                      // @ts-ignore
                      placeholder={
                        !!id && !!fileNames?.privateOwnersClauseAttachment
                          ? fileNames?.privateOwnersClauseAttachment
                          : 'Attachments'
                      }
                      multiple={true}
                      onChange={(payload: File[]) =>
                        handleUploadAttachments(
                          payload,
                          'privateOwnersClauseAttachment',
                          'privateOwnersClauseAttachment'
                        )
                      }
                      value={formik.values.privateOwnersClauseAttachment ?? []}
                      variant="filled"
                      accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      disabled={!!isViewMode}
                    />
                  </Box>

                  {/* Display Validation Error for Attachments */}
                  {formik.touched.privateOwnersClauseAttachment &&
                    formik.errors.privateOwnersClauseAttachment && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.privateOwnersClauseAttachment}
                      </div>
                    )}
                </Box>
              )}

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Contract Type</li>
                </Text>
                <Select
                  onChange={formik.handleChange}
                  value={formik.values.contractType ?? ''}
                  name="contractType"
                  fontSize={'13px'}
                  _disabled={{ opacity: 1 }}
                  w="100%"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  disabled={!!isViewMode}
                >
                  {Array.isArray(contractTypeOptions) &&
                    contractTypeOptions.map((item: any, index: number) => (
                      <option
                        style={{ color: 'black' }}
                        key={index}
                        value={item.value}
                        label={item.label}
                      />
                    ))}
                </Select>
                {formik.touched.contractType && formik.errors.contractType && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.contractType}
                  </div>
                )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>$ Estimated Sublet Budget for this project?</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="$ Estimated Sublet Budget for this project"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.estimatedSubletBudget ?? ''}
                  name="estimatedSubletBudget"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {formik.touched.estimatedSubletBudget &&
                  formik.errors.estimatedSubletBudget && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.estimatedSubletBudget}
                    </div>
                  )}
              </Box>

              {formik.values.estimatedSubletBudget && (
                <Box
                  gridColumnStart={1}
                  gridColumnEnd={{ base: 1, md: 2, lg: 3, xl: 4 }}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  {formik?.values?.workSublet?.map((item: any, i: number) => (
                    <>
                      <Box
                        display={{ base: 'block', md: 'flex' }}
                        flex={{ base: 1, md: 1 }}
                        gap={3}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                      >
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          flex={{ base: 1, md: 1 }}
                          maxW={'300px'}
                        >
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                            mb={1}
                          >
                            <li>
                              Major Subs Name{' '}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </li>
                          </Text>

                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="text"
                            placeholder="Major Subs Name"
                            borderRadius="5px"
                            onChange={formik.handleChange}
                            value={formik.values.workSublet?.[i]?.name ?? ''}
                            name={`workSublet[${i}].name`}
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            readOnly={!!isViewMode}
                          />
                          {(formik.touched.workSublet as any)?.[i]?.name &&
                            (formik.errors.workSublet as any)?.[i]?.name && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {(formik.errors.workSublet as any)?.[i]?.name}
                              </div>
                            )}
                        </Box>
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          flex={{ base: 1, md: 1 }}
                          maxW={'300px'}
                        >
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                            mb={1}
                          >
                            <li>Amount</li>
                          </Text>
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="number"
                            placeholder="Amount"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={formik.values.workSublet?.[i]?.amount ?? ''}
                            name={`workSublet[${i}].amount`}
                            onKeyDown={preventAlphabetsTyping}
                            onWheel={(e) =>
                              (e.target as HTMLInputElement).blur()
                            }
                            readOnly={!!isViewMode}
                          />
                          {(formik.touched.workSublet as any)?.[i]?.amount &&
                            (formik.errors.workSublet as any)?.[i]?.amount && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {(formik.errors.workSublet as any)?.[i]?.amount}
                              </div>
                            )}
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                            mb={1}
                          >
                            <li>Bonded</li>
                          </Text>
                          <Stack spacing={5} direction="row">
                            <Checkbox
                              colorScheme="green"
                              isChecked={
                                formik.values?.workSublet?.[i]?.bonded === true
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `workSublet[${i}].bonded`,
                                  e.target.checked
                                );
                              }}
                              readOnly={!!isViewMode}
                            >
                              Yes
                            </Checkbox>
                            <Checkbox
                              colorScheme="red"
                              isChecked={
                                formik.values?.workSublet?.[i]?.bonded === false
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `workSublet[${i}].bonded`,
                                  !e.target.checked
                                );
                              }}
                              readOnly={!!isViewMode}
                            >
                              No
                            </Checkbox>
                          </Stack>
                          {(formik.touched.workSublet as any)?.[i]?.bonded &&
                            (formik.errors.workSublet as any)?.[i]?.bonded && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {(formik.errors.workSublet as any)?.[i]?.bonded}
                              </div>
                            )}
                        </Box>

                        {formik?.values?.workSublet && i === 0 && (
                          <Box
                            display="flex"
                            alignItems="center"
                            fontSize="xl"
                            // marginTop="18px"
                            onClick={() =>
                              !!isViewMode ? {} : handleAddBoxWorkSublet(i)
                            }
                            cursor={!!isViewMode ? 'not-allowed' : 'pointer'}
                            pointerEvents={'auto'}
                            sx={{
                              mt: '18px',
                              visibility:
                                formik?.values?.workSublet?.length < 5 &&
                                i === 0
                                  ? 'visible'
                                  : 'hidden',
                            }}
                          >
                            <CiCirclePlus />
                          </Box>
                        )}

                        {formik?.values?.workSublet && i > 0 && (
                          <Box
                            display="flex"
                            alignItems="center"
                            fontSize="xl"
                            mt="18px"
                            // marginTop="18px"
                            onClick={() =>
                              !!isViewMode ? {} : handleRemoveBoxWorkSublet(i)
                            }
                            cursor={!!isViewMode ? 'not-allowed' : 'pointer'}
                            pointerEvents={'auto'}
                          >
                            <CiCircleMinus />
                          </Box>
                        )}
                      </Box>
                    </>
                  ))}
                  {(formik.touched.workSublet as any) &&
                    typeof formik.errors.workSublet === 'string' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.workSublet as any}
                      </div>
                    )}
                </Box>
              )}

              <Box
                display={'flex'}
                flexDirection={'column'}
                gridColumnStart={1}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Payment Terms</li>
                </Text>
                <Select
                  onChange={formik.handleChange}
                  value={formik.values.paymentTerms ?? ''}
                  name="paymentTerms"
                  fontSize={'13px'}
                  _disabled={{ opacity: 1 }}
                  w="100%"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  disabled={!!isViewMode}
                >
                  {Array.isArray(paymentTermsOptions) &&
                    paymentTermsOptions.map((item: any, index: number) => (
                      <option
                        style={{ color: 'black' }}
                        key={index}
                        value={item.value}
                        label={item.label}
                      />
                    ))}
                </Select>
                {formik.touched.paymentTerms && formik.errors.paymentTerms && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.paymentTerms}
                  </div>
                )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Holdback</li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={formik.values?.holdback === true}
                    onChange={(e) => {
                      formik.setFieldValue('holdback', e.target.checked);
                    }}
                    readOnly={!!isViewMode}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={formik.values?.holdback === false}
                    onChange={(e) => {
                      formik.setFieldValue('holdback', !e.target.checked);
                    }}
                    readOnly={!!isViewMode}
                  >
                    No
                  </Checkbox>
                </Stack>
                {formik.touched.holdback && formik.errors.holdback && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.holdback}
                  </div>
                )}
              </Box>

              {/* holdbackPercentage */}
              {formik?.values?.holdback && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>Holdback Percentage</li>
                  </Text>
                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="number"
                    placeholder="Holdback Percentage"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={formik.handleChange}
                    value={formik.values.holdbackPercentage ?? ''}
                    name="holdbackPercentage"
                    onKeyDown={preventAlphabetsTyping}
                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    readOnly={!!isViewMode}
                  />
                  {formik.touched.holdbackPercentage &&
                    formik.errors.holdbackPercentage && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.holdbackPercentage}
                      </div>
                    )}
                </Box>
              )}
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Architect/Engineer Estimate</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Architect/Engineer Estimate"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.architectEstimate ?? ''}
                  name="architectEstimate"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                {formik.touched.architectEstimate &&
                  formik.errors.architectEstimate && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.architectEstimate}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Maintenance Warranty Term(# Of Months)</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Maintenance Warranty Term"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  onChange={formik.handleChange}
                  value={formik.values.maintenanceWarrantyTerm ?? ''}
                  name="maintenanceWarrantyTerm"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  readOnly={!!isViewMode}
                />
                <Text>
                  {convertToYearsAndMonths(
                    formik.values.maintenanceWarrantyTerm!
                  )}
                </Text>
                {formik.touched.maintenanceWarrantyTerm &&
                  formik.errors.maintenanceWarrantyTerm && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.maintenanceWarrantyTerm}
                    </div>
                  )}
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Are there penalties in the contract</li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={formik.values?.penaltiesInContract === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'penaltiesInContract',
                        e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={formik.values?.penaltiesInContract === false}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'penaltiesInContract',
                        !e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    No
                  </Checkbox>
                </Stack>
                {formik.touched.penaltiesInContract &&
                  formik.errors.penaltiesInContract && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.penaltiesInContract}
                    </div>
                  )}
              </Box>
              {formik?.values?.penaltiesInContract && (
                <>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>
                        please attach the relevant contract references{' '}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <FileInput
                      icon={
                        <IconFileCv
                          style={{ width: 18, height: 18 }}
                          stroke={1.5}
                        />
                      }
                      styles={{
                        input: {
                          maxWidth: '350px',
                          border: '1px solid #7f7f7f',
                          minWidth: '155px',
                          minHeight: '35px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        placeholder: {
                          color: `black !important`,
                        },
                      }}
                      name="penaltyContranctReferencesAttachments"
                      // @ts-ignore
                      placeholder={
                        !!id && !!fileNames?.penaltyContranctReferencesFiles
                          ? fileNames?.penaltyContranctReferencesFiles
                          : 'Attachments'
                      }
                      // label="Upload Contract"
                      multiple={true}
                      onChange={(payload: File[]) =>
                        handleUploadAttachments(
                          payload,
                          'penaltyContranctReferencesAttachments',
                          'penaltyContranctReferences'
                        )
                      }
                      value={
                        formik.values.penaltyContranctReferencesAttachments ??
                        []
                      }
                      // description={`The file format should be - xlsx, xls`}
                      variant="filled"
                      accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      disabled={!!isViewMode}
                    />
                    {formik.touched.penaltyContranctReferences &&
                      formik.errors.penaltyContranctReferences && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.penaltyContranctReferences}
                        </div>
                      )}
                  </Box>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Are Penalties capped</li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={formik.values?.arePenaltiesCapped === true}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'arePenaltiesCapped',
                            e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={formik.values?.arePenaltiesCapped === false}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'arePenaltiesCapped',
                            !e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        No
                      </Checkbox>
                    </Stack>
                    {formik.touched.arePenaltiesCapped &&
                      formik.errors.arePenaltiesCapped && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.arePenaltiesCapped}
                        </div>
                      )}
                  </Box>
                </>
              )}

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Are there Liquidated Damages in the contract</li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={formik.values?.liquidatedDamages === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'liquidatedDamages',
                        e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={formik.values?.liquidatedDamages === false}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'liquidatedDamages',
                        !e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    No
                  </Checkbox>
                </Stack>
                {formik.touched.liquidatedDamages &&
                  formik.errors.liquidatedDamages && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.liquidatedDamages}
                    </div>
                  )}
              </Box>
              {formik?.values?.liquidatedDamages && (
                <>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>
                        please attach the relevant contract references{' '}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <FileInput
                      icon={
                        <IconFileCv
                          style={{ width: 18, height: 18 }}
                          stroke={1.5}
                        />
                      }
                      styles={{
                        input: {
                          maxWidth: '350px',
                          border: '1px solid #7f7f7f',
                          minWidth: '155px',
                          minHeight: '35px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        placeholder: {
                          color: `black !important`,
                        },
                      }}
                      name="liquidatedDamagesReferencesAttachments"
                      // @ts-ignore
                      placeholder={
                        !!id && !!fileNames?.liquidatedDamagesReferencesFiles
                          ? fileNames?.liquidatedDamagesReferencesFiles
                          : 'Attachments'
                      }
                      // label="Upload Contract"
                      multiple={true}
                      onChange={(payload: File[]) =>
                        handleUploadAttachments(
                          payload,
                          'liquidatedDamagesReferencesAttachments',
                          'liquidatedDamagesReferences'
                        )
                      }
                      value={
                        formik.values.liquidatedDamagesReferencesAttachments ??
                        []
                      }
                      // description={`The file format should be - xlsx, xls`}
                      variant="filled"
                      accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      disabled={!!isViewMode}
                    />
                    {formik.touched.liquidatedDamagesReferences &&
                      formik.errors.liquidatedDamagesReferences && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.liquidatedDamagesReferences}
                        </div>
                      )}
                  </Box>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Are Damages capped</li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={
                          formik.values?.liquidatedDamagesCapped === true
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'liquidatedDamagesCapped',
                            e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={
                          formik.values?.liquidatedDamagesCapped === false
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'liquidatedDamagesCapped',
                            !e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        No
                      </Checkbox>
                    </Stack>
                    {formik.touched.liquidatedDamagesCapped &&
                      formik.errors.liquidatedDamagesCapped && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.liquidatedDamagesCapped}
                        </div>
                      )}
                  </Box>
                </>
              )}

              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Environmental Hazards</li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={formik.values?.environmentalHazards === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'environmentalHazards',
                        e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={formik.values?.environmentalHazards === false}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'environmentalHazards',
                        !e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    No
                  </Checkbox>
                </Stack>
                {formik.touched.environmentalHazards &&
                  formik.errors.environmentalHazards && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.environmentalHazards}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Design Responsibility</li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={formik.values?.designResponsibility === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'designResponsibility',
                        e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={formik.values?.designResponsibility === false}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'designResponsibility',
                        !e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    No
                  </Checkbox>
                </Stack>
                {formik.touched.designResponsibility &&
                  formik.errors.designResponsibility && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.designResponsibility}
                    </div>
                  )}
              </Box>
              {/* designResponsibilityAttachments => array */}
              {formik.values?.designResponsibility && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>Design Responsibility References</li>
                  </Text>
                  <Box display={'flex'} flexDirection={'column'}>
                    <FileInput
                      icon={
                        <IconFileCv
                          style={{ width: 18, height: 18 }}
                          stroke={1.5}
                        />
                      }
                      styles={{
                        input: {
                          maxWidth: '350px',
                          border: '1px solid #7f7f7f',
                          minWidth: '155px',
                          minHeight: '35px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        placeholder: {
                          color: `black !important`,
                        },
                      }}
                      name="designResponsibilityAttachments"
                      // @ts-ignore
                      placeholder={
                        !!id && !!fileNames?.designResponsibilityReferencesFiles
                          ? fileNames?.designResponsibilityReferencesFiles
                          : 'Attachments'
                      }
                      // label="Upload Contract"
                      multiple={true}
                      onChange={(payload: File[]) =>
                        handleUploadAttachments(
                          payload,
                          'designResponsibilityAttachments',
                          'designResponsibilityReferences'
                        )
                      }
                      value={
                        formik.values.designResponsibilityAttachments ?? []
                      }
                      // description={`The file format should be - xlsx, xls`}
                      variant="filled"
                      accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      disabled={!!isViewMode}
                    />
                  </Box>
                  {formik.touched.designResponsibilityReferences &&
                    formik.errors.designResponsibilityReferences && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.designResponsibilityReferences}
                      </div>
                    )}
                </Box>
              )}
              {/* bondForm => object */}
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Bond Form</li>
                </Text>
                <Box>
                  <Select
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== 'Other') {
                        formik.setFieldValue('bondForm.otherDetails', null);
                      }
                      formik.setFieldValue('bondForm.bondFormValue', value);
                    }}
                    value={formik.values.bondForm?.bondFormValue ?? ''}
                    name="bondForm.bondFormValue"
                    fontSize={'13px'}
                    _disabled={{ opacity: 1 }}
                    w="100%"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    disabled={!!isViewMode}
                  >
                    <option
                      value={''}
                      style={{ color: 'black' }}
                      label="Select Option"
                    />
                    {getBonfFormDownOption(
                      formik.values?.projectLocationCountry!
                    )?.map((item: any, index: number) => (
                      <option
                        style={{ color: 'black' }}
                        key={index}
                        value={item.value}
                        label={item.label}
                      />
                    ))}
                  </Select>
                  {(formik.touched.bondForm as any)?.bondFormValue &&
                    (formik.errors.bondForm as any)?.bondFormValue && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {(formik.errors.bondForm as any)?.bondFormValue}
                      </div>
                    )}
                </Box>
                {/* show only if other is selected => string */}
                {formik?.values?.bondForm?.bondFormValue === 'Other' && (
                  <Box>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="text"
                      placeholder="Bond Form"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={formik.values.bondForm?.otherDetails ?? ''}
                      name="bondForm.otherDetails"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      readOnly={!!isViewMode}
                    />
                    {(formik.touched.bondForm as any)?.otherDetails &&
                      (formik.errors.bondForm as any)?.otherDetails && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {(formik.errors.bondForm as any)?.otherDetails}
                        </div>
                      )}
                  </Box>
                )}
              </Box>
              {/* bondApplicationType => select */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                gridColumnStart={1}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Bond Application Type</li>
                </Text>

                <Select
                  onChange={(e) => {
                    formik.handleChange(e); // Handle the original change for bondApplicationType

                    // Clear the MultiSelect selections
                    formik.setFieldValue('bondType', []);

                    // Clear all related fields for each bond type in bondTypeMap
                    Object.values(bondTypeMap).forEach((bondKey: string) => {
                      formik.setFieldValue(
                        `bidBondDetails.${bondKey}Percentage`,
                        ''
                      );
                      formik.setFieldValue(
                        `bidBondDetails.${bondKey}Amount`,
                        ''
                      );
                      formik.setFieldValue(
                        `finalBondDetails.${bondKey}Amount`,
                        ''
                      );
                    });

                    // Clear the Estimated Contract Price field
                    formik.setFieldValue(
                      'bidBondDetails.estimatedContractPrice',
                      ''
                    );
                    formik.setFieldValue('finalBondDetails.contractPrice', '');
                    formik.setFieldValue('bidBondDetails.bidBondAmount', '');
                  }}
                  value={formik.values.bondApplicationType ?? ''}
                  name="bondApplicationType"
                  fontSize="13px"
                  _disabled={{ opacity: 1 }}
                  w="100%"
                  borderRadius="5px"
                  focusBorderColor="#7f7f7f"
                  borderColor="#7f7f7f"
                  _hover={{ borderColor: '#7f7f7f' }}
                  disabled={!!isViewMode}
                >
                  {Array.isArray(applicationTypeOptions) &&
                    applicationTypeOptions.map((item: any, index: number) => (
                      <option
                        style={{ color: 'black' }}
                        key={index}
                        value={item.value}
                        label={item.label}
                      />
                    ))}
                </Select>

                {formik.touched.bondApplicationType &&
                  formik.errors.bondApplicationType && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.bondApplicationType}
                    </div>
                  )}
              </Box>

              {/* show only if bid is selected */}
              {formik.values.bondApplicationType === 'Bid' && (
                <>
                  {/* Bond Type */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Bond Type</li>
                    </Text>

                    <MultiSelect
                      className="legalform_pdfPreference"
                      data={BondType.map((el) => ({
                        label: el.label,
                        value: el.value,
                        tooltip: el.tooltip,
                      }))}
                      placeholder="Bond Type"
                      maxDropdownHeight={160}
                      styles={{
                        input: { maxHeight: '90px' },
                      }}
                      itemComponent={SelectItem}
                      onChange={(value) => {
                        const previousValues = formik.values.bondType ?? [];
                        console.log(previousValues, 'previousValues');
                        const removedItems = previousValues.filter(
                          (item: string) => !value.includes(item)
                        );

                        removedItems.forEach((removedBond: any) => {
                          const bondKey = bondTypeMap[removedBond];
                          if (bondKey) {
                            formik.setFieldValue(
                              `bidBondDetails.${bondKey}Percentage`,
                              ''
                            );
                            formik.setFieldValue(
                              `bidBondDetails.${bondKey}Amount`,
                              ''
                            );
                          }
                        });

                        formik.setFieldValue(
                          'bondType',
                          value.length ? value : null
                        );
                      }}
                      value={formik.values.bondType ?? []}
                      disabled={!!isViewMode}
                    />

                    {formik.touched.bondType && formik.errors.bondType && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.bondType}
                      </div>
                    )}
                  </Box>

                  {/* estimatedContractPrice */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Estimated Contract Price</li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="Estimated Contract Price"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={
                        formik.values.bidBondDetails?.estimatedContractPrice ??
                        ''
                      }
                      name="bidBondDetails.estimatedContractPrice"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      readOnly={!!isViewMode}
                    />
                    {(formik.touched.bidBondDetails as any)
                      ?.estimatedContractPrice &&
                      (formik.errors.bidBondDetails as any)
                        ?.estimatedContractPrice && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            (formik.errors.bidBondDetails as any)
                              ?.estimatedContractPrice
                          }
                        </div>
                      )}
                  </Box>

                  {/* performanceBondAmount => number */}
                  {values.bondType?.includes('Performance Bond') && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Performance Bond Amount</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={performanceBondPercentage ?? ''}
                          name="bidBondDetails.performanceBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            setFieldValue(
                              'bidBondDetails.performanceBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            values.bidBondDetails?.performanceBondAmount ?? ''
                          }
                          name="bidBondDetails.performanceBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            setFieldValue(
                              'bidBondDetails.performanceBondAmount',
                              String(amountValue)
                            );

                            setFieldValue(
                              'bidBondDetails.performanceBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>
                      {(formik.touched.bidBondDetails as any)
                        ?.performanceBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.performanceBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.performanceBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* paymentBondAmount => number */}
                  {formik.values.bondType?.includes('Payment Bond') && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Payment Bond Amount</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.paymentBondPercentage ?? ''
                          }
                          name="bidBondDetails.paymentBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.paymentBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails?.paymentBondAmount ??
                            ''
                          }
                          name="bidBondDetails.paymentBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.paymentBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.paymentBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>
                      {(formik.touched.bidBondDetails as any)
                        ?.paymentBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.paymentBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.paymentBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* maintenanceBondAmount => number */}
                  {formik.values.bondType?.includes('Maintenance Bond') && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Maintenance Bond Amount</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.maintenanceBondPercentage ?? ''
                          }
                          name="bidBondDetails.maintenanceBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.maintenanceBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.maintenanceBondAmount ?? ''
                          }
                          name="bidBondDetails.maintenanceBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.maintenanceBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.maintenanceBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>

                      {/* Error message */}
                      {(formik.touched.bidBondDetails as any)
                        ?.maintenanceBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.maintenanceBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.maintenanceBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* Supply Bond => number */}
                  {formik.values.bondType?.includes('Supply Bond') && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Supply Bond</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.supplyBondPercentage ?? ''
                          }
                          name="bidBondDetails.supplyBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.supplyBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails?.supplyBondAmount ?? ''
                          }
                          name="bidBondDetails.supplyBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.supplyBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.supplyBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>

                      {/* Error message */}
                      {(formik.touched.bidBondDetails as any)
                        ?.supplyBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.supplyBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.supplyBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* Subdivision Bond => number */}
                  {formik.values.bondType?.includes('Subdivision Bond') && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Subdivision Bond</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.subdivisionBondPercentage ?? ''
                          }
                          name="bidBondDetails.subdivisionBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.subdivisionBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.subdivisionBondAmount ?? ''
                          }
                          name="bidBondDetails.subdivisionBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.subdivisionBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.subdivisionBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>

                      {/* Error message */}
                      {(formik.touched.bidBondDetails as any)
                        ?.subdivisionBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.subdivisionBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.subdivisionBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* License and Permit Bond => number */}
                  {formik.values.bondType?.includes(
                    'License and Permit Bond'
                  ) && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>License and Permit Bond</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.licenseAndPermitBondPercentage ?? ''
                          }
                          name="bidBondDetails.licenseAndPermitBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.licenseAndPermitBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.licenseAndPermitBondAmount ?? ''
                          }
                          name="bidBondDetails.licenseAndPermitBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.licenseAndPermitBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.licenseAndPermitBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>

                      {/* Error message */}
                      {(formik.touched.bidBondDetails as any)
                        ?.licenseAndPermitBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.licenseAndPermitBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.licenseAndPermitBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* Environmental or Reclamation Bond => number */}
                  {formik.values.bondType?.includes(
                    'Environmental or Reclamation Bond'
                  ) && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Environmental or Reclamation Bond</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.environmentalOrReclamationBondPercentage ?? ''
                          }
                          name="bidBondDetails.environmentalOrReclamationBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.environmentalOrReclamationBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.environmentalOrReclamationBondAmount ?? ''
                          }
                          name="bidBondDetails.environmentalOrReclamationBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.environmentalOrReclamationBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.environmentalOrReclamationBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>

                      {/* Error message */}
                      {(formik.touched.bidBondDetails as any)
                        ?.environmentalOrReclamationBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.environmentalOrReclamationBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.environmentalOrReclamationBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* completionBondAmount => number */}
                  {formik.values.bondType?.includes('Completion Bond') && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Completion Bond</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.completionBondPercentage ?? ''
                          }
                          name="bidBondDetails.completionBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.completionBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.completionBondAmount ?? ''
                          }
                          name="bidBondDetails.completionBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.completionBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.completionBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>

                      {/* Error message */}
                      {(formik.touched.bidBondDetails as any)
                        ?.completionBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.completionBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.completionBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* Site Improvement Bond => number */}
                  {formik.values.bondType?.includes(
                    'Site Improvement Bond'
                  ) && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Site Improvement Bond</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.siteImprovementBondPercentage ?? ''
                          }
                          name="bidBondDetails.siteImprovementBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.siteImprovementBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.siteImprovementBondAmount ?? ''
                          }
                          name="bidBondDetails.siteImprovementBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.siteImprovementBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.siteImprovementBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>

                      {/* Error message */}
                      {(formik.touched.bidBondDetails as any)
                        ?.siteImprovementBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.siteImprovementBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.siteImprovementBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* Material Bond => number */}
                  {formik.values.bondType?.includes('Material Bond') && (
                    <Box display="flex" flexDirection="column">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Material Bond</li>
                      </Text>

                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        {/* Percentage Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="text"
                          placeholder="Percentage (%)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails
                              ?.materialBondPercentage ?? ''
                          }
                          name="bidBondDetails.materialBondPercentage"
                          onChange={(e) => {
                            const percentValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.materialBondPercentage',
                              percentValue
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={false}
                        />

                        {/* Dollar Amount Input */}
                        <Input
                          fontSize="13px"
                          w="50%"
                          type="number"
                          placeholder="Amount ($)"
                          borderRadius="5px"
                          value={
                            formik.values.bidBondDetails?.materialBondAmount ??
                            ''
                          }
                          name="bidBondDetails.materialBondAmount"
                          onChange={(e) => {
                            const amountValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ''
                            );
                            formik.setFieldValue(
                              'bidBondDetails.materialBondAmount',
                              String(amountValue)
                            );
                            formik.setFieldValue(
                              'bidBondDetails.materialBondPercentage',
                              ''
                            );
                          }}
                          focusBorderColor="#7f7f7f"
                          borderColor="#7f7f7f"
                          _hover={{ borderColor: '#7f7f7f' }}
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          readOnly={!!isViewMode}
                        />
                      </Box>

                      {/* Error message */}
                      {(formik.touched.bidBondDetails as any)
                        ?.materialBondPercentage &&
                        (formik.errors.bidBondDetails as any)
                          ?.materialBondPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.bidBondDetails as any)
                                ?.materialBondPercentage
                            }
                          </div>
                        )}
                    </Box>
                  )}

                  {/* tenderDate  => date */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Tender Date</li>
                    </Text>
                    <DatePickerInput
                      ref={dateTenderRef}
                      value={
                        formik.values.bidBondDetails?.tenderDate !== null
                          ? formik.values.bidBondDetails?.tenderDate
                          : undefined
                      }
                      onChange={(date: Date | null) => {
                        formik.setFieldValue('bidBondDetails.tenderDate', date);
                      }}
                      locale="en"
                      placeholder="Tender Date"
                      className={`react-datepicker-component react-datepicker-input input gray-border`}
                      showOnInputClick={true}
                      onShow={() => addFocusEventListenerOnInput(dateTenderRef)}
                      onHide={() =>
                        removeFocusEventListenerOnInput(dateTenderRef)
                      }
                      disabled={!!isViewMode}
                    />
                    {(formik.touched.bidBondDetails as any)?.tenderDate &&
                      (formik.errors.bidBondDetails as any)?.tenderDate && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {(formik.errors.bidBondDetails as any)?.tenderDate}
                        </div>
                      )}
                  </Box>
                  {/* bidBondAmount  => number */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Bid Bond Amount</li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="Bid Bond Amount"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={formik.values.bidBondDetails?.bidBondAmount ?? ''}
                      name="bidBondDetails.bidBondAmount"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onKeyDown={preventAlphabetsTyping}
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      readOnly={!!isViewMode}
                    />
                    {(formik.touched.bidBondDetails as any)?.bidBondAmount &&
                      (formik.errors.bidBondDetails as any)?.bidBondAmount && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {(formik.errors.bidBondDetails as any)?.bidBondAmount}
                        </div>
                      )}
                  </Box>
                  {/* acceptancePeriod  => number */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Acceptance Period(number of days)</li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="Acceptance Period"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={
                        formik.values.bidBondDetails?.acceptancePeriod ?? ''
                      }
                      name="bidBondDetails.acceptancePeriod"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      readOnly={!!isViewMode}
                    />
                    {(formik.touched.bidBondDetails as any)?.acceptancePeriod &&
                      (formik.errors.bidBondDetails as any)
                        ?.acceptancePeriod && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            (formik.errors.bidBondDetails as any)
                              ?.acceptancePeriod
                          }
                        </div>
                      )}
                  </Box>

                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Were you issued a Pre-Qualification Letter</li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={
                          formik.values?.bidBondDetails
                            ?.isPreQualificationLetter === true
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'bidBondDetails.isPreQualificationLetter',
                            e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={
                          formik.values?.bidBondDetails
                            ?.isPreQualificationLetter === false
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'bidBondDetails.isPreQualificationLetter',
                            !e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        No
                      </Checkbox>
                    </Stack>
                    {(formik.touched.bidBondDetails as any)
                      ?.isPreQualificationLetter &&
                      (formik.errors.bidBondDetails as any)
                        ?.isPreQualificationLetter && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            (formik.errors.bidBondDetails as any)
                              ?.isPreQualificationLetter
                          }
                        </div>
                      )}
                  </Box>
                  {/* preQualificationLetter  => string */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Pre Qualification Letter</li>
                    </Text>
                    <FileInput
                      icon={
                        <IconFileCv
                          style={{ width: 18, height: 18 }}
                          stroke={1.5}
                        />
                      }
                      styles={{
                        input: {
                          maxWidth: '350px',
                          border: '1px solid #7f7f7f',
                          minWidth: '155px',
                          minHeight: '35px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        placeholder: {
                          color: `black !important`,
                        },
                      }}
                      name="preQualificationLetterAttachments"
                      // @ts-ignore
                      placeholder={
                        !!id && !!fileNames?.preQualificationLetterFiles
                          ? fileNames?.preQualificationLetterFiles
                          : 'Attachments'
                      }
                      // label="Upload Contract"
                      multiple={true}
                      onChange={(payload: File[]) =>
                        handleUploadAttachments(
                          payload,
                          'preQualificationLetterAttachments',
                          'bidBondDetails.preQualificationLetter'
                        )
                      }
                      value={
                        formik.values?.preQualificationLetterAttachments ?? []
                      }
                      // description={`The file format should be - xlsx, xls`}
                      variant="filled"
                      accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      disabled={!!isViewMode}
                    />
                    {(formik.touched.bidBondDetails as any)
                      ?.preQualificationLetter &&
                      (formik.errors.bidBondDetails as any)
                        ?.preQualificationLetter && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            (formik.errors.bidBondDetails as any)
                              ?.preQualificationLetter
                          }
                        </div>
                      )}
                  </Box>
                  {/* additionalComments => string */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Additional Comments</li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="text"
                      placeholder="Additional Comments"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={
                        formik.values.bidBondDetails?.additionalComments ?? ''
                      }
                      name="bidBondDetails.additionalComments"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      readOnly={!!isViewMode}
                    />
                    {(formik.touched.bidBondDetails as any)
                      ?.additionalComments &&
                      (formik.errors.bidBondDetails as any)
                        ?.additionalComments && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            (formik.errors.bidBondDetails as any)
                              ?.additionalComments
                          }
                        </div>
                      )}
                  </Box>
                  {/* attachments => array */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Attachments</li>
                    </Text>
                    {/* <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Attachments"
                        borderRadius="5px"
                        onChange={formik.handleChange}
                        value={formik.values.bidBondDetails?.attachments ?? ''}
                        name="bidBondDetails.attachments"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                      /> */}
                    <Box display={'flex'} flexDirection={'column'}>
                      <FileInput
                        icon={
                          <IconFileCv
                            style={{ width: 18, height: 18 }}
                            stroke={1.5}
                          />
                        }
                        styles={{
                          input: {
                            maxWidth: '350px',
                            border: '1px solid #7f7f7f',
                            minWidth: '155px',
                            minHeight: '35px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                          placeholder: {
                            color: `black !important`,
                          },
                        }}
                        name="bidBondDetailsUpload"
                        // @ts-ignore
                        placeholder={
                          !!id && !!fileNames?.attachmentsFiles
                            ? fileNames?.attachmentsFiles
                            : 'Attachments'
                        }
                        // label="Upload Contract"
                        multiple={true}
                        onChange={(payload: File[]) =>
                          handleUploadAttachments(
                            payload,
                            'bidBondDetailsUpload',
                            'bidBondDetails.attachments'
                          )
                        }
                        value={formik.values.bidBondDetailsUpload ?? []}
                        // description={`The file format should be - xlsx, xls`}
                        variant="filled"
                        accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        disabled={!!isViewMode}
                      />
                    </Box>
                    {(formik.touched.bidBondDetails as any)?.attachments &&
                      (formik.errors.bidBondDetails as any)?.attachments && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {(formik.errors.bidBondDetails as any)?.attachments}
                        </div>
                      )}
                  </Box>

                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Is a final Bonds required</li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={
                          formik.values?.bidBondDetails?.isFinalBondRequired ===
                          true
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'bidBondDetails.isFinalBondRequired',
                            e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={
                          formik.values?.bidBondDetails?.isFinalBondRequired ===
                          false
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'bidBondDetails.isFinalBondRequired',
                            !e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        No
                      </Checkbox>
                    </Stack>
                  </Box>
                </>
              )}
              {/* show only if final is selected */}
              {formik.values.bondApplicationType === 'Final' && (
                <>
                  {/* Bond Type */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Bond Type</li>
                    </Text>

                    <MultiSelect
                      className="legalform_pdfPreference"
                      data={BondType.map((el) => ({
                        label: el.label,
                        value: el.value,
                        tooltip: el.tooltip,
                      }))}
                      placeholder="Bond Type"
                      maxDropdownHeight={160}
                      styles={{
                        input: { maxHeight: '90px' },
                      }}
                      itemComponent={SelectItem}
                      onChange={(value) => {
                        const previousValues = formik.values.bondType ?? [];
                        console.log('previousValues', previousValues);
                        const removedItems = previousValues.filter(
                          (item: string) => !value.includes(item)
                        );

                        removedItems.forEach((removedBond: any) => {
                          const bondKey = bondTypeMap[removedBond];
                          if (bondKey) {
                            formik.setFieldValue(
                              `finalBondDetails.${bondKey}Amount`,
                              ''
                            );
                          }
                        });

                        formik.setFieldValue(
                          'bondType',
                          value.length ? value : null
                        );
                      }}
                      value={formik.values.bondType ?? []}
                      disabled={!!isViewMode}
                    />
                    {formik.touched.bondType && formik.errors.bondType && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.bondType}
                      </div>
                    )}
                  </Box>
                  {/* contractPrice => number */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Contract Price</li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="Contract Price"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={
                        formik.values.finalBondDetails?.contractPrice ?? ''
                      }
                      name="finalBondDetails.contractPrice"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      readOnly={!!isViewMode}
                    />
                    {(formik.touched.finalBondDetails as any)?.contractPrice &&
                      (formik.errors.finalBondDetails as any)
                        ?.contractPrice && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            (formik.errors.finalBondDetails as any)
                              ?.contractPrice
                          }
                        </div>
                      )}
                  </Box>
                  {/* includesTaxes => boolean */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Includes Taxes</li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={
                          formik.values?.finalBondDetails?.includesTaxes ===
                          true
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'finalBondDetails.includesTaxes',
                            e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={
                          formik.values?.finalBondDetails?.includesTaxes ===
                          false
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'finalBondDetails.includesTaxes',
                            !e.target.checked
                          );
                        }}
                        readOnly={!!isViewMode}
                      >
                        No
                      </Checkbox>
                    </Stack>
                    {(formik.touched.finalBondDetails as any)?.includesTaxes &&
                      (formik.errors.finalBondDetails as any)
                        ?.includesTaxes && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            (formik.errors.finalBondDetails as any)
                              ?.includesTaxes
                          }
                        </div>
                      )}
                  </Box>
                  {/* taxType => select */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Tax Type</li>
                    </Text>
                    <Select
                      onChange={formik.handleChange}
                      value={formik.values.finalBondDetails?.taxType ?? ''}
                      name="finalBondDetails.taxType"
                      fontSize={'13px'}
                      _disabled={{ opacity: 1 }}
                      w="100%"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      disabled={!!isViewMode}
                    >
                      <option
                        value={''}
                        style={{ color: 'black' }}
                        label="Select Option"
                      />
                      {getTaxTypeDownOption(
                        formik.values?.projectLocationCountry!
                      )?.map((item: any, index: number) => (
                        <option
                          style={{ color: 'black' }}
                          key={index}
                          value={item.value}
                          label={item.label}
                        />
                      ))}
                    </Select>
                    {(formik.touched.finalBondDetails as any)?.taxType &&
                      (formik.errors.finalBondDetails as any)?.taxType && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {(formik.errors.finalBondDetails as any)?.taxType}
                        </div>
                      )}
                  </Box>
                  {/* contractDate => date */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Contract Date</li>
                    </Text>
                    <DatePickerInput
                      ref={dateContractRef}
                      value={
                        formik.values.finalBondDetails?.contractDate !== null
                          ? formik.values.finalBondDetails?.contractDate
                          : undefined
                      }
                      onChange={(date: Date | null) => {
                        formik.setFieldValue(
                          'finalBondDetails.contractDate',
                          date
                        );
                      }}
                      locale="en"
                      placeholder="Contract Date"
                      className={`react-datepicker-component react-datepicker-input input gray-border`}
                      showOnInputClick={true}
                      onShow={() =>
                        addFocusEventListenerOnInput(dateContractRef)
                      }
                      onHide={() =>
                        removeFocusEventListenerOnInput(dateContractRef)
                      }
                      minDate={moment(new Date()).toDate()}
                      disabled={!!isViewMode}
                    />
                    {(formik.touched.finalBondDetails as any)?.contractDate &&
                      (formik.errors.finalBondDetails as any)?.contractDate && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            (formik.errors.finalBondDetails as any)
                              ?.contractDate
                          }
                        </div>
                      )}
                  </Box>
                  {/* performanceBondAmount => number */}
                  {formik.values.bondType?.includes('Performance Bond') && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Performance Bond Amount</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="number"
                        placeholder="Performance Bond Amount"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.performanceBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails
                            ?.performanceBondAmount ?? ''
                        }
                        name="finalBondDetails.performanceBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.performanceBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.performanceBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.performanceBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {/* paymentBondAmount => number */}
                  {formik.values.bondType?.includes('Payment Bond') && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Payment Bond Amount</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="number"
                        placeholder="Payment Bond Amount"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.paymentBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails?.paymentBondAmount ??
                          ''
                        }
                        name="finalBondDetails.paymentBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.paymentBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.paymentBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.paymentBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {/* maintenanceBondAmount => number */}
                  {formik.values.bondType?.includes('Maintenance Bond') && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Maintenance Bond Amount</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="number"
                        placeholder="Maintenance Bond Amount"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.maintenanceBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails
                            ?.maintenanceBondAmount ?? ''
                        }
                        name="finalBondDetails.maintenanceBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.maintenanceBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.maintenanceBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.maintenanceBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {formik.values.bondType?.includes('Supply Bond') && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Supply Bond</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Supply Bond"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.supplyBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails?.supplyBondAmount ?? ''
                        }
                        name="finalBondDetails.supplyBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onKeyDown={preventAlphabetsTyping}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.supplyBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.supplyBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.supplyBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {/* Subdivision Bond => number */}
                  {formik.values.bondType?.includes('Subdivision Bond') && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Subdivision Bond</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Subdivision Bond"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.subdivisionBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails
                            ?.subdivisionBondAmount ?? ''
                        }
                        name="finalBondDetails.subdivisionBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onKeyDown={preventAlphabetsTyping}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.subdivisionBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.subdivisionBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.subdivisionBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {/* License and Permit Bond => number */}
                  {formik.values.bondType?.includes(
                    'License and Permit Bond'
                  ) && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>License and Permit Bond</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="License and Permit Bond"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.licenseAndPermitBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails
                            ?.licenseAndPermitBondAmount ?? ''
                        }
                        name="finalBondDetails.licenseAndPermitBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onKeyDown={preventAlphabetsTyping}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.licenseAndPermitBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.licenseAndPermitBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.licenseAndPermitBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {/* Environmental or Reclamation Bond => number */}
                  {formik.values.bondType?.includes(
                    'Environmental or Reclamation Bond'
                  ) && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Environmental or Reclamation Bond</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Environmental or Reclamation Bond"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.environmentalOrReclamationBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails
                            ?.environmentalOrReclamationBondAmount ?? ''
                        }
                        name="finalBondDetails.environmentalOrReclamationBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onKeyDown={preventAlphabetsTyping}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.environmentalOrReclamationBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.environmentalOrReclamationBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.environmentalOrReclamationBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {/* completionBondAmount => number */}
                  {formik.values.bondType?.includes('Completion Bond') && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Completion Bond</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Completion Bond"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.completionBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails
                            ?.completionBondAmount ?? ''
                        }
                        name="finalBondDetails.completionBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onKeyDown={preventAlphabetsTyping}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.completionBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.completionBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.completionBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {/* Site Improvement Bond => number */}
                  {formik.values.bondType?.includes(
                    'Site Improvement Bond'
                  ) && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Site Improvement Bond</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Site Improvement Bond"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.siteImprovementBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails
                            ?.siteImprovementBondAmount ?? ''
                        }
                        name="finalBondDetails.siteImprovementBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onKeyDown={preventAlphabetsTyping}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.siteImprovementBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.siteImprovementBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.siteImprovementBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                  {/* Material Bond => number */}
                  {formik.values.bondType?.includes('Material Bond') && (
                    <Box display={'flex'} flexDirection={'column'}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        mb={1}
                      >
                        <li>Material Bond</li>
                      </Text>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Material Bond"
                        borderRadius="5px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            'finalBondDetails.materialBondAmount',
                            e.target.value.toString()
                          )
                        }
                        value={
                          formik.values.finalBondDetails?.materialBondAmount ??
                          ''
                        }
                        name="finalBondDetails.materialBondAmount"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onKeyDown={preventAlphabetsTyping}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        readOnly={!!isViewMode}
                      />
                      {(formik.touched.finalBondDetails as any)
                        ?.materialBondAmount &&
                        (formik.errors.finalBondDetails as any)
                          ?.materialBondAmount && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {
                              (formik.errors.finalBondDetails as any)
                                ?.materialBondAmount
                            }
                          </div>
                        )}
                    </Box>
                  )}
                </>
              )}
            </Box>

            <Divider
              orientation="horizontal"
              color={'#7f7f7f'}
              my={5}
              opacity={1}
            />

            <Box>
              <Text
                fontWeight="600"
                color="#114684"
                textAlign="center"
                fontSize={20}
              >
                Bond Approval & Premium{' '}
              </Text>
            </Box>
            <Box
              display={'grid'}
              gridTemplateColumns={{
                base: 'repeat(auto-fill, minmax(220px, 1fr))',
                md: 'repeat(auto-fill, minmax(270px, 1fr))',
              }}
              gap={5}
            >
              {/* premiumCalculation => string */}
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Premium Calculation</li>
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Premium Calculation"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.premiumCalculation ?? ''}
                  name="premiumCalculation"
                  focusBorderColor="#7f7f7f"
                  borderColor={'#7f7f7f'}
                  _hover={{ borderColor: '#7f7f7f' }}
                  readOnly={!!isViewMode}
                />
                {formik.touched.premiumCalculation &&
                  formik.errors.premiumCalculation && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.premiumCalculation}
                    </div>
                  )}
              </Box>
              {/* approvedDate => date */}
              {/* <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>Approved Date</li>
                  </Text>
                  <DatePickerInput
                    ref={dateApprovedRef}
                    value={
                      formik.values.approvedDate !== null
                        ? formik.values.approvedDate
                        : undefined
                    }
                    onChange={(date: Date | null) => {
                      formik.setFieldValue('approvedDate', date);
                    }}
                    locale="en"
                    placeholder="Approved Date"
                    className={`react-datepicker-component react-datepicker-input input gray-border`}
                    showOnInputClick={true}
                    onShow={() => addFocusEventListenerOnInput(dateApprovedRef)}
                    onHide={() =>
                      removeFocusEventListenerOnInput(dateApprovedRef)
                    }
                  />
                  {formik.touched.approvedDate && formik.errors.approvedDate && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.approvedDate}
                    </div>
                  )}
                </Box> */}
              {/* approver => string */}
              {/* <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>Approver</li>
                  </Text>
                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="Approver"
                    borderRadius="5px"
                    onChange={formik.handleChange}
                    value={formik.values.approver ?? ''}
                    name="approver"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                  />
                  {formik.touched.approver && formik.errors.approver && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.approver}
                    </div>
                  )}
                </Box> */}
              {/* approvalStamp => string */}
              {/* <Box display={'flex'} flexDirection={'column'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>Approval Stamp</li>
                  </Text>
                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="Approval Stamp"
                    borderRadius="5px"
                    onChange={formik.handleChange}
                    value={formik.values.approvalStamp ?? ''}
                    name="approvalStamp"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                  />
                  {formik.touched.approvalStamp &&
                    formik.errors.approvalStamp && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.approvalStamp}
                      </div>
                    )}
                </Box> */}
              {/* consentOfSurety => boolean */}
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Consent Of Surety</li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={formik.values?.consentOfSurety === true}
                    onChange={(e) => {
                      formik.setFieldValue('consentOfSurety', e.target.checked);
                    }}
                    readOnly={!!isViewMode}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={formik.values?.consentOfSurety === false}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'consentOfSurety',
                        !e.target.checked
                      );
                    }}
                    readOnly={!!isViewMode}
                  >
                    No
                  </Checkbox>
                </Stack>
                {formik.touched.consentOfSurety &&
                  formik.errors.consentOfSurety && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.consentOfSurety}
                    </div>
                  )}
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  mb={1}
                >
                  <li>Was the price and/or terms negotiated</li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={formik.values?.isNegotiated === true}
                    onChange={(e) => {
                      formik.setFieldValue('isNegotiated', e.target.checked);
                    }}
                    readOnly={!!isViewMode}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={formik.values?.isNegotiated === false}
                    onChange={(e) => {
                      formik.setFieldValue('isNegotiated', !e.target.checked);
                    }}
                    readOnly={!!isViewMode}
                  >
                    No
                  </Checkbox>
                </Stack>
                {formik.touched.isNegotiated && formik.errors.isNegotiated && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.isNegotiated}
                  </div>
                )}
              </Box>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Text
                fontWeight="600"
                color="#114684"
                textAlign="start"
                fontSize={13}
                mb={1}
              >
                <li>Tender Results</li>
              </Text>
              <Box
                display={'grid'}
                gridTemplateColumns={{
                  base: 'repeat(auto-fill, minmax(280px, 1fr))',
                  md: 'repeat(auto-fill, minmax(350px, 1fr))',
                }}
                gap={5}
                gridColumnStart={1}
              >
                {/* bidders => array */}
                <Box gridColumnStart={1}>
                  {formik?.values?.tenderResults?.bidders?.map(
                    (item: any, i: number) => (
                      <>
                        <Box
                          display={{ base: 'block', md: 'flex' }}
                          flex={{ base: 1, md: 1 }}
                          gap={3}
                          marginBottom={4}
                        >
                          <Box display={'flex'} flexDirection={'column'}>
                            {i === 0 && (
                              <Text
                                fontWeight="600"
                                color="#114684"
                                textAlign="start"
                                ml={4}
                                fontSize={13}
                                mb={1}
                              >
                                Bidder Company Name
                              </Text>
                            )}
                            <Box display={'flex'} flexDirection={'row'}>
                              <Text
                                fontWeight="600"
                                color="#114684"
                                textAlign="start"
                                fontSize={13}
                                mb={1}
                                width={'110px'}
                                mt={2}
                              >
                                {`${i + 2}nd Bidder `}
                              </Text>
                              <Input
                                fontSize={'13px'}
                                w="100%"
                                type="text"
                                placeholder="Bidder Company Name"
                                borderRadius="5px"
                                onChange={formik.handleChange}
                                value={
                                  formik.values.tenderResults?.bidders?.[i]
                                    ?.companyName ?? ''
                                }
                                name={`tenderResults.bidders[${i}].companyName`}
                                focusBorderColor="#7f7f7f"
                                borderColor={'#7f7f7f'}
                                _hover={{ borderColor: '#7f7f7f' }}
                                readOnly={!!isViewMode}
                              />
                            </Box>
                            {(
                              (formik.touched.tenderResults as any)?.bidders?.[
                                i
                              ] as any
                            )?.companyName &&
                              (
                                (formik.errors.tenderResults as any)?.bidders?.[
                                  i
                                ] as any
                              )?.companyName && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {
                                    (
                                      (formik.errors.tenderResults as any)
                                        ?.bidders?.[i] as any
                                    )?.companyName
                                  }
                                </div>
                              )}
                          </Box>
                          <Box display={'flex'} flexDirection={'column'}>
                            {i === 0 && (
                              <Text
                                fontWeight="600"
                                color="#114684"
                                textAlign="start"
                                ml={4}
                                fontSize={13}
                                mb={1}
                              >
                                Amount $
                              </Text>
                            )}

                            <Input
                              fontSize={'13px'}
                              w="100%"
                              type="number"
                              placeholder="Amount in $"
                              borderRadius="5px"
                              onChange={formik.handleChange}
                              value={
                                formik.values.tenderResults?.bidders?.[i]
                                  ?.amount ?? ''
                              }
                              name={`tenderResults.bidders.[${i}].amount`}
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onKeyDown={preventAlphabetsTyping}
                              onWheel={(e) =>
                                (e.target as HTMLInputElement).blur()
                              }
                              readOnly={!!isViewMode}
                            />
                            {(
                              (formik.touched.tenderResults as any)?.bidders?.[
                                i
                              ] as any
                            )?.amount &&
                              (
                                (formik.errors.tenderResults as any)?.bidders?.[
                                  i
                                ] as any
                              )?.amount && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {
                                    (
                                      (formik.errors.tenderResults as any)
                                        ?.bidders?.[i] as any
                                    )?.amount
                                  }
                                </div>
                              )}
                          </Box>

                          {formik?.values?.tenderResults?.bidders &&
                            i === 0 && (
                              <Box
                                display="flex"
                                alignItems="center"
                                fontSize="xl"
                                marginTop="18px"
                                onClick={() =>
                                  !!isViewMode ? {} : handleAddBox(i)
                                }
                                cursor={
                                  !!isViewMode ? 'not-allowed' : 'pointer'
                                }
                                pointerEvents={!!isViewMode ? 'none' : 'auto'}
                                sx={{
                                  visibility:
                                    formik?.values?.tenderResults?.bidders
                                      ?.length < 2 && i === 0
                                      ? 'visible'
                                      : 'hidden',
                                }}
                              >
                                <CiCirclePlus />
                              </Box>
                            )}

                          {i === 1 && (
                            <Box
                              display="flex"
                              alignItems="center"
                              fontSize="xl"
                              marginTop="5px"
                              onClick={() =>
                                !!isViewMode ? {} : handleRemoveBox(i)
                              }
                              cursor={!!isViewMode ? 'not-allowed' : 'pointer'}
                              pointerEvents={!!isViewMode ? 'none' : 'auto'}
                            >
                              <CiCircleMinus />
                            </Box>
                          )}
                        </Box>
                      </>
                    )
                  )}
                  {(formik.touched.tenderResults as any)?.bidders &&
                    typeof (formik.errors.tenderResults as any)?.bidders ===
                      'string' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {(formik.errors.tenderResults as any)?.bidders}
                      </div>
                    )}
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              justifyContent={'end'}
              gap={3}
              flexWrap={'wrap'}
            >
              <Button
                loadingText="Submit"
                type="button"
                colorScheme="blue"
                onClick={() => {
                  formik.setFieldValue('status', 'pending');
                  formik.handleSubmit();
                }}
                isLoading={
                  loader.btnLoader && formik.values?.status === 'pending'
                }
                isDisabled={
                  (loader.btnLoader && formik.values?.status === 'completed') ||
                  !!isViewMode
                }
              >
                Save
              </Button>
              <Button
                loadingText="Mark as Completed"
                type="button"
                colorScheme="blue"
                isLoading={
                  loader.btnLoader && formik.values?.status === 'completed'
                }
                isDisabled={
                  (loader.btnLoader && formik.values?.status === 'pending') ||
                  !!isViewMode
                }
                onClick={() => {
                  OnHandelFinish();
                }}
              >
                Mark as Complete
              </Button>
            </Box>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default BidBondReference;
